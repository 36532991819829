import React, { Fragment } from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import Icon from "react-icons-kit";
import { closeRound } from "react-icons-kit/ionicons/";

import { history } from "../_helpers";
import { alertActions } from "../_actions";
import Login from "../screens/Login/Login";
import PrivateRoute from "components/PrivateRoute/PrivateRoute";
import { HomePage } from "../screens/HomePage/HomePage";
import {
  ForgetPassword,
  ForgetPasswordStep2,
  ForgetPasswordChangePassword,
  ForgetPasswordResetted
} from "../screens/ForgetPassword";
import { Register, SignUpOrderNo } from "../screens/RegisterPage";

import Profile from "../screens/Profile/Profile";
import ProfileLinkedProducts from "../screens/Profile/ProfileLinkedProducts";
import LinkedProductDetails from "../screens/Profile/LinkedProductDetails";
import AddNewOrderNumber from "../screens/Profile/AddNewOrderNumber";
import EyeTestHistory from "../screens/Profile/EyeTestHistory";
import EyeTestFullHistory from "../screens/Profile/Eyetestfullhistory";

import Landing from "../screens/Landing/Landing";
import Testimonials from "../screens/Landing/Testimonials";
import About from "../screens/Landing/About";
import Technology from "../screens/Landing/Technology";
import Awards from "../screens/Landing/Awards";
import Club from "../screens/Landing/Club";

import ContactUs from "../screens/ContactUs/ContactUs";
import VerifiyEmail from "../screens/VerifyEmail/VerifyEmail";
import {
  RewardsDashboard,
  RewardsRedemption,
  RewardsPointsSummary,
  RewardsRedemptionConfirmation
} from "../screens/Rewards/";
import { ApptDashboard, ApptEdit } from "../screens/Appointment";

class App extends React.Component {
  constructor(props) {
    super(props);

    history.listen((location, action) => {
      this.props.clearAlerts();
    });
  }

  render() {
    const { alert } = this.props;
    const alertStyle = {
      zIndex: 999999999999,
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      height: "200px",
      width: "100%",
      maxWidth: "450px",
      margin: "auto",
      borderRadius: "10px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      overflow: "scroll"
    };
    const overlayAlertStyle = {
      position: "fixed",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: "rgba(0, 0, 0, 0.2)",
      zIndex: 999999999999999
    };
    return (
      <Fragment>
        {alert.message &&
          <div className={`overlay-alert`} style={overlayAlertStyle}>
            <div style={alertStyle} className={`alert ${alert.type}`}>
              <p style={{ margin: 0, width: "100%", textAlign: "center" }}>
                {alert.message}
              </p>
              <Icon
                icon={closeRound}
                onClick={this.props.clearAlerts}
                style={{
                  cursor: "pointer",
                  position: "absolute",
                  top: "10px",
                  right: "10px"
                }}
                size={16}
              />
            </div>
          </div>}
        <Router history={history}>
          <Switch>
            <Route exact path="/" component={Landing} />
            <Route path="/Testimonials" component={Testimonials}/>
            <Route path="/About" component={About}/>
            <Route path="/Technology" component={Technology}/>
            <Route path="/Awards" component={Awards}/>
            <Route path="/Club" component={Club}/>

            <PrivateRoute path="/home" component={HomePage} />
            <Route path="/login" component={Login} />
            <Route path="/register" component={Register} />
            <Route path="/forgetpassword" component={ForgetPassword} />
            <Route
              path="/forgetpasswordstep2"
              component={ForgetPasswordStep2}
            />
            <Route
              path="/forgetpasswordchangepassword"
              component={ForgetPasswordChangePassword}
            />
            <Route
              path="/forgetpasswordresetted"
              component={ForgetPasswordResetted}
            />
            <Route path="/verifyEmail" component={VerifiyEmail} />
            <Route path="/signuporderno" component={SignUpOrderNo} />
            <PrivateRoute path="/contactus" component={ContactUs} />
            <PrivateRoute path="/profile" component={Profile} />
            <PrivateRoute
              path="/profilelinkedproducts"
              component={ProfileLinkedProducts}
            />
            <PrivateRoute
              path="/linkedproductdetails"
              component={LinkedProductDetails}
            />
            <PrivateRoute
              path="/addnewordernumber"
              component={AddNewOrderNumber}
            />
            <PrivateRoute path="/eyetesthistory" component={EyeTestHistory} />
            <PrivateRoute
              path="/eyetestfullhistory"
              component={EyeTestFullHistory}
            />
            <PrivateRoute path="/apptDashboard" component={ApptDashboard} />
            <PrivateRoute path="/appt-edit" component={ApptEdit} />
            <PrivateRoute path="/rewards" component={RewardsDashboard} />
            <PrivateRoute
              path="/rewards-points-summary"
              component={RewardsPointsSummary}
            />
            <PrivateRoute
              path="/rewardsredemption"
              component={RewardsRedemption}
            />
            <PrivateRoute
              path="/rewards-redemption-confirmation"
              component={RewardsRedemptionConfirmation}
            />
            <Redirect from="*" to="/" />
          </Switch>
        </Router>
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    authentication: state.authentication,
    userProfile: state.userProfile,
    alert: state.alert
  };
};

const actionCreators = {
  clearAlerts: alertActions.clear
};

const connectedApp = connect(mapStateToProps, actionCreators)(App);
export { connectedApp as App };
