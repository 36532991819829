// export function authHeader() {
//     // return authorization header with jwt token
//     let user = JSON.parse(localStorage.getItem('user'));
//     let headers = {};
//     if (user && user.token) {
//         return { 'Authorization': 'Bearer ' + user.token };
//     } else {
//         return {};
//     }
// }

export function authHeader(headerConfig) {
    const user = JSON.parse(localStorage.getItem('user'));
    let headers = {};
    if (!headerConfig) {
      headers = {
        "Content-Type": "application/json"
      };
    }
    if (user && user.token) {
      headers = Object.assign({}, headers, {
        Authorization: "Bearer " + user.token
      });
    }
    return { headers };
   }