import React from "react";
import PropTypes from "prop-types";
import "./Title.scss";

export const Title = props => {
  return (
    <h1
      className={["titleStyleComponent", props.className].join(" ")}
      style={{ ...props.style }}
    >
      {props.children}
    </h1>
  );
};

Title.propTypes = {
  title: PropTypes.string
};
