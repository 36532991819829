import axios from "axios";
// import { authHeader } from '../_helpers/auth-header'

export const BaseService = {
  get: (url, params = {}) => {
    return axios.get(url, { params, ...authHeader() });
  },
  put: (url = {}) => {
    return axios.put(url, { ...authHeader() });
  },
  put_: (url, body = {}, headerConfig) => {
    return axios.put(url, body, authHeader(headerConfig));
  },

  _get: (url, data = {}) => {
    return axios.get(url, { data, ...authHeader() });
  },
  post: (url, body = {}, headerConfig) => {
    return axios.post(url, body, authHeader(headerConfig));
  },
  patch: (url, body = {}) => {
    return axios.patch(url, body, authHeader());
  },
  delete: (url, params = {}) => {
    return axios.delete(url, { params, ...authHeader() });
  }
};

export function authHeader(headerConfig) {
  const user = JSON.parse(localStorage.getItem("user"));
  let headers = {};
  if (!headerConfig) {
    headers = {
      "Content-Type": "application/json"
    };
  }
  if (user && user.token) {
    headers = Object.assign({}, headers, {
      Authorization: "Bearer " + user.token
    });
  }
  return { headers };
}
