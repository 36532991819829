import React, { Component } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import Icon from "react-icons-kit";
import { closeRound } from "react-icons-kit/ionicons/";

export default class PopUp extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { children, visible, toggle, className } = this.props;
        return (
            <Modal isOpen={visible} onClosed={this.props.onClosed} toggle={toggle} className={`miyosmart-pop-up-container ${className}`} contentClassName="miyosmart-pop-up-content">
                <ModalHeader className="miyosmart-pop-up-header">
                    <Icon icon={closeRound} onClick={() => toggle("close")} size={23} />
                </ModalHeader>
                <ModalBody>{children}</ModalBody>
            </Modal>
        );
    }
}
