import React, { Component } from "react";
import { Row, Col, Card } from "reactstrap";
import Button from "components/button";

export default class RedemptionSummaryCardComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { title, onCancel, onClick, totalPoints, subTotal, currentPoints } = this.props;
        const balance = +currentPoints - +totalPoints < 0;
        return (
            <div className="redemption-summary-container">
                <h1 className="title">{title}</h1>
                <Card className="redemption-summary-card">
                    <Row className="rs-card-role">
                        <Col>
                            <p>Current points</p>
                        </Col>
                        <Col>
                            <p className="bold" style={balance ? { color: "red" } : null}>{`${+currentPoints - +totalPoints}pts`}</p>
                        </Col>
                    </Row>
                    <Row className="rs-card-role">
                        <Col>
                            <p>Subtotal</p>
                        </Col>
                        <Col>
                            <p className="bold">{`${subTotal}pts`}</p>
                        </Col>
                    </Row>
                    <Row className="rs-card-role">
                        <Col>
                            <p>Shipping</p>
                        </Col>
                        <Col>
                            <p className="bold">FREE</p>
                        </Col>
                    </Row>
                    <Row className="rs-card-role rs-card-miyo-points">
                        <p className="total-points-title bold">TOTAL MIYO POINTS</p>
                        <p className="total-points bold">
                            {`${totalPoints}`}
                            <small className="bold">pts</small>
                        </p>
                    </Row>
                </Card>
                <div className="button-container">
                    <Button title="CONTINUE" onClick={onClick} disabled={balance} style={balance ? { backgroundColor: "gray" } : null} />
                    <Button title="CANCEL" outline onClick={onCancel} />
                </div>
            </div>
        );
    }
}
