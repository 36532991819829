import React from "react";
import facebook from "assets/images/footer-facebook.svg";
import instagram from "assets/images/footer-instagram.svg";

const Footer = ({ hideSocialMedia }) => {
    if (hideSocialMedia) {
        return (
            <div className="social-footer">
                <div className="footer">
                    <div className="footer__wrapper">
                        <span className="footer__text">© {new Date().getFullYear()} Hoya. All Rights Reserved.</span>
                        <span className="footer__text">
                            <span className="footer__text">Privacy</span>
                            <a href="https://miyosmart.hoya.com.my/terms_of_use.html" target="_blank" rel="noreferrer">
                                Terms of use
                            </a>
                        </span>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="social-footer-flex">
            <div className="socialContainer">
                <div className="socialWrapper">
                    <div className="socialElements">
                        <h2 className="title">CONNECT WITH US:</h2>
                        <div className="content">
                            <p>For support or any question:</p>
                            <p>
                                Email us at
                                <a href="mailto: support@hoya.com.my"> support@hoya.com.my</a>
                            </p>
                            <p>Call us at 03-6256 0081</p>
                        </div>
                    </div>

                    <div className="socialElements">
                        <h2 className="title">SOCIAL MEDIA</h2>
                        <div className="content social-content">
                            <a target="_blank" href="https://www.facebook.com/hoyamalaysia/" rel="noreferrer">
                                <img className="socialIcons" src={facebook} alt="" />
                            </a>
                            <a target="_blank" href="https://www.instagram.com/hoyamalaysia/" rel="noreferrer">
                                <img className="socialIcons" src={instagram} alt="" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer">
                <div className="footer__wrapper">
                    <span className="footer__text">© {new Date().getFullYear()} Hoya. All Rights Reserved.</span>
                    <span className="footer__text">
                        <span className="footer__text">Privacy</span>
                        <a href="https://miyosmart.hoya.com.my/terms_of_use.html" target="_blank" rel="noreferrer">
                            Terms of use
                        </a>
                    </span>
                </div>
            </div>
        </div>
    );
};

export default Footer;
