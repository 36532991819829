import React, { Component } from "react";
import { Link } from "react-router-dom";

import Footer from "components/footer";
import miyobee from "assets/images/miyo-bee.svg";

export default class ForgetPasswordStep2Page extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        const { history } = this.props;
        if (history.location.state) {
            const payload = history.location.state.payload.input;
            const validEmail = this.validateEmail(payload);
            if (validEmail) {
                const name = payload.substring(0, payload.lastIndexOf("@"));
                const domain = payload.substring(payload.lastIndexOf("@") + 1);
                const email = name.substring(0, 1) + name.substring(1, name.length).replace(/./g, "*") + "@" + domain;
                this.setState({
                    value: email,
                });
            } else {
                const phoneNumber = payload.substring(0, 1) + payload.substring(1, payload.length);
                this.setState({
                    value: phoneNumber,
                });
            }
        } else {
            history.push("forgetPassword");
        }
    }

    validateEmail(email) {
        var re = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    render() {
        const { value } = this.state;
        return (
            <div className="forget-password">
                <Link to="/login">
                    <div className="hoya-logo" />
                </Link>
                <div className="signUpCard">
                    <div className="forget-password__wrapper">
                        <div className="title">Reset Password</div>
                        <p className="description">A Password Reset Email has been sent to your email {value || "-"}. To reset your password, click on the Reset button in the email.</p>
                        <div className="registor-container">
                            <img className="miyobeeLogo" src={miyobee} alt="" />
                        </div>
                        <div className="pattern-bg">
                            <div className="left" />
                        </div>
                    </div>
                </div>

                <Footer />
            </div>
        );
    }
}
