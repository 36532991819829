import React, { Component } from "react";

import { ApiService, ServiceApiUrl } from "../../../_helpers";
import Layout from "components/layout";
import CardTable from "components/card-table";
import { tableHeader } from "../ultilities/productData";

export default class RewardsPointsSummaryPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            historyData: [],
        };
    }

    componentDidMount() {
        console.log("Rewards Summary Did Mount :)");
        this.getSummaryPointHistory();
    }

    getSummaryPointHistory = (i = 1) => {
        this.requestPointHistory(i)
            .then((resp) => {
                this.setState({
                    historyData: resp.data.pointsList,
                    totalIndex: resp.data.totalIndex,
                });
                console.log(resp.data);
            })
            .catch((err) => {
                alert(err);
            });
    };

    requestPointHistory = async (i) => {
        const response = await ApiService.get(`${ServiceApiUrl.POINT_HISTORY}${i}`);
        return response;
    };

    goBack = () => {
        const { history } = this.props;
        history.push("rewards");
    };

    render() {
        const { historyData, totalIndex } = this.state;
        console.log(historyData, "historydata");
        return (
            <Layout>
                <div className="rewardsSummary-container">
                    <CardTable
                        disabledEdit
                        type="miyo-points-summary"
                        title="MIYO POINTS SUMMARY"
                        tableTHeadData={tableHeader.miyoPointTransaction}
                        tableTBodyData={historyData}
                        paginationTotal={totalIndex}
                        goBack={this.goBack}
                        nextPage={this.getSummaryPointHistory}
                    />
                </div>
            </Layout>
        );
    }
}
