import React, { Component } from "react";
import NavBar from "components/nav-bar";
import Button from "components/button";

import technologyBg1 from "assets/images/technologyBg1.png";
import techBee1 from "assets/images/tech-bee-1.png";
import techBee2 from "assets/images/landing-bee4-&-tech-bee-2.png";
import techBee3 from "assets/images/about-bee-&-tech-bee-3.png";
import techDIMS from "assets/images/tech-d.i.m.s-techonology.png";
import techMultipleSeg1 from "assets/images/tech-multiple-segments-1.png";
import techMultipleSeg2 from "assets/images/tech-multiple-segments-2.png";
import techOpticalClarity from "assets/images/tech-optical-clarity.png";
import techSafeChild from "assets/images/tech-safe-for-child.png";
import techUvProtect from "assets/images/tech-uv-protection.png";
import techThinLight from "assets/images/tech-thin-and-light.png";
import techStrongSafe from "assets/images/tech-strong-and-safe.png";
import techEyeShield from "assets/images/tech-eye-shield.png";
import techLensRegular from "assets/images/tech-miyosmart-lenses-regular.png";
import techLensBeauty from "assets/images/tech-miyosmart-lenses-beatifully.png";
import techCosmeticAppear from "assets/images/tech-cosmetic-appearance.png";
import techSpecialCoat from "assets/images/tech-special-coatings.png";
import techLensCoat from "assets/images/tech-lens-coating.png";
import Bottom from "components/bottom";

export default class Technology extends Component {
    render() {
        return (
            <div className="technology-page">
                <div className="technology">
                    <NavBar />
                    <div className="technology-1">
                        <div className="technology-1__main">
                            <div className="technology-1__left">
                                <div className="technology-1__left-content">
                                    <div>
                                        <div className="bold">A look into</div>
                                        <div className="bold">how MiYOSMART</div>
                                        <div className="bold">works.</div>
                                    </div>
                                </div>
                            </div>
                            <div className="technology-1__right">
                                <img className="technology-1__right-image" src={technologyBg1} alt="" />
                            </div>
                        </div>
                    </div>

                    <div className="technology-2">
                        <div className="technology-2__main">
                            <div className="technology-2__top">
                                <div className="technology-2__top-wrap">
                                    <div className="technology-2__top-left">
                                        <img className="technology-2__top-left-image" src={techDIMS} alt="" />
                                    </div>
                                    <div className="technology-2__top-right">
                                        <div className="technology-2__top-right-title">D.I.M.S. Technology</div>
                                        <div className="technology-2__top-right-body">To control myopia progression, myopic defocus has to be constantly experienced by the wearer.</div>
                                        <div className="technology-2__top-right-body">This requires defocus segments to be evenly distributed on the lens surface.</div>
                                        <div className="technology-2__top-right-body">MiYOSMART lenses consist of Defocus Incorporated Multiple Segments, which have been clinically proven to control myopia.</div>
                                    </div>
                                </div>
                            </div>
                            <div className="technology-2__bottom">
                                <div className="technology-2__bottom-left">
                                    <img className="technology-2__bottom-left-image" src={techMultipleSeg1} alt="" />
                                </div>
                                <img className="technology-2__bottom-right" src={techMultipleSeg2} alt="" />
                                <img className="technology-2__tech-bee" src={techBee1} alt="" />
                            </div>
                        </div>
                    </div>

                    <div className="technology-3">
                        <div className="technology-3__main">
                            <div className="technology-3__top">
                                <div className="technology-3__top-wrap">
                                    <div className="technology-3__top-left">
                                        <img className="technology-3__top-left-image" src={techEyeShield} alt="" />
                                    </div>
                                    <div className="technology-3__top-right">
                                        <div className="technology-3__top-right-title">Eye Shield</div>
                                        <div className="technology-3__top-right-body">MiYOSMART lenses are made of strong, UV protected and impact-resistant material to keep active children safe.</div>
                                        <div className="technology-3__top-right-body">
                                            To protect your child’s eyes, MiYOSMART uses polycarbonate 1.59, a highly impact-resistant material that has passed the high velocity impact drop ball test. <sup>(5)</sup>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="technology-3__bottom">
                                <div className="technology-3__item-list">
                                    <div className="technology-3__item">
                                        <div>
                                            <img className="technology-3__item-icon" src={techStrongSafe} alt="" />
                                        </div>
                                        <div className="technology-3__item-label">Strong and safe</div>
                                    </div>
                                    <div className="technology-3__item">
                                        <div>
                                            <img className="technology-3__item-icon" src={techOpticalClarity} alt="" />
                                        </div>
                                        <div className="technology-3__item-label">Optical clarity</div>
                                    </div>
                                    <div className="technology-3__item">
                                        <div>
                                            <img className="technology-3__item-icon" src={techThinLight} alt="" />
                                        </div>
                                        <div className="technology-3__item-label">Thin and light</div>
                                    </div>
                                    <div className="technology-3__item">
                                        <div>
                                            <img className="technology-3__item-icon" src={techUvProtect} alt="" />
                                        </div>
                                        <div className="technology-3__item-label">UV protection</div>
                                    </div>
                                    <div className="technology-3__item">
                                        <div>
                                            <img className="technology-3__item-icon" src={techSafeChild} alt="" />
                                        </div>
                                        <div className="technology-3__item-label">Safe for children</div>
                                    </div>
                                </div>
                                <div className="technology-3__citation">
                                    (5) ANSI Z87.1 High Velocity Impact Test: The American National Standards Institure (ANSI) has established the most stringent impact and projectile penetration standards for ophthalmic lenses. The standard
                                    specifies that high impact lenses must pass “high velocity” testing where 1⁄4” steel pallets are “shot” at the lens at a velocity of 150 feet-per-second. Polycarbonate passes ANSI Z87.1 - the industry’s highest
                                    standards for high-impact resistance, ensuring full protection to every weare
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="technology-4">
                        <div className="technology-4__main">
                            <div className="technology-4__top">
                                <div className="technology-4__top-wrap">
                                    <div className="technology-4__top-left">
                                        <img className="technology-4__top-left-image" src={techCosmeticAppear} alt="" />
                                    </div>
                                    <div className="technology-4__top-right">
                                        <div className="technology-4__top-right-title">Cosmetic Appearance</div>
                                        <div className="technology-4__top-right-body">MiYOSMART lenses are light, easy to wear, and clear with no visible lines, similar to regular smooth-surfaced lens.</div>
                                    </div>
                                </div>
                            </div>
                            <div className="technology-4__bottom">
                                <div className="technology-4__bottom-item technology-4__bottom-item--first">
                                    <div>
                                        <img className="technology-4__bottom-image" src={techLensBeauty} alt="" />
                                    </div>
                                    <div className="technology-4__bottom-title">MiYOSMART Lenses</div>
                                    <div className="technology-4__bottom-body">Beautifully designed lens provides optical clarity & confidence</div>
                                </div>
                                <div className="technology-4__bottom-item">
                                    <div>
                                        <img className="technology-4__bottom-image" src={techLensRegular} alt="" />
                                    </div>
                                    <div className="technology-4__bottom-title">Regular Lenses</div>
                                    <div className="technology-4__bottom-body">Visual distortion leads to discomfort & poor adaptation</div>
                                </div>
                            </div>
                            <img className="technology-4__tech-bee" src={techBee2} alt="" />
                        </div>
                    </div>

                    <div className="technology-5">
                        <div className="technology-5__main">
                            <div className="technology-5__top">
                                <div className="technology-5__top-wrap">
                                    <div className="technology-5__top-left">
                                        <img className="technology-5__top-left-image" src={techSpecialCoat} alt="" />
                                    </div>
                                    <div className="technology-5__top-right">
                                        <div className="technology-5__top-right-title">Special Coatings</div>
                                        <div className="technology-5__top-right-body">MiYOSMART has a low-maintenance multi-coating that is easy to clean and durable, making it suitable for your child.</div>
                                    </div>
                                </div>
                            </div>
                            <div className="technology-5__bottom">
                                <div className="technology-5__bottom-main">
                                    <div className="technology-5__bottom-image">
                                        <img width="95%" src={techLensCoat} alt="" />
                                    </div>
                                    <div className="technology-5__bottom-item-list">
                                        <div className="technology-5__bottom-item">
                                            <div className="technology-5__bottom-item-content">
                                                <div className="technology-5__bottom-title">Water-repellent Coating</div>
                                                <div className="technology-5__bottom-body">Prevents water stains</div>
                                            </div>
                                        </div>
                                        <div className="technology-5__bottom-item">
                                            <div className="technology-5__bottom-item-content">
                                                <div className="technology-5__bottom-title">Anti-reflective Durable Coating</div>
                                                <div className="technology-5__bottom-body">Keeps lens longer-lasting</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="technology-5__bottom-button-bee-container">
                                    <div>
                                        <Button title="FIND A MiYOSMART CENTRE" customClass="technology-5__bottom-button" link={"https://www.hoyavision.com/my/find-eye-care-practitioner/"} />
                                    </div>
                                    <img className="technology-5__tech-bee" src={techBee3} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <Bottom />
                </div>
            </div>
        );
    }
}
