import React, { Component } from "react";
import logoAskQuestion from "assets/images/logoAskQuestion.svg";
import Icon from "react-icons-kit";
import { star } from "react-icons-kit/feather/star";
import { calendar } from "react-icons-kit/icomoon/calendar";
import { mail } from "react-icons-kit/feather/mail";
import { ic_person_outline } from "react-icons-kit/md/ic_person_outline";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import BeeChat from "./bee-chat";
import Topbar from "./top-bar";
import { ic_lock_open } from "react-icons-kit/md";
import { userActions } from "../_actions";

class Layout extends Component {
    constructor(props) {
        super(props);

        this.state = {
            profile: {
                profileImg: "",
                name: "",
            },
            showComponent: false,
            path: this.props.location.pathname.substring(1, this.props.location.pathname.length),
        };
    }

    setProfile = (data) => {
        this.setState({
            profile: data,
        });
    };

    toggle = () => {
        this.setState({
            showComponent: !this.state.showComponent,
        });
    };

    render() {
        const { path, profile } = this.state;
        return (
            <div className="layoutContainer">
                <div>
                    <Topbar setProfile={this.setProfile} />
                </div>
                <div className="verticalFLexContainer">
                    <div className="vflex1-sidebar">
                        <div className="profilePicAndNameCointainer">
                            <p className="profile-name">{profile.name}</p>
                        </div>
                        <Link to="/profile">
                            <div
                                className={`sidebarElement ${
                                    path === "profile" || path === "profilelinkedproducts" || path === "linkedproductdetails" || path === "addnewordernumber" || path === "eyetesthistory" || path === "eyetestfullhistory" ? "activated" : null
                                }`}>
                                <Icon
                                    style={{
                                        position: "relative",
                                        bottom: "2px",
                                        paddingRight: "10px",
                                    }}
                                    icon={ic_person_outline}
                                />
                                Profile
                            </div>
                        </Link>
                        <Link to="/rewards">
                            <div className={`sidebarElement ${path === "rewards" || path === "rewards-points-summary" || path === "rewardsredemption" || path === "rewards-redemption-confirmation" ? "activated" : null}`}>
                                <Icon
                                    style={{
                                        position: "relative",
                                        bottom: "2px",
                                        paddingRight: "10px",
                                    }}
                                    icon={star}
                                />
                                Rewards
                            </div>
                        </Link>
                        <Link to="/apptDashboard">
                            <div className={`sidebarElement ${path === "apptDashboard" || path === "appt-edit" ? "activated" : null}`}>
                                <Icon
                                    style={{
                                        position: "relative",
                                        bottom: "2px",
                                        paddingRight: "10px",
                                    }}
                                    icon={calendar}
                                />
                                Bookings
                            </div>
                        </Link>
                        <Link to="/contactus">
                            <div className={`sidebarElement ${path === "contactus" ? "activated" : null}`}>
                                <Icon
                                    style={{
                                        position: "relative",
                                        bottom: "2px",
                                        paddingRight: "10px",
                                    }}
                                    icon={mail}
                                />
                                Contact Us
                            </div>
                        </Link>
                        <div className="sidebarElement" onClick={this.props.logout}>
                            <Icon
                                style={{
                                    position: "relative",
                                    bottom: "2px",
                                    paddingRight: "10px",
                                }}
                                icon={ic_lock_open}
                            />
                            Log Out
                        </div>
                    </div>

                    <div className="vflex2">
                        {this.props.children}
                        <img className="logoAskQuestionStyle" onClick={this.toggle} src={logoAskQuestion} alt="" />
                    </div>
                </div>
                {this.state.showComponent ? <BeeChat toggle={this.toggle} /> : null}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    console.log(state);
    return {
        redempProduct: state.redempProduct,
    };
};

const mapDispatchToProps = {
    logout: userActions.logout,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Layout));
