import React, { Component } from "react";
import { Card, CardBody, CardTitle, CardSubtitle } from "reactstrap";
import Button from "./button";

export default class ProductCard extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { title, subtitle, buttonText, productImg, miyoPoints, onClick } = this.props;
        return (
            <Card className="product-card-container">
                {miyoPoints && <CardTitle className="title">{miyoPoints}</CardTitle>}
                {productImg && <div style={{ backgroundImage: `url(${productImg})` }} className="product-image" />}
                <CardBody className="product-content">
                    <div>
                        {title && <CardTitle className="title">{title}</CardTitle>}
                        {subtitle && <CardSubtitle>{subtitle}</CardSubtitle>}
                    </div>
                    <div className="product-card-button-container">
                        <Button title={buttonText || "REDEEM NOW"} onClick={onClick} />
                    </div>
                </CardBody>
            </Card>
        );
    }
}
