import React, { Component } from "react";
import * as moment from "moment";
import Icon from "react-icons-kit";
import { chevronLeft } from "react-icons-kit/ionicons";
import { connect } from "react-redux";

import Layout from "components/layout";
import { ApiService } from "../../_helpers/index";
import { history } from "../../_helpers/history";
import { alertActions } from "../../_actions";

class LinkedProductDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fixprofileid: "",
            dataList: [],
            navPropOrderNum: this.props.location.state.linkedProductOrderNum,
            navProplinkedProfileId: this.props.location.state.linkedProfileId,
        };
    }

    componentDidMount() {
        this.getLinkedProductDetailsData();
    }

    getLinkedProductDetailsData = async (a, b) => {
        const { navProplinkedProfileId, navPropOrderNum } = this.state;
        try {
            const response = await ApiService.get(process.env.REACT_APP_MIYOSMART_URL + "Link/Profile/" + navProplinkedProfileId + "/" + navPropOrderNum);
            this.setState({
                dataList: response.data,
            });
        } catch (err) {
            this.props.alertError(err.message);
        }
    };

    render() {
        const { dataList } = this.state;

        return (
            <Layout>
                <div className="linkend-product-details">
                    <div className="linkend-product-details__header">
                        <p className="title">LINKED PRODUCT DETAILS</p>
                        <div className="linkend-product-details__back-btn" onClick={() => history.push("/profilelinkedproducts")}>
                            <Icon icon={chevronLeft} size={10} />
                            <p className="bold">Back</p>
                        </div>
                    </div>

                    <div className="linkend-product-details__item">
                        <div className="linkend-product-details__content">
                            <div className="linkend-product-details__info">
                                <div className="linkend-product-details__info-wrapper">
                                    <p className="linkend-product-details__label">ORDER NUMBERS</p>
                                    <p className="linkend-product-details__value bold">{dataList.productNumber}</p>
                                </div>
                            </div>
                            <div className="linkend-product-details__info">
                                <div className="linkend-product-details__info-wrapper">
                                    <p className="linkend-product-details__label">NAME OF OWNER</p>
                                    <p className="linkend-product-details__value bold">{dataList.profileName}</p>
                                </div>
                            </div>
                            <div className="linkend-product-details__info">
                                <div className="linkend-product-details__info-wrapper">
                                    <p className="linkend-product-details__label">DATE OF BIRTH</p>
                                    <p className="linkend-product-details__value bold">{moment(dataList.dob).format("DD/MM/YYYY")}</p>
                                </div>
                            </div>
                            <div className="linkend-product-details__info">
                                <div className="linkend-product-details__info-wrapper">
                                    <p className="linkend-product-details__label">DATE OF PURCHASE</p>
                                    <p className="linkend-product-details__value bold">{moment(dataList.dateOfPurchased).format("DD/MM/YYYY")}</p>
                                </div>
                            </div>
                            <div className="linkend-product-details__info linkend-product-details__info--no-border">
                                <div className="linkend-product-details__info-wrapper">
                                    <p className="linkend-product-details__label">END OF WARRANTY</p>
                                    <p className="linkend-product-details__value bold">{dataList.isReplacementPair || !dataList.warrantyValidUntil ? "-" : moment(dataList.warrantyValidUntil).format("DD/MM/YYYY")}</p>
                                </div>
                            </div>
                        </div>
                        <div className="linkend-product-details__content">
                            <div className="linkend-product-details__info">
                                <div className="linkend-product-details__info-wrapper">
                                    <p className="linkend-product-details__label">RL TYPE</p>
                                    <p className="linkend-product-details__value bold">RIGHT</p>
                                </div>
                                <div className="linkend-product-details__info-wrapper">
                                    <p className="linkend-product-details__label">RL TYPE</p>
                                    <p className="linkend-product-details__value bold">LEFT</p>
                                </div>
                            </div>
                            <div className="linkend-product-details__info">
                                <div className="linkend-product-details__info-wrapper">
                                    <p className="linkend-product-details__label">SPH</p>
                                    <p className="linkend-product-details__value bold">{dataList.rightSPH}</p>
                                </div>
                                <div className="linkend-product-details__info-wrapper">
                                    <p className="linkend-product-details__label">SPH</p>
                                    <p className="linkend-product-details__value bold">{dataList.leftSPH}</p>
                                </div>
                            </div>
                            <div className="linkend-product-details__info">
                                <div className="linkend-product-details__info-wrapper">
                                    <p className="linkend-product-details__label">CYL</p>
                                    <p className="linkend-product-details__value bold">{dataList.rightCYL}</p>
                                </div>
                                <div className="linkend-product-details__info-wrapper">
                                    <p className="linkend-product-details__label">CYL</p>
                                    <p className="linkend-product-details__value bold">{dataList.leftCYL}</p>
                                </div>
                            </div>
                            <div className="linkend-product-details__info">
                                <div className="linkend-product-details__info-wrapper">
                                    <p className="linkend-product-details__label">AXIS</p>
                                    <p className="linkend-product-details__value bold">{dataList.rightAXIS || "-"}</p>
                                </div>
                                <div className="linkend-product-details__info-wrapper">
                                    <p className="linkend-product-details__label">AXIS</p>
                                    <p className="linkend-product-details__value bold">{dataList.leftAXIS || "-"}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    alertError: (error) => {
        dispatch(alertActions.error(error));
    },
});
export default connect(null, mapDispatchToProps)(LinkedProductDetails);
