import React, { Fragment, Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { ApiService, ServiceApiUrl } from "../../_helpers/index";
import CarouselComponent from "components/carousel";
import Topbar from "components/top-bar";
import Footer from "components/footer";
import profileIcon from "assets/images/profile-icon.svg";
import rewardsIcon from "assets/images/rewards-icon.svg";
import bookingIcon from "assets/images/booking-icon.svg";

class HomePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            img: [],
        };
    }

    componentDidMount = () => {
        this.getUser();
        this.getBanner();
    };

    shareBanner = () => {
        const payload = {
            BannerId: this.state.img.id,
            Platform: "Facebook",
            Points: this.state.img.points,
        };
        this.shareThisBanner(payload)
            .then((resp) => {
                console.log(resp);
            })
            .catch((err) => {});
    };

    shareThisBanner = async (payload) => {
        const response = await ApiService.post(ServiceApiUrl.SHAREBANNER, payload);
        return response;
    };

    getBanner = () => {
        this.getAllBanners()
            .then((resp) => {
                console.log(resp.data);
                this.setState({
                    img: resp.data,
                });
            })
            .catch((err) => {});
    };

    getAllBanners = (payload) => {
        const response = ApiService.get(ServiceApiUrl.GETBANNER, payload);
        return response;
    };

    getUser = () => {
        this.requestProfile()
            .then((resp) => {
                const profileState = resp.data.userView.email;
                const profileData = resp.data.userView;
                this.saveToLocal(profileState, profileData);
            })
            .catch((err) => {});
    };

    requestProfile = (payload) => {
        const response = ApiService.get(ServiceApiUrl.VIEWPROFILE, payload);
        return response;
    };

    saveToLocal = (profileState, profileData) => {
        localStorage.setItem("profileState", JSON.stringify(profileState));
        localStorage.setItem("profileData", JSON.stringify(profileData));
    };

    render() {
        const { img } = this.state;
        return (
            <Fragment>
                <div className="landing-home-page">
                    <Topbar />
                    <CarouselComponent dataImage={img} />
                </div>

                <div className="homepage_container">
                    <div className="content_container">
                        <div className="menu-wrapper">
                            <Link to="/profile">
                                <div className="icon_bg">
                                    <img src={profileIcon} alt="" />
                                    <p>Profile</p>
                                </div>
                            </Link>
                            <Link to="/rewards">
                                <div className="icon_bg">
                                    <img src={rewardsIcon} alt="" />
                                    <p>Rewards</p>
                                </div>
                            </Link>
                            <Link to="/apptDashboard">
                                <div className="icon_bg">
                                    <img src={bookingIcon} alt="" />
                                    <p>Bookings</p>
                                </div>
                            </Link>
                        </div>
                    </div>

                    <Footer />
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    const { users, authentication } = state;
    const { user } = authentication;
    return { user, users };
};

const connectedHomePage = connect(mapStateToProps, null)(HomePage);
export { connectedHomePage as HomePage };
