import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import GoogleLogin from "react-google-login";
import { Formik } from "formik";
import * as Yup from "yup";
import Icon from "react-icons-kit";
import { ic_mail_outline } from "react-icons-kit/md/ic_mail_outline";
import { ic_lock_outline } from "react-icons-kit/md/ic_lock_outline";
import facebook from "assets/images/facebook-contact-us.svg";
import google from "assets/images/white-google.svg";
import miyobee from "assets/images/register-account-bee.png";
import Button from "components/button";
import Footer from "components/footer";

import { userActions } from "../../_actions";

class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: "",
            password: "",
            submitted: false,
            banners: [],
        };
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    };

    handleSubmit = (values) => {
        this.setState({ submitted: true });
        const { email, password } = values;
        if (email && password) {
            this.props.login(email, password);
        }
    };

    responseFacebook = (response) => {
        this.setState({ submitted: true });
        this.props.fblogin(response.email, response.name);
    };
    responseGoogle = (response) => {
        this.props.glogin(response.Zi.id_token, response.w3.U3);
    };
    render() {
        return (
            <div className="login">
                <Link to={"/landing"}>
                    <div className="hoya-logo" />
                </Link>

                <div className="signUpCard">
                    <div className="login__wrapper">
                        <div className="title cardTitle">Hi, Welcome Back!</div>
                        <div className="cardInputContainer">
                            <Formik
                                initialValues={{
                                    email: "",
                                    password: "",
                                }}
                                onSubmit={(values, { setSubmitting }) => {
                                    this.handleSubmit(values);
                                    setSubmitting(false);
                                }}
                                validationSchema={Yup.object().shape({
                                    email: Yup.string().email().required("Required"),
                                    password: Yup.string().required("Required").min(6, "min 6 character password").max(15, "try a shorter password."),
                                })}>
                                {(props) => {
                                    const { values, touched, errors, isSubmitting, handleChange, handleBlur, handleSubmit } = props;
                                    return (
                                        <form onSubmit={handleSubmit} className="loginForm" autoComplete="off">
                                            <div className="inputContainer">
                                                <div className="input_Icon">
                                                    <Icon icon={ic_mail_outline} size={20} />
                                                </div>
                                                <input id="email" type="text" placeholder="email" value={values.email} onChange={handleChange} onBlur={handleBlur} className={errors.email && touched.email ? "text-input error" : "text-input"} />

                                                {errors.email && touched.email && <div className="input-feedback">{errors.email}</div>}
                                            </div>
                                            <div className="inputContainer">
                                                <div className="input_Icon">
                                                    <Icon className="input_Icon" icon={ic_lock_outline} size={20} />
                                                </div>
                                                <input
                                                    id="password"
                                                    type="password"
                                                    placeholder="password"
                                                    value={values.password}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={errors.password && touched.password ? "text-input error" : "text-input"}
                                                />
                                                {errors.password && touched.password && <div className="input-feedback">{errors.password}</div>}
                                            </div>
                                            <Button title="LOGIN" type="submit" disabled={isSubmitting} />
                                        </form>
                                    );
                                }}
                            </Formik>
                        </div>

                        <Link to="/forgetpassword" className="forgot-btn semibold">
                            Forgot my password
                        </Link>

                        <div className="sso-login-container">
                            <p>Login in with</p>
                            <FacebookLogin appId="525844644637607" fields="name,email,picture" callback={this.responseFacebook} render={(renderProps) => <img className="socialIcons" onClick={renderProps.onClick} src={facebook} alt="" />} />
                            <GoogleLogin
                                clientId="859934599216-pg8gsk52ci51dl3mrf781g2mqim20ck5.apps.googleusercontent.com"
                                render={(renderProps) => <img className="socialIcons" src={google} onClick={renderProps.onClick} alt="" />}
                                onSuccess={this.responseGoogle}
                                onFailure={this.responseGoogle}
                            />
                        </div>

                        <div className="registor-container">
                            <Link to="/signuporderno">
                                <img className="miyobeeLogo" src={miyobee} alt="" />
                            </Link>
                        </div>
                    </div>
                </div>

                <Footer />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loggingIn: state.authentication.loggingIn,
    };
};

const mapDispatchToProps = {
    login: userActions.login,
    logout: userActions.logout,
    fblogin: userActions.fblogin,
    glogin: userActions.glogin,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
