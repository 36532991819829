import React, { Component } from "react";
import { Link } from "react-router-dom";

import Button from "components/button";
import Footer from "components/footer";

import miyobee from "assets/images/miyo-bee.svg";

export default class ForgetPasswordResettedPage extends Component {
    navigateTo = () => {
        const { history } = this.props;
        history.push("login");
    };

    render() {
        return (
            <div className="forget-password">
                <Link to="/login">
                    <div className="hoya-logo" />
                </Link>
                <div className="signUpCard">
                    <div className="forget-password__wrapper">
                        <div className="title">Your Password has been Reset</div>
                        <p className="description">Your password has been successfully changed. You can now login using your new password.</p>

                        <Button title="Login" onClick={this.navigateTo} />
                        <div className="registor-container">
                            <img className="miyobeeLogo" src={miyobee} alt="" />
                        </div>
                    </div>
                </div>

                <Footer />
            </div>
        );
    }
}
