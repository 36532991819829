import { userConstants } from "../_constants";
import { userService } from "../_services";
import { alertActions } from "./";
import { history } from "../_helpers";

export const userActions = {
    login,
    logout,
    register,
    registerSSO,
    getAll,
    fblogin,
    glogin,
    delete: _delete,
};

function login(email, password) {
    return (dispatch) => {
        dispatch(request({ email }));
        userService.login(email, password).then(
            (user) => {
                dispatch(success(user));
                history.push("home");
            },
            (error) => {
                console.log(error);
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request(user) {
        return { type: userConstants.LOGIN_REQUEST, user };
    }
    function success(user) {
        return { type: userConstants.LOGIN_SUCCESS, user };
    }
    function failure(error) {
        return { type: userConstants.LOGIN_FAILURE, error };
    }
}

function fblogin(email, name) {
    return (dispatch) => {
        dispatch(request({ email, name }));
        userService.fblogin(email, name).then(
            (user) => {
                dispatch(success(user));
                history.push("home");
            },
            (error) => {
                console.log(error);
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request(user) {
        return { type: userConstants.LOGIN_REQUEST, user };
    }
    function success(user) {
        return { type: userConstants.LOGIN_SUCCESS, user };
    }
    function failure(error) {
        return { type: userConstants.LOGIN_FAILURE, error };
    }
}

function glogin(token, email) {
    return (dispatch) => {
        dispatch(request({ email, token }));
        userService.glogin((email, token)).then(
            (user) => {
                dispatch(success(user));
                history.push("home");
            },
            (error) => {
                console.log(error);
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request(user) {
        return { type: userConstants.LOGIN_REQUEST, user };
    }
    function success(user) {
        return { type: userConstants.LOGIN_SUCCESS, user };
    }
    function failure(error) {
        return { type: userConstants.LOGIN_FAILURE, error };
    }
}

function logout() {
    userService.logout();
    return { type: userConstants.LOGOUT };
}

function register(user, callback) {
    return (dispatch) => {
        dispatch(request(user));

        userService.register(user).then(
            (user) => {
                dispatch(success());
                callback("success");
                // dispatch(alertActions.success("Registration successful"));
            },
            (error) => {
                callback("error");
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request(user) {
        return { type: userConstants.REGISTER_REQUEST, user };
    }
    function success(user) {
        return { type: userConstants.REGISTER_SUCCESS, user };
    }
    function failure(error) {
        return { type: userConstants.REGISTER_FAILURE, error };
    }
}

function registerSSO(payload, callback) {
    return (dispatch) => {
        dispatch(request(payload));

        userService.registerSSO(payload).then(
            (user) => {
                dispatch(success());
                if (typeof callback === "function") callback();
                // dispatch(alertActions.success("Registration successful"));
            },
            (error) => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request(payload) {
        return { type: userConstants.REGISTER_REQUEST, payload };
    }
    function success(payload) {
        return { type: userConstants.REGISTER_SUCCESS, payload };
    }
    function failure(error) {
        return { type: userConstants.REGISTER_FAILURE, error };
    }
}

function getAll() {
    return (dispatch) => {
        dispatch(request());

        userService.getAll().then(
            (users) => dispatch(success(users)),
            (error) => dispatch(failure(error.toString()))
        );
    };

    function request() {
        return { type: userConstants.GETALL_REQUEST };
    }
    function success(users) {
        return { type: userConstants.GETALL_SUCCESS, users };
    }
    function failure(error) {
        return { type: userConstants.GETALL_FAILURE, error };
    }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    return (dispatch) => {
        dispatch(request(id));

        userService.delete(id).then(
            (user) => dispatch(success(id)),
            (error) => dispatch(failure(id, error.toString()))
        );
    };

    function request(id) {
        return { type: userConstants.DELETE_REQUEST, id };
    }
    function success(id) {
        return { type: userConstants.DELETE_SUCCESS, id };
    }
    function failure(id, error) {
        return { type: userConstants.DELETE_FAILURE, id, error };
    }
}
