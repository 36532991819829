import React, { Component } from "react";
import Icon from "react-icons-kit";
import { chevronDown, chevronLeft } from "react-icons-kit/ionicons";
import moment from "moment";
import Pagination from "react-js-pagination";

import Layout from "components/layout";
import { ApiService, ServiceApiUrl } from "../../_helpers/index";
import { history } from "../../_helpers/history";
import { Card } from "components/card";

export default class ProfileLinkedProducts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dropdown: false,
            respNameListData: [],
            respLinkedProductData: [],
            currentPageIndex: 1,
            respTotalIndex: 1,
            profileId: "",
            currentIndex: 1,
            dropDownActiveName: "",
            dropDownActiveProfileid: "",
            activePagination: 1,
        };
    }

    componentDidMount() {
        this.getNameList();
    }

    backToProfile = () => {
        history.push("/profile");
    };
    dropDownSelected = (profileId, profileName) => {
        this.setState(
            {
                dropDownActiveProfileid: profileId,
                dropDownActiveName: profileName,
            },
            () => {
                const { dropDownActiveProfileid } = this.state;
                if (dropDownActiveProfileid) {
                    this.getLinkedProductData(dropDownActiveProfileid);
                } else {
                    this.getAllLinkedProduct();
                }
            }
        );
    };

    getAllLinkedProduct = async (i = 1) => {
        try {
            const response = await ApiService.get(process.env.REACT_APP_MIYOSMART_URL + `Link/Profile/AllLink/${i}`);
            this.setState(
                {
                    respTotalIndex: response.data.totalIndex,
                    respLinkedProductData: response.data.linkedProduct,
                    activePagination: i,
                },
                () => {
                    this.mapRespLinkedProductData();
                }
            );
        } catch (err) {
            console.log(err);
        }
    };

    getLinkedProductData = async (profileId, currentIndex = 1) => {
        const response = await ApiService.get(process.env.REACT_APP_MIYOSMART_URL + "Link/Profile/AllLink/" + profileId + "/" + currentIndex);
        this.setState(
            {
                respTotalIndex: response.data.totalIndex,
                respLinkedProductData: response.data.linkedProduct,
                activePagination: currentIndex,
            },
            () => {
                this.mapRespLinkedProductData();
            }
        );
    };

    mapRespLinkedProductData = () => {
        const mappedLinkedProductName = [];
        const { respLinkedProductData } = this.state;
        const response = respLinkedProductData;
        response.forEach((i) => {
            mappedLinkedProductName.push({
                elementTitle: "ORDER NUMBER",
                elementTitleDesc1: i.orderNumber,
                elementTitle2: " DATE OF PURCHASE",
                elementTitle2Desc: moment(i.dateOfPurchase).format("DD/MM/YYYY"),
                elementTitle3: "NAME OF OWNER",
                elementTitle3Desc: i.ownerName,
                elementTitle4: "BRANCH",
                elementTitle4Desc: i.storeName,
                elementTitle5: " END OF WARRANTY",
                elementTitle5Desc: !i.isReplacementPair && i.warrantyValidUntil ? moment(i.warrantyValidUntil).format("DD/MM/YYYY") : "-",
                data: response,
            });
        });

        this.setState({ mappedLinkedProductName });
    };

    rendercard = () => {
        const { dropDownActiveProfileid, mappedLinkedProductName, currentIndex } = this.state;
        return <Card clickLinkedProductDetails={1} key={currentIndex} cardContent={mappedLinkedProductName} icon1={"false"} icon2={"false"} pagination={"true"} hiddenProfileId={dropDownActiveProfileid} />;
    };

    getNameList = async (payload) => {
        const response = await ApiService.get(ServiceApiUrl.SHOWLINKPROFILE, payload);
        const all = {
            profileId: null,
            profileName: "ALL",
        };
        const dropdownList = [all, ...response.data];
        this.setState(
            {
                respNameListData: dropdownList,
                dropDownActiveName: dropdownList[0].profileName,
                dropDownActiveProfileid: dropdownList[0].profileId,
            },
            () => {
                this.getAllLinkedProduct();
            }
        );
    };

    togglePatientNameDropdown = (close) => {
        close === "close"
            ? this.setState({
                  dropdown: false,
              })
            : this.setState({
                  dropdown: !this.state.dropdown,
              });
    };

    addOrderNum = () => {
        history.push("/addnewordernumber");
    };

    handlePageChange = (i) => {
        const { dropDownActiveProfileid } = this.state;
        this.setState({
            activePagination: i,
        });
        if (dropDownActiveProfileid) {
            this.getLinkedProductData(dropDownActiveProfileid, i);
        } else {
            this.getAllLinkedProduct(i);
        }
    };

    render() {
        const { respTotalIndex, activePagination, respLinkedProductData } = this.state;

        console.log(respLinkedProductData);
        return (
            <Layout>
                <div className="linked-product linkedproductspage_container">
                    <div className="linked-product__header">
                        <p className="title">LINKED PRODUCT</p>
                        <div className="eye-test-history__back-btn" onClick={() => this.backToProfile()}>
                            <Icon icon={chevronLeft} size={10} />
                            <p className="bold">Back</p>
                        </div>
                    </div>

                    <div className="linkedproductspage_dropdownAndaddnewOrder">
                        <div className="linkedproductspage_dropDownContainer">
                            <div className="linkedproductspage_patientNameStyle">Patient Name:</div>
                            <div onClick={this.togglePatientNameDropdown}>
                                <div className="bold linkedproductspage_dropDownActiveName">
                                    {this.state.dropDownActiveName}
                                    {this.state.dropDownActiveName && <Icon icon={chevronDown} size={14} />}
                                </div>
                                {this.state.dropdown && (
                                    <div className="linkedproductspage_dropDownBox">
                                        {this.state.respNameListData.map((p) => {
                                            if (p.profileName) {
                                                return (
                                                    <div className="bold linkedproductspage_dropDownElements" key={p.profileName} onClick={() => this.dropDownSelected(p.profileId, p.profileName)}>
                                                        {p.profileName}
                                                    </div>
                                                );
                                            }
                                            return null;
                                        })}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="linkedproductspage_addOrderButton">
                            <p onClick={() => this.addOrderNum()}>ADD NEW ORDER NUMBER +</p>
                        </div>
                    </div>
                    <div className="linkedproductspage_cardAndPaginatorContainer">
                        {respLinkedProductData.map((data, index) => {
                            return (
                                <div className="linked-product__lists" key={index}>
                                    <div className="linked-product__item">
                                        <p className="linked-product__label">ORDER NUMBERS</p>
                                        <p className="linked-product__value bold">{data.orderNumber}</p>
                                    </div>
                                    <div className="linked-product__item">
                                        <p className="linked-product__label">DATE OF PURCHASE</p>
                                        <p className="linked-product__value bold">{moment(data.dateOfPurchase).format("DD/MM/YYYY")}</p>
                                    </div>
                                    <div className="linked-product__item">
                                        <p className="linked-product__label">NAME OF OWNER</p>
                                        <p className="linked-product__value bold">{data.ownerName}</p>
                                    </div>
                                    <div className="linked-product__item">
                                        <p className="linked-product__label">BRANCH</p>
                                        <p className="linked-product__value bold">{data.storeName}</p>
                                    </div>
                                    <div className="linked-product__item">
                                        <p className="linked-product__label">END OF WARRANTY</p>
                                        <p className="linked-product__value bold">{moment(data.warrantyValidUntil).format("DD/MM/YYYY")}</p>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <div className="pagination-container">
                        <Pagination
                            activePage={activePagination}
                            itemsCountPerPage={4}
                            pageRangeDisplayed={7}
                            totalItemsCount={respTotalIndex}
                            firstPageText={"First Page"}
                            lastPageText={"Last Page"}
                            itemClassFirst="pagination-first-child"
                            itemClassLast="pagination-last-child"
                            onChange={this.handlePageChange}
                        />
                    </div>
                </div>
            </Layout>
        );
    }
}
