import React, { Component, Fragment } from "react";
import { Card } from "reactstrap";
import { connect } from "react-redux";
import Icon from "react-icons-kit";
import { iosCheckmarkOutline } from "react-icons-kit/ionicons";

import { ApiService, ServiceApiUrl } from "../../../_helpers";
import { redempConstants } from "../../../_constants/redemp.constants";
import { alertActions } from "../../../_actions";
import CardTable from "components/card-table";
import Layout from "components/layout";
import Button from "components/button";
import PopUp from "components/pop-up";

import { tableHeader } from "../ultilities/productData";

class RewardsRedemptionConfirmationPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            profileInfo: {
                name: "",
                email: "",
                address: "",
                contacts: "",
                loading: false,
            },
            visible: false,
        };
    }

    componentDidMount() {
        console.log("Rewards Redemption Confirmation Did Mount :)");
        const { redempProduct, history, location } = this.props;

        if (!redempProduct.totalRedem.length) history.push("rewards");
        this.setState({
            profileInfo: location.state.shippingInfo,
        });
    }

    goBack = () => {
        const { history } = this.props;
        history.push("rewardsredemption");
    };

    cancel = () => {
        const { history } = this.props;
        this.props.resetRedempItems();
        history.push("rewards");
    };

    successReedemPopUp = (type) => {
        const { history } = this.props;
        if (type === "close") {
            this.setState(
                (prevState) => ({
                    visible: !prevState.visible,
                }),
                () => {
                    history.push("rewards");
                }
            );
        } else {
            this.setState((prevState) => ({
                visible: !prevState.visible,
            }));
        }
    };
    goBackRewards = () => {
        this.props.history.push("rewards");
    };

    sendReedemItems = () => {
        this.setState({ loading: true });

        const { redempProduct } = this.props;
        const { profileInfo } = this.state;
        const products = [];

        redempProduct.totalRedem.forEach((i) => {
            const productsQuantity = Array(i.addedQTY).fill(i.id);
            const p3 = productsQuantity.map(String);
            products.push(...p3);
        });

        const payload = {
            Products: products,
            MobileNumber: profileInfo.contacts,
            ShipEmail: profileInfo.email,
            ShipAddress: profileInfo.address,
            FullName: profileInfo.name,
            // Country: profileInfo.country
        };

        this.requestReedemItems(payload)
            .then((resp) => {
                //resp
                this.successReedemPopUp();
                console.log(resp);
                this.setState({ loading: false });
                this.props.resetRedempItems();
            })
            .catch((err) => {
                this.setState({ loading: false });

                const errMsg = err.response.data ? err.response.data.message : err.message;
                this.props.alertError(errMsg);
            });
    };

    requestReedemItems = async (payload) => {
        const response = await ApiService.post(ServiceApiUrl.REDEEM_CONFIRM_ITEMS, payload);
        return response;
    };

    render() {
        const { redempProduct } = this.props;
        const { profileInfo, visible } = this.state;

        return (
            <Layout>
                <div className="rewardsconfirmation-container">
                    <CardTable
                        disabledEdit
                        type="redemption-confirm"
                        title="CONFIRM YOUR REDEMPTION"
                        tableTHeadData={tableHeader.confirmRedemption}
                        tableTBodyData={redempProduct.totalRedem}
                        goBack={this.goBack}
                        tfoot={
                            <Fragment>
                                <tr>
                                    <td />
                                    <td className="title">SHIPPING</td>
                                    <td className="title">FREE</td>
                                </tr>
                                <tr>
                                    <td />
                                    <td className="title">Total Redeem Points</td>
                                    <td className="title">{`${redempProduct.totalPoints} points`}</td>
                                </tr>
                            </Fragment>
                        }
                    />
                    <p className="title">DELIVERY ADDRESS</p>
                    <Card className="user-info-container">
                        <p>{profileInfo.name}</p>
                        <p>{profileInfo.address}</p>
                    </Card>

                    <p>*T&C apply. Only applicable within Malaysia.</p>

                    <div className="button-container">
                        <Button title="CONFIRM ORDER" onClick={this.sendReedemItems} />
                        <Button title="CANCEL" outline onClick={this.cancel} />
                    </div>
                    <PopUp
                        onClosed={() => {
                            this.props.history.push("/rewards");
                        }}
                        className="rewards-redemption-confirmation-popup"
                        visible={visible}
                        toggle={this.successReedemPopUp}>
                        <div className="pop-up-card">
                            <Icon icon={iosCheckmarkOutline} size={100} />
                            <h1 className="title">SUBMITTED</h1>
                            <p className="description">A confirmation email will sent to you shortly.</p>
                        </div>
                    </PopUp>
                </div>
            </Layout>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        redempProduct: state.redempProduct,
    };
};

const mapDispatchToProps = (dispatch) => ({
    alertError: (error) => {
        dispatch(alertActions.error(error));
    },
    resetRedempItems: (data) => {
        dispatch({ type: redempConstants.INIT_REDEMPTION, data });
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(RewardsRedemptionConfirmationPage);
