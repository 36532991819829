import { authHeader } from "../_helpers";
const config = process.env.REACT_APP_MIYOSMART_URL;
// const config = "https://backend.altecflex.com";

export const userService = {
    login,
    logout,
    register,
    registerSSO,
    getAll,
    getById,
    update,
    fblogin,
    glogin,
};

function login(email, password) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email, password }),
    };

    return fetch(`${config}Account/Login`, requestOptions)
        .then(handleResponse)
        .then((user) => {
            localStorage.setItem("user", JSON.stringify(user));
            return user;
        });
}

function fblogin(email, name) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ name, email }),
    };

    return fetch(`${config}Account/Login/Facebook`, requestOptions)
        .then(handleResponse)
        .then((user) => {
            localStorage.setItem("user", JSON.stringify(user));
            return user;
        });
}

function glogin(token, email) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ token, email }),
    };

    return fetch(`${config}Account/Login/Google`, requestOptions)
        .then(handleResponse)
        .then((user) => {
            localStorage.setItem("user", JSON.stringify(user));
            return user;
        });
}

function logout() {
    localStorage.removeItem("user");
    localStorage.removeItem("profileState");
    localStorage.removeItem("profileData");
}

function getAll() {
    const requestOptions = {
        method: "GET",
        headers: authHeader(),
    };

    return fetch(`${config}/users`, requestOptions).then(handleResponse);
}

function getById(id) {
    const requestOptions = {
        method: "GET",
        headers: authHeader(),
    };

    return fetch(`${config}/users/${id}`, requestOptions).then(handleResponse);
}

function register(user) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(user),
    };

    return fetch(`${config}Account/Signup`, requestOptions).then(handleResponse);
}

function registerSSO(payload) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
    };

    return fetch(`${config}Account/SignUp/SSO`, requestOptions).then(handleResponse);
}

function update(user) {
    const requestOptions = {
        method: "PUT",
        headers: { ...authHeader(), "Content-Type": "application/json" },
        body: JSON.stringify(user),
    };

    return fetch(`${config}/users/${user.id}`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then((text) => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                logout();
                window.location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
