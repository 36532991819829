import React from "react";
import { Route, Redirect, withRouter } from "react-router-dom";
import jwt from "jsonwebtoken";
import { connect } from "react-redux";
import Profile from "../../screens/Profile/Profile";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const getToken = Object.keys(rest.authentication).length;
  if (!getToken) {
    return <Redirect to={{ pathname: "/admin" }} />;
  } else {
    const validToken = jwt.decode(rest.authentication.user.token);
    const FinalComponent =
      validToken.profileComplete.length > 1 &&
      validToken.profileComplete === "False"
        ? Profile
        : Component;
    return <Route {...rest} component={FinalComponent} />;
  }
};

const mapStateToProps = state => {
  return {
    authentication: state.authentication
  };
};

export default connect(mapStateToProps, null)(withRouter(PrivateRoute));
