import React, { Component } from "react";
import { Input } from "reactstrap";
import { Formik } from "formik";
import Icon from "react-icons-kit";
import { iosCheckmarkOutline } from "react-icons-kit/ionicons";
import { ic_expand_more } from "react-icons-kit/md/ic_expand_more";
import { ic_chevron_left, ic_chevron_right } from "react-icons-kit/md/";
import { connect } from "react-redux";
import { Calendar } from "react-calendar";
import moment from "moment";

import { alertActions } from "../../../_actions";
import Layout from "components/layout";
import Button from "components/button";
import PopUp from "components/pop-up";
import { ApiService, ServiceApiUrl } from "../../../_helpers";
import { timeSlotData } from "../../../_constants/constants";

class ApptEditPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {},
            visible: false,
            hide: false,
            date: new Date(),
            overlay: false,
            selectedTimeSlot: {},
        };
    }

    componentDidMount() {
        console.log("APPT Edit Did Mount :)");
        const { history } = this.props;
        if (history.location.state) {
            this.setState(
                {
                    data: history.location.state,
                    date: moment(history.location.state.appointmentTime)._d,
                    selectedTimeSlot: timeSlotData[history.location.state.slot - 1],
                },
                () => {
                    this.getTimeSlot();
                }
            );
        }
    }
    showHide = () => {
        this.setState({
            hide: !this.state.hide,
            overlay: !this.state.overlay,
        });
    };

    handleChange = (date) => {
        this.setState(
            {
                date: date,
            },
            () => {
                this.getTimeSlot();
            }
        );
    };

    closeOverlay = () => {
        this.setState({
            hide: !true,
            overlay: !true,
        });
    };

    handleSubmit = () => {
        const { date, data, selectedTimeSlot } = this.state;
        if (Object.keys(selectedTimeSlot).length) {
            const payload = {
                Id: data.appointmentId,
                RequestedDate: moment(date).format("DD/MM/YYYY"),
                RequestedSlot: selectedTimeSlot.index + 1,
            };
            this.requestChangeAppt(payload)
                .then((resp) => {
                    this.successBookedApptPopUp();
                })
                .catch((err) => {
                    const errMsg = err.response.data ? err.response.data.message : err.message;
                    this.props.alertError(errMsg);
                });
        }
    };

    getTimeSlot = () => {
        this.requestTimeSlot()
            .then((resp) => {
                console.log(resp);
                this.setState({
                    slotsAvailable: resp.data.slotsAvailable,
                });
            })
            .catch((err) => {
                console.log(err);
            });
    };

    requestChangeAppt = async (payload) => {
        const { data } = this.state;
        const response = await ApiService.put_(`${ServiceApiUrl.APPT_CHANGE}${data.profileId}`, payload);
        return response;
    };

    requestTimeSlot = async () => {
        const { data, date } = this.state;
        const payload = {
            StoreId: data.soreId,
            Date: moment(date || data.appointmentTime).format("YYYY-MM-DD"),
        };
        const response = await ApiService.post(`${ServiceApiUrl.APPT_TIME_SLOT}`, payload);
        return response;
    };

    successBookedApptPopUp = (type) => {
        const { history } = this.props;
        if (type === "close") {
            this.setState(
                (prevState) => ({
                    visible: !prevState.visible,
                }),
                () => {
                    history.goBack();
                }
            );
        } else {
            this.setState((prevState) => ({
                visible: !prevState.visible,
            }));
        }
    };

    setTimeSlot = (data) => {
        this.setState({
            selectedTimeSlot: data,
        });
    };

    render() {
        const { data, visible, hide, date, overlay, slotsAvailable, selectedTimeSlot } = this.state;
        const { history } = this.props;
        return (
            <Layout>
                {overlay ? <div id="overlay" onClick={this.closeOverlay} /> : null}
                {hide && slotsAvailable.length > 0 ? (
                    <div className="edit-appt-calendar">
                        <div className="calendar-wrapper">
                            <div className="appt-calendar">
                                <Calendar
                                    minDate={moment().toDate()}
                                    onChange={this.handleChange}
                                    value={date}
                                    locale="US"
                                    prevLabel={<Icon icon={ic_chevron_left} size={20} className="calendar_button" />}
                                    nextLabel={<Icon icon={ic_chevron_right} size={20} className="calendar_button" />}
                                    prev2Label={null}
                                    next2Label={null}
                                />
                                <div className="timeselect_container">
                                    <div className="timeselect_title">
                                        <p className="title">TIME SLOT</p>
                                        {timeSlotData.map((data, i) => {
                                            const selectedTime = selectedTimeSlot && selectedTimeSlot.index === i ? "active semibold" : "semibold";
                                            return (
                                                <button key={`${data.index}_${i}_time_slot`} disabled={!slotsAvailable[i]} className={selectedTime} type="button" onClick={() => this.setTimeSlot(data)}>
                                                    {data.label}
                                                </button>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null}
                <div className="apptEdit-container">
                    <p className="title">EDIT APPOINTMENT</p>
                    {data.patientName && (
                        <Formik
                            initialValues={{
                                name: data.patientName,
                            }}
                            onSubmit={(values, { setSubmitting }) => {
                                this.handleSubmit(values);
                                setSubmitting(false);
                            }}>
                            {(props) => {
                                const { isSubmitting, handleChange, handleBlur, handleSubmit } = props;
                                return (
                                    <form onSubmit={handleSubmit}>
                                        <div className="input-container">
                                            <p className="input-label">Patient Name</p>
                                            <input id="name" className="bold" type="text" value={data.patientName} onChange={handleChange} onBlur={handleBlur} disabled />
                                        </div>
                                        <div className="datetime_container">
                                            <p className="input-label">Select Date and Time:</p>
                                            <div className="icon_Container">
                                                <Icon icon={ic_expand_more} className="icon_chevron" size={25} onClick={this.showHide} />
                                                <Input
                                                    className="datetime_input bold"
                                                    maxLength={20}
                                                    value={`${moment(date).format("DD/MM/YYYY")} ${selectedTimeSlot.label || ""}`}
                                                    onChange={handleChange}
                                                    onClick={this.showHide}
                                                    disabled={!timeSlotData}
                                                />
                                            </div>
                                        </div>

                                        <div className="input-button-container">
                                            <Button type="submit" title="REQUEST" disabled={isSubmitting} />
                                            <Button type="button" title="CANCEL" outline onClick={() => history.goBack()} />
                                        </div>
                                    </form>
                                );
                            }}
                        </Formik>
                    )}
                </div>
                <PopUp
                    className="appt-edit-popup"
                    onClosed={() => {
                        this.props.history.push("/apptDashboard");
                    }}
                    visible={visible}
                    toggle={this.successBookedApptPopUp}>
                    <div className="pop-up-card">
                        <Icon icon={iosCheckmarkOutline} size={100} />
                        <h1 className="title">Your request has been made.</h1>

                        <p className="description">An email will be sent to you upon confirmation.</p>
                    </div>
                </PopUp>
            </Layout>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    alertError: (error) => {
        dispatch(alertActions.error(error));
    },
});
export default connect(null, mapDispatchToProps)(ApptEditPage);
