import React, { Component } from "react";
import { Row, Col, Card } from "reactstrap";
import Icon from "react-icons-kit";
import { chevronLeft } from "react-icons-kit/ionicons/";

export default class RedemptionChartCardComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { title, redemptionChartData, goBack, onChange } = this.props;
        return (
            <div className="redemption-chart-container">
                {title && (
                    <div className="card-header-container">
                        <h1 className="title">{title}</h1>
                        {goBack && (
                            <div className="back-btn-container" onClick={goBack}>
                                <Icon icon={chevronLeft} size={10} />
                                <p>Back</p>
                            </div>
                        )}
                    </div>
                )}
                <Card className="redemption-chart-card">
                    <Row className="rc-card-header">
                        <Col>ITEM</Col>
                        <Col>QTY</Col>
                        <Col>MIYO POINT</Col>
                    </Row>
                    <div className="rc-card-content">
                        {redemptionChartData.map((data, i) => {
                            return (
                                <Row className="rc-card-role" key={`${data.description}_shipping_details_row`}>
                                    <Col>
                                        <p className="rc-col-text ">{data.name}</p>
                                        <p className="label">{data.points} points</p>
                                    </Col>
                                    <Col className="rc-button-container">
                                        <div className="rc-button-wrapper">
                                            <span className="rc-button" onClick={() => onChange(data, "minus")}>
                                                -
                                            </span>
                                            <span className="rc-quantity-counter">{data.addedQTY}</span>
                                            <span className="rc-button " onClick={() => onChange(data, "plus")}>
                                                +
                                            </span>
                                        </div>
                                        <p onClick={() => onChange(data, "remove")}>Remove</p>
                                    </Col>
                                    <Col>
                                        <span className="rc-col-text points-text">{data.points}pts</span>
                                    </Col>
                                </Row>
                            );
                        })}
                    </div>
                </Card>
            </div>
        );
    }
}
