import React, { Component } from "react";
import { Row, Col, Card } from "reactstrap";
import Icon from "react-icons-kit";
import { userOutline, flagOutline, phoneOutline, locationOutline, pencil, mail } from "react-icons-kit/typicons";

export default class ShippingDetailsCardComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const iconSelectorBasedOnLabelName = (labelName) => {
            switch (labelName) {
                case "FULL NAME":
                    return userOutline;
                case "COUNTRY / REGION":
                    return flagOutline;
                case "CONTACT NUMBER":
                    return phoneOutline;
                case "SHIPPING ADDRESS":
                    return locationOutline;
                case "EMAIL":
                    return mail;
                default:
                    break;
            }
        };
        const { shippingDetails, title, onEdit } = this.props;
        return (
            <div className="shipping-card-container">
                <h1 className="title">{title}</h1>
                <Card className="shipping-details-card">
                    {shippingDetails.map((data, i) => {
                        return (
                            <Row className="sd-card-role" key={`${i}_shipping_details_row`}>
                                <Col className="col-1">
                                    <Icon size={24} className="sd-details-icon-style" icon={iconSelectorBasedOnLabelName(data.label)} />
                                </Col>
                                <Col>
                                    <p className="label">{data.label}</p>
                                    <p className="desc bold">{data.desc}</p>
                                </Col>
                                <Col className="col-1 edit-col" onClick={() => onEdit(data.label)}>
                                    <Icon icon={pencil} size={26} />
                                </Col>
                            </Row>
                        );
                    })}
                </Card>
            </div>
        );
    }
}
