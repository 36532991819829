import React, { Component } from "react";
import { Row } from "reactstrap";
import { connect } from "react-redux";

import { redempConstants } from "../../../_constants/redemp.constants";
import { ApiService, ServiceApiUrl } from "../../../_helpers";
import Layout from "components/layout";
import ProductCard from "components/product-card";

class RewardsDashboardPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reedemPoints: "0",
            reedemItems: [],
        };
    }

    componentDidMount() {
        console.log("Rewards Dashboard Did Mount :)");
        this.getReedemItems();
    }

    getReedemItems = () => {
        this.requestReedemItems()
            .then((resp) => {
                this.props.updateCurrentPoints(resp.data.points);
                this.setState(
                    {
                        reedemPoints: resp.data.points,
                        reedemItems: resp.data.itemList,
                    },
                    () => {
                        console.log(this.state);
                    }
                );
            })
            .catch((err) => {
                alert(err);
            });
    };

    requestReedemItems = async () => {
        const response = await ApiService.get(ServiceApiUrl.REDEEM_ITEMS);
        return response;
    };

    navigateToMiyoPointSummary = () => {
        const { history } = this.props;
        history.push("rewards-points-summary");
    };

    navigateToReedemItems = (item) => {
        const { history } = this.props;
        const { reedemPoints } = this.state;
        history.push("rewardsredemption", { currentPts: reedemPoints });
        item.addedQTY = 0;
        this.props.updateTotalRedemp(item);
    };

    render() {
        const { reedemPoints, reedemItems } = this.state;
        return (
            <Layout>
                <div className="rewardsDashboard-container">
                    <div className="rewards-category">
                        <h1 className="title">MiYO POINTS</h1>
                        <Row className="redemption-row">
                            <ProductCard miyoPoints={reedemPoints.toString()} buttonText="VIEW SUMMARY" onClick={this.navigateToMiyoPointSummary} />
                        </Row>
                    </div>
                    <br />
                    <div className="redemption-category">
                        <h1 className="title">REDEMPTION</h1>
                        <Row className="redemption-row">
                            {reedemItems.map((item, i) => {
                                return <ProductCard key={`${i}_${item.title}`} title={item.name} subtitle={`${item.points} points`} productImg={item.image} onClick={() => this.navigateToReedemItems(item)} />;
                            })}
                        </Row>
                    </div>
                </div>
            </Layout>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loggingIn: state.authentication.loggingIn,
        redempProduct: state.redempProduct,
    };
};

const mapDispatchToProps = (dispatch) => ({
    updateTotalRedemp: (data) => {
        dispatch({ type: redempConstants.UPDATE_TOTAL_REDEMP, data });
    },
    updateCurrentPoints: (data) => {
        dispatch({ type: redempConstants.REDEMPTION_TOTAL_POINT, data });
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(RewardsDashboardPage);
