import React, { Component } from "react";
import NavBar from "components/nav-bar";
import Button from "components/button";

import aboutBg1 from "assets/images/aboutBg1.png";
import aboutBg2 from "assets/images/aboutBg2.png";
import aboutBg3 from "assets/images/aboutBg3.png";
import aboutMiyoSmartClub from "assets/images/about-miyosmart-club.png";
import aboutSpecialCoat from "assets/images/about-special-coatings.png";
import aboutCosmeticApp from "assets/images/about-cosmetic-appearance.png";
import aboutDIMS from "assets/images/about-d.i.m.s-techonology.png";
import aboutEyeShield from "assets/images/about-eye-shield.png";
import aboutAwardWin from "assets/images/about-award-winning.png";
import aboutSeekRegular from "assets/images/about-seek-regular-eye-care.png";
import aboutSpendTime from "assets/images/about-spend-time-outdoors.png";
import aboutGiveEye from "assets/images/about-gives-eyes-a-break.png";
import aboutKeepEye from "assets/images/about-keep-eyes-healthy.png";
import aboutBee from "assets/images/about-bee-&-tech-bee-3.png";
import Bottom from "components/bottom";

export default class About extends Component {
    render() {
        return (
            <div className="about-page">
                <div className="about">
                    <NavBar />
                    <div className="about-1">
                        <div className="about-1__main">
                            <div className="about-1__left">
                                <div className="about-1__left-content">
                                    <div>
                                        <div className="bold">A safe,</div>
                                        <div className="bold">easy to use,</div>
                                        <div className="bold">effective method</div>
                                        <div className="bold">to manage</div>
                                        <div className="bold">myopia.</div>
                                    </div>
                                </div>
                            </div>
                            <img className="about-1__right" src={aboutBg1} alt="" />
                        </div>
                    </div>

                    <div className="about-2">
                        <div className="about-2__left" />
                        <img className="about-2__left-image" src={aboutBg2} alt="" />
                        <div className="about-2__right">
                            <div className="about-2__right-container">
                                <div className="about-2__right-title">
                                    <div>MiYOSMART lenses are innovative</div>
                                    <div>spectacle lenses for myopia control.</div>
                                </div>
                                <div className="about-2__right-body">A two-year clinical trial showed that MiYOSMART lenses effectively:</div>
                                <div className="about-2__right-body">
                                    <div>
                                        • Slows myopia progression by 59% <sup>(3)</sup>
                                    </div>
                                    <div>
                                        • Slows axial eye growth by 60% <sup>(3)</sup>
                                    </div>
                                    <div>
                                        • Halts myopia progression by 21.5% <sup>(3)</sup>
                                    </div>
                                </div>
                                <div className="about-2__right-citation">
                                    (3) Lam CSY, Tang WC, Tse DY, Lee RPK, Chun RKM, Hasegawa K, Qi H, Hatanaka T, To CH. Defocus Incorporated Multiple Segments (DIMS) spectacle lenses slow myopia progression a 2-year randomised clinical trial.
                                    British Journal of Ophthalmology. Published Online First: 29 May 2019, doi: 10.1136/bjophthalmol-2018-313739
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="about-3">
                        <div className="about-3__main">
                            <div className="about-3__title">Child-friendly and easy to use.</div>
                            <div className="about-3__sub-title">
                                <div>MiYOSMART provides comfort and convenience with its special features</div>
                                <div>and online platform to track your child’s myopia progression.</div>
                            </div>
                            <div className="about-3__content">
                                <div className="about-3__content-item">
                                    <div className="about-3__content-item-icon">
                                        <img src={aboutAwardWin} alt="" />
                                    </div>
                                    <div className="about-3__content-item-description">Award-winning</div>
                                </div>
                                <div className="about-3__content-item">
                                    <div className="about-3__content-item-icon">
                                        <img src={aboutDIMS} alt="" />
                                    </div>
                                    <div className="about-3__content-item-description">D.I.M.S. Technology</div>
                                </div>
                                <div className="about-3__content-item">
                                    <div className="about-3__content-item-icon">
                                        <img src={aboutEyeShield} alt="" />
                                    </div>
                                    <div className="about-3__content-item-description">Eye Shield</div>
                                </div>
                                <div className="about-3__content-item">
                                    <div className="about-3__content-item-icon">
                                        <img src={aboutSpecialCoat} alt="" />
                                    </div>
                                    <div className="about-3__content-item-description">Special Coatings</div>
                                </div>
                                <div className="about-3__content-item">
                                    <div className="about-3__content-item-icon">
                                        <img src={aboutCosmeticApp} alt="" />
                                    </div>
                                    <div className="about-3__content-item-description">Cosmetic Appearance</div>
                                </div>
                                <div className="about-3__content-item">
                                    <div className="about-3__content-item-icon">
                                        <img src={aboutMiyoSmartClub} alt="" />
                                    </div>
                                    <div className="about-3__content-item-description">MiYOSMART Club</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="about-4">
                        <div className="about-4__left">
                            <div className="about-4__left-content">
                                <div className="about-4__left-content-title">Give your children better vision.</div>
                                <div className="about-4__left-content-body">
                                    <ol>
                                        <li>
                                            <div className="about-4__left-content-body-title">Consult an eye care professional (ECP)</div>
                                            <div className="about-4__left-content-body-body">Get an assessment of your child’s eye health, to understand myopia management and the options available</div>
                                        </li>
                                        <li>
                                            <div className="about-4__left-content-body-title">Get MiYOSMART lenses</div>
                                            <div className="about-4__left-content-body-body">Slow down myopia progression and axial elongation with daily wear of MiYOSMART lenses</div>
                                        </li>
                                        <li>
                                            <div className="about-4__left-content-body-title">Assess adaptation after 2 weeks</div>
                                            <div className="about-4__left-content-body-body">Answer a performance assessment with your ECP and practice good visual hygiene at home.</div>
                                        </li>
                                        <li>
                                            <div className="about-4__left-content-body-title">Return every 6 months</div>
                                            <div className="about-4__left-content-body-body">Assess your child’s myopia progression, eye health and wearing position with your ECP</div>
                                        </li>
                                        <li></li>
                                    </ol>
                                    <ul>
                                        <li>
                                            <div className="about-4__left-content-body-title">Follow up with eye care</div>
                                            <div className="about-4__left-content-body-body">Continue to follow up with your ECP and monitor your child’s myopia progression on MiYOSMART Club.</div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="about-4__right" />
                        <img className="about-4__right-image" src={aboutBg3} alt="" />
                    </div>

                    <div className="about-5">
                        <div className="about-5__main">
                            <div className="about-5__title">Care goes beyond the lens.</div>
                            <div className="about-5__body">
                                <div className="about-5__item">
                                    <div className="about-5__item-icon">
                                        <img src={aboutSpendTime} alt="" />
                                    </div>
                                    <div className="about-5__item-body">
                                        <div className="about-5__item-title">Spend time outdoors</div>
                                        <div className="about-5__item-content">
                                            <div>Research shows that spending time outdoors</div>
                                            <div>
                                                may reduce the risk of myopia. <sup>(4)</sup>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="about-5__item">
                                    <div className="about-5__item-icon">
                                        <img src={aboutGiveEye} alt="" />
                                    </div>
                                    <div className="about-5__item-body">
                                        <div className="about-5__item-title">Gives eyes a break</div>
                                        <div className="about-5__item-content">
                                            <div>Reduce your child's eye strain with</div>
                                            <div>regular breaks from screen time.</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="about-5__item">
                                    <div className="about-5__item-icon">
                                        <img src={aboutKeepEye} alt="" />
                                    </div>
                                    <div className="about-5__item-body">
                                        <div className="about-5__item-title">Keep eyes healthy</div>
                                        <div className="about-5__item-content">
                                            <div>Take note of proper lighting, posture</div>
                                            <div>and recommended working distance.</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="about-5__item">
                                    <div className="about-5__item-icon">
                                        <img src={aboutSeekRegular} alt="" />
                                    </div>
                                    <div className="about-5__item-body">
                                        <div className="about-5__item-title">Seek regular eye care</div>
                                        <div className="about-5__item-content">
                                            <div>Get your child regular check-ups to</div>
                                            <div>detect and treat vision problems early.</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="about-5__bottom">
                                <div>
                                    <Button title="FIND A MiYOSMART CENTRE" customClass="about-5__bottom-button" link={"https://www.hoyavision.com/my/find-eye-care-practitioner/"} />
                                </div>
                                <div className="about-5__about-bee">
                                    <img src={aboutBee} alt="" />
                                </div>
                            </div>
                            <div className="about-5__citation">(4) Shah R.L. et al, Time outdoors at specific ages during early childhood and the risk of incident myopia. Investigative ophthalmology & visual science. 2/2017, 58(2) pp 1158–1166.</div>
                        </div>
                    </div>
                    <Bottom />
                </div>
            </div>
        );
    }
}
