export const currentApptData = [
  {
    name: "TAN BOON KEAT",
    location: "FOCUS POINT LEISURE MALL",
    date: "15/11/2019, 3PM - 5PM",
    status: "APPROVED"
  },
  {
    name: "TAN BOON KEAT",
    location: "FOCUS POINT LEISURE MALL",
    date: "30/11/2019, 3PM - 5PM",
    status: "APPROVED"
  },
  {
    name: "TAN BOON KEAT",
    location: "FOCUS POINT LEISURE MALL",
    date: "13/12/2019, 3PM - 5PM",
    status: "APPROVED"
  },
  {
    name: "TAN BOON KEAT",
    location: "FOCUS POINT LEISURE MALL",
    date: "13/12/2019, 3PM - 5PM",
    status: "APPROVED"
  },
  {
    name: "TAN BOON KEAT",
    location: "FOCUS POINT LEISURE MALL",
    date: "13/12/2019, 3PM - 5PM",
    status: "APPROVED"
  },
  {
    name: "TAN BOON KEAT",
    location: "FOCUS POINT LEISURE MALL",
    date: "13/12/2019, 3PM - 5PM",
    status: "APPROVED"
  },
  {
    name: "TAN BOON KEAT",
    location: "FOCUS POINT LEISURE MALL",
    date: "13/12/2019, 3PM - 5PM",
    status: "APPROVED"
  }
];

export const completedApptData = [
  {
    name: "TAN BOON KEAT",
    location: "FOCUS POINT LEISURE MALL",
    date: "15/11/2019, 3PM - 5PM"
  },
  {
    name: "TAN BOON KEAT",
    location: "FOCUS POINT LEISURE MALL",
    date: "30/11/2019, 3PM - 5PM"
  },
  {
    name: "TAN BOON KEAT",
    location: "FOCUS POINT LEISURE MALL",
    date: "13/12/2019, 3PM - 5PM"
  }
];

export const tableHeader = {
  currentApptHeader: [
    {
      label: "Patient Name",
      key: "patientName"
    },
    {
      label: "Location",
      key: "storeName"
    },
    {
      label: "Date And Time",
      key: "appointmentTime",
      format: "dateTime"
    },
    {
      label: "Status",
      key: "status"
    },
    {
      label: "",
      key: "edit"
    }
  ],
  completedApptHeader: [
    {
      label: "Patient Name",
      key: "patientName"
    },
    {
      label: "Location",
      key: "storeName"
    },
    {
      label: "Date",
      key: "appointmentTime",
      format: "date"
    }
  ]
};
