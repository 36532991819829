import React, { Component } from "react";
import { ApiService, ServiceApiUrl } from "../../_helpers/index";
import { Card, CardHeader, CardBody, CardFooter, Row, Col, Tooltip } from "reactstrap";

import NavBar from "components/nav-bar";
import Bottom from "components/bottom";

import iconRightArrow from "assets/images/testimonials-right-arrow.png";
import iconCopyLink from "assets/images/testimonials-copy-link.png";
import iconAddMore from "assets/images/testimonials-add-more.png";

// import testImage from 'assets/images/testimonials-image.png';
// import testVideo from 'assets/images/testimonials-video.mp4';

const cardGroupLength = 4;
export default class Testimonials extends Component {
    state = {
        articlesVideo: null,
        selectedVideo: 0,
        articles: [],
        articlesPage: 1,
        totalPage: 1,
    };

    componentDidMount = () => {
        this.getArticles(this.state.articlesPage);
        this.getArticlesVideo();
    };

    getArticlesAPI = async (page) => {
        const response = await ApiService._get(ServiceApiUrl.GETARTICLE + page + "/" + cardGroupLength);
        return response;
    };

    getArticles = async (page) => {
        let tempArticles = [];
        let tempTotalPage = 0;

        try {
            const response = await this.getArticlesAPI(page);
            if (response.data.articles) {
                if (response.data.articles.length !== 0) {
                    tempArticles = response.data.articles;
                    tempTotalPage = response.data.totalPage;
                }
            }
        } catch (error) {}

        if (tempArticles.length !== 0) {
            let articlesStore = [];

            for (var i = 0; i < tempArticles.length; i += 2) {
                const j = i;
                if (tempArticles[j + 1]) {
                    articlesStore = [
                        ...articlesStore,
                        [
                            { ...tempArticles[j], tooltipOpen: false },
                            { ...tempArticles[j + 1], tooltipOpen: false },
                        ],
                    ];
                } else {
                    articlesStore = [...articlesStore, [{ ...tempArticles[j], tooltipOpen: false }]];
                }
            }
            this.setState({
                ...this.state,
                articles: [...this.state.articles, ...articlesStore],
                totalPage: tempTotalPage,
            });
        }
    };

    getArticlesVideo = async () => {
        try {
            const response = await ApiService._get(ServiceApiUrl.GETARTICLEVIDEO);
            if (response.data.articles) {
                if (response.data.articles.length !== 0) {
                    this.setState({
                        ...this.state,
                        articlesVideo: response.data.articles,
                        selectedVideo: 0,
                    });
                }
            }
        } catch (error) {}
    };

    setShownVideo = (index) => {
        this.setState({
            ...this.state,
            selectedVideo: index,
        });
    };

    openNewWindow = (url) => {
        window.open(url);
    };

    copyUrl = (e, url) => {
        e.stopPropagation();
        navigator.clipboard.writeText(url);
    };

    toggle = (rowIndex, index) => {
        const tempArticles = this.state.articles;

        tempArticles[rowIndex][index].tooltipOpen = !tempArticles[rowIndex][index].tooltipOpen;
        this.setState({ ...this.state, articles: tempArticles });
    };

    addMoreArticles = () => {
        const tempPage = this.state.articlesPage + 1;

        this.setState({ ...this.state, articlesPage: tempPage });
        this.getArticles(tempPage);
    };

    render() {
        const { selectedVideo, articlesVideo, articles, totalPage, articlesPage } = this.state;

        return (
            <div className="testimonials-page">
                <div className="testimonials">
                    <NavBar />
                    <div className="testimonials-1">
                        <div className="testimonials-1__main">
                            <div className="testimonials-1__main-title">MiYOSMART: a breakthrough in myopia control</div>
                            {articlesVideo ? (
                                <div className="testimonials-1__main-body">
                                    <div className="testimonials-1__main-body-video">
                                        <div className="testimonials-1__main-body-video-wrap">
                                            <video key={selectedVideo} className="testimonials-1__main-body-video-video" controls>
                                                {/* <source src={testVideo} type="video/mp4"/> */}
                                                <source src={articlesVideo[selectedVideo].content} />
                                            </video>
                                        </div>
                                    </div>
                                    <div className="testimonials-1__main-body-image-list">
                                        {/* <img className="testimonials-1__main-body-image" src={testImage} alt=""/> */}
                                        {articlesVideo.map((video, index) => (
                                            <img
                                                key={index}
                                                alt=""
                                                className={["testimonials-1__main-body-image", selectedVideo === index ? "testimonials-1__main-body-image--selected" : ""].join(" ")}
                                                src={video.thumbnail}
                                                onClick={() => this.setShownVideo(index)}
                                            />
                                        ))}
                                    </div>
                                </div>
                            ) : null}
                        </div>
                    </div>

                    <div className="testimonials-2">
                        <div className="testimonials-2__main">
                            <div className="testimonials-2__main-title">Articles</div>
                            {articles
                                ? articles.length > 0
                                    ? articles.map((rowArticle, rowIndex) => (
                                          <Row key={rowIndex}>
                                              {rowArticle.map((article, index) => (
                                                  <Col key={index} sm="6">
                                                      <Card body onClick={() => this.openNewWindow(article.content)}>
                                                          <CardHeader>
                                                              <div className="testimonials-2__main-card-header-title">
                                                                  <div className="testimonials-2__main-card-header-title-type">{article.articleType === 1 ? "URL" : article.articleType === 2 ? "PDF" : article.articleType === 3 ? "VIDEO" : ""}</div>
                                                                  <div className="testimonials-2__main-card-header-title-title">{article.title}</div>
                                                              </div>
                                                              <div className="testimonials-2__main-card-header-tooltip" id={"Tooltip" + rowIndex + index}>
                                                                  <img className="testimonials-2__main-card-header-icon" onClick={(e) => this.copyUrl(e, article.content)} src={iconCopyLink} alt="" />
                                                              </div>
                                                              <Tooltip placement="top" target={"Tooltip" + rowIndex + index} isOpen={article.tooltipOpen} toggle={() => this.toggle(rowIndex, index)}>
                                                                  Copy to clipboard
                                                              </Tooltip>
                                                          </CardHeader>
                                                          <CardBody>
                                                              <div className="testimonials-2__main-card-header-body">{article.description}</div>
                                                          </CardBody>
                                                          <CardFooter>
                                                              <div>
                                                                  <img src={iconRightArrow} alt="" />
                                                              </div>
                                                          </CardFooter>
                                                      </Card>
                                                  </Col>
                                              ))}
                                          </Row>
                                      ))
                                    : null
                                : null}
                            {articlesPage < totalPage ? (
                                <div className="testimonials-2__main-bottom">
                                    See More
                                    <img className="testimonials-2__main-bottom-button" src={iconAddMore} onClick={this.addMoreArticles} alt="" />
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
                <Bottom />
            </div>
        );
    }
}
