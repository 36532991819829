import RewardsDashboardPage from "./RewardsDashboard/RewardsDashboard";
import RewardsPointsSummaryPage from "./RewardsPointSummary/RewardsPointSummary";
import RewardsRedemptionConfirmationPage from "./RewardsRedemptionConfirmation/RewardsRedemptionConfirmation";
import RewardsRedemptionPage from "./RewardsRedemption/RewardsRedemption";

const RewardsDashboard = RewardsDashboardPage;
const RewardsRedemption = RewardsRedemptionPage;
const RewardsPointsSummary = RewardsPointsSummaryPage;
const RewardsRedemptionConfirmation = RewardsRedemptionConfirmationPage;

export {
  RewardsRedemption,
  RewardsDashboard,
  RewardsPointsSummary,
  RewardsRedemptionConfirmation
};
