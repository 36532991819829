import React, { Component, Fragment } from "react";
import { Badge, Button } from "reactstrap";
import Icon from "react-icons-kit";
import { Link, withRouter } from "react-router-dom";
import { mail } from "react-icons-kit/feather/mail";
import { connect } from "react-redux";
import { ic_person_outline, ic_star_border } from "react-icons-kit/md/";
import { iosUnlockedOutline, chevronDown } from "react-icons-kit/ionicons";
import { calendar } from "react-icons-kit/feather/calendar";

import { ApiService, ServiceApiUrl } from "_helpers/index";
import { ServiceApi } from "_constants/apiconstants";
import { userActions } from "_actions";
import notificationBell from "assets/images/notification-bell.svg";

class Topbar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dropdown: false,
            dropdownBell: false,
            profileName: "",
            notifications: "",
            notinumber: "",
        };
    }

    componentDidMount = () => {
        const { pageType } = this.props;
        if (pageType !== "landing") {
            this.getu();
            this.getNoti();
        }
    };

    readNoti = (obj) => {
        const { history } = this.props;
        this.viewNoti(obj.id)
            .then((resp) => {
                switch (obj.notificationType) {
                    case "1":
                        history.push("apptDashboard");
                        break;
                    case "2":
                        history.push("rewards");
                        break;
                    default:
                        break;
                }
            })
            .catch((err) => {
                // console.log(err);
            });
    };

    viewNoti = (id) => {
        const response = ApiService.put_(ServiceApiUrl.READNOTI + id);
        return response;
    };

    getu = () => {
        this.viewProfile()
            .then((resp) => {
                this.setState({
                    profileName: resp.data.userView.email,
                });
                this.props.setProfile(resp.data.userView);
            })
            .catch((err) => {});
    };

    viewProfile = (payload) => {
        const response = ApiService.get(ServiceApiUrl.VIEWPROFILE, payload);
        return response;
    };

    getNoti = () => {
        this.fetchNoti()
            .then((resp) => {
                this.setState({
                    notifications: resp.data,
                });
            })
            .catch((err) => {});
    };

    fetchNoti = (payload) => {
        const response = ApiService.get(ServiceApi.GETNOTI, payload);
        return response;
    };

    dropdownBellToggle = () => {
        this.setState((prevState) => ({
            dropdownBell: !prevState.dropdownBell,
        }));
    };

    toggleDropdown = () => {
        this.setState((prevState) => ({
            dropdown: !prevState.dropdown,
        }));
    };

    render() {
        const { notifications, dropdownBell, profileName, dropdown } = this.state;
        const { pageType } = this.props;
        return (
            <Fragment>
                <nav className="topbar">
                    <div className="navLeftsideElements">
                        <Link to="/home">
                            <div className="hoya-logo" />
                        </Link>
                    </div>
                    {pageType === "landing" ? (
                        <div className="landing-signup-button">
                            <Link to="/login">
                                <Button className="landingLoginSignupButtonStyle">Log In/Sign Up</Button>
                            </Link>
                        </div>
                    ) : (
                        <div className="navRightSideElements">
                            <div className="RightSideElementstyleBell">
                                <div
                                    className="RightSideElementstyleBellWrapper"
                                    onClick={() => {
                                        this.dropdownBellToggle();
                                    }}>
                                    <img src={notificationBell} alt="" />
                                    {notifications.totalNotification !== 0 && <Badge color="danger">{notifications.totalNotification}</Badge>}
                                    {dropdownBell && (
                                        <div className="dropdownBellOptionContainer">
                                            {notifications &&
                                                notifications.notificationList.reverse().map((n) => (
                                                    <div onClick={() => this.readNoti(n)} key={n.id} className={`notification-list-wrapper ${n.seen ? "activated" : null}`}>
                                                        <p className="notification-msg">{n.notificationMessage}</p>
                                                        {(n.notificationType === "2" || n.notificationType === "1") && <p className="notification-btn">Check Now</p>}
                                                    </div>
                                                ))}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="RightSideElementstyleName bold">welcome, <br/>{profileName}</div>
                            <div className="dropdownContainer" onClick={this.toggleDropdown}>
                                <div className="dropdownChevronLogo">
                                    <Icon icon={chevronDown} size={22} />
                                </div>
                                {dropdown && (
                                    <div className="dropdownOptionAndArrowContainer">
                                        <div className="dropdownOptionContainer">
                                            <Link to="/profile">
                                                <div className="dropdownElements bold">
                                                    <Icon icon={ic_person_outline} size={20} />
                                                    Profile
                                                </div>
                                            </Link>
                                            <Link to="/rewards">
                                                <div className="dropdownElements bold">
                                                    <Icon icon={ic_star_border} size={20} />
                                                    Rewards
                                                </div>
                                            </Link>
                                            <Link to="/apptDashboard">
                                                <div className="dropdownElements bold">
                                                    <Icon icon={calendar} size={20} />
                                                    Booking
                                                </div>
                                            </Link>
                                            <Link to="/contactUs">
                                                <div className="dropdownElements bold">
                                                    <Icon icon={mail} size={20} />
                                                    Contact Us
                                                </div>
                                            </Link>
                                            <Link to="/login">
                                                <div className="dropdownElements bold" onClick={() => this.props.logout()}>
                                                    <Icon icon={iosUnlockedOutline} size={20} />
                                                    Log Out
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </nav>
            </Fragment>
        );
    }
}

const mapDispatchToProps = {
    logout: userActions.logout,
};

export default connect(null, mapDispatchToProps)(withRouter(Topbar));
