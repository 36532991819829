import React, { Component } from "react";
import { connect } from "react-redux";
import { alertActions } from "_actions";
import { ApiService, ServiceApiUrl } from "_helpers";
import { userConstants } from "../../_constants";
import Layout from "components/layout";
import Button from "components/button";
import fb from "assets/images/facebook-contact-us.svg";
import insta from "assets/images/instagram.svg";

class ContactUs extends Component {
    constructor(props) {
        super(props);
        this.state = { loading: false };
    }

    componentDidMount() {
        this.getProfile();
    }

    getProfile = async (payload) => {
        try {
            const response = await ApiService.get(ServiceApiUrl.VIEWPROFILE, payload);
            this.props.updateProfile(response.data);
        } catch (error) {
            console.log(error);
        }
    };

    onSubmit = () => {
        const { msg } = this.state;
        const payload = {
            Message: msg,
        };

        if (msg) {
            this.requestContactUs(payload)
                .then((resp) => {
                    this.props.alertSuccess(resp.data.message);
                    this.setState({ loading: false });
                    this.setState({
                        Messages: "",
                    });
                })
                .catch((err) => {
                    this.setState({ loading: false });

                    const errMsg = err.response.data ? err.response.data.message : err.message;
                    this.props.alertError(errMsg);
                });
        }
    };

    handleChange = (e) => {
        this.setState({
            msg: e.currentTarget.value,
        });
    };

    requestContactUs = async (payload) => {
        const response = await ApiService.post(ServiceApiUrl.CONTACT_US, payload);
        return response;
    };

    render() {
        return (
            <Layout>
                <div className="container-contact-us">
                    <div className="contact-us-wrapper">
                        <div className="git_container">
                            <p className="title">GET IN TOUCH</p>
                            <div>
                                <p className="description">Please fill out the form below and we will be in touch with lightning speed.</p>
                            </div>
                            <textarea className="msg_box" placeholder="Message" onChange={this.handleChange} value={this.state.Messages} cols="50" rows="4" disabled={this.state.loading} />
                            <div className="button-container">
                                <Button type="submit" title={this.state.loading ? "SUBMITTING" : "SUBMIT"} onClick={this.onSubmit} disabled={this.state.loading} />
                            </div>
                        </div>
                        <div className="cwu_container">
                            <p className="title">CONNECT WITH US:</p>
                            <div className="cwu_text">
                                <p className="description">
                                    <b>HOYA Lens Manufacturing Malaysia Sdn. Bhd.</b>
                                    <br /> No. 6, Jalan 7/32A, Off 6 1/2 Miles, Jalan Kepong, 52000, Kuala Lumpur, WP Kuala Lumpur.
                                </p>
                            </div>
                            <div className="cwu_text">
                                <p className="description">
                                    For support or any question: <br />
                                    Email us at
                                    <a href="mailto: support@hoya.com.my"> support@hoya.com.my</a>
                                    <br />
                                    Call us at 03-6256 0081
                                </p>
                            </div>
                            <div className="sm_container">
                                <a href="https://www.facebook.com/hoyamalaysia/" target="_blank" rel="noreferrer">
                                    <img src={fb} alt="Facebook" />
                                </a>

                                <a href="https://www.instagram.com/hoyamalaysia/" target="_blank" rel="noreferrer">
                                    <img src={insta} alt="Instagram" />
                                </a>
                            </div>

                            <div className="contact-us-location">
                                <p className="title">VISIT YOUR OPTICAL CENTRE:</p>
                                {this.props.users?.profile?.stores?.map((item, index) => {
                                    return (
                                        <div className="cwu_container" key={index}>
                                            <div className="cwu_text">
                                                <p className="description">
                                                    <b>{item.corporateName}</b>
                                                    <br />
                                                    {item.address}
                                                </p>
                                            </div>
                                            <ul>
                                                {item.businessHour.map((day, dayIndex) => {
                                                    const labels = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Staturday", "Sunday"];
                                                    const closedClassName = day.toLowerCase() === "closed" ? "closed" : "";

                                                    return (
                                                        <li className="bold" key={dayIndex}>
                                                            {labels[dayIndex]} <span className={`bold ${closedClassName}`}>{day}</span>
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                            <div className="cwu_text">
                                                <p className="description">
                                                    For support or any question: <br />
                                                    Email us at
                                                    <a href={`mailto: ${item.email}`}> {item.email}</a>
                                                    <br />
                                                    Call us at {item.mobileNumber}
                                                </p>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    alertError: (error) => {
        dispatch(alertActions.error(error));
    },
    alertSuccess: (msg) => {
        dispatch(alertActions.success(msg));
    },
    updateProfile: (data) => {
        dispatch({ type: userConstants.UPDATE_PROFILE, profile: data });
    },
});

const mapStateToProps = (state) => {
    return {
        users: state.users,
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(ContactUs);
