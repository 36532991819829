import React, { Component } from "react";
import ReactLoading from "react-loading";
import { Link } from "react-router-dom";
import { Card } from "reactstrap";

import Button from "components/button";
import Footer from "components/footer";
import { ApiService, ServiceApiUrl } from "../../_helpers";

export default class VerifiyEmail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            error: true,
        };
    }

    componentDidMount() {
        setTimeout(() => {
            this.verifyEmail();
        }, 5000);
    }

    verifyEmail = () => {
        const url = new URL(window.location);
        const searchParams = new URLSearchParams(url.search);
        const token = searchParams.get("token");
        this.requestVerifyEmail(token)
            .then((resp) => {
                this.setState({
                    message: resp.data.message,
                    loading: false,
                    error: false,
                });
            })
            .catch((err) => {
                this.setState({
                    error: true,
                    loading: false,
                    message: err.response.data.message,
                });
            });
    };

    requestVerifyEmail = async (token) => {
        const response = await ApiService.get(`${ServiceApiUrl.VERIFY_EMAIL}${token}`);
        return response;
    };

    proceedToLogin = () => {
        const { history } = this.props;
        history.push("login");
    };

    render() {
        const { message, loading } = this.state;
        return (
            <div className="verify-email-container">
                <Link to={"/landing"}>
                    <div className="hoya-logo" />
                </Link>

                <div className="card-wrapper">
                    <Card className="verify-email-card">
                        <div className="loading-wrapper">{loading && <ReactLoading type={"spinningBubbles"} color={"#2f368f"} height={50} width={50} />}</div>
                        <div className="title">{message || "Verifying Email"}</div>
                        {message && (
                            <div className="button-container">
                                <Button title="PROCEED TO LOGIN" onClick={this.proceedToLogin} />
                            </div>
                        )}
                    </Card>
                </div>
                <Footer />
            </div>
        );
    }
}
