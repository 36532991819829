import React, { Component } from "react";
import { Carousel, CarouselItem, CarouselControl, CarouselIndicators } from "reactstrap";
import PopUp from "./pop-up";

import fb from "assets/images/facebook-contact-us.svg";
import twitter from "assets/images/twitter.svg";
import { ApiService, ServiceApiUrl } from "../_helpers/index";

class CarouselComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeIndex: 0,
            modal: false,
            fade: true,
            img: "",
            dataImages: "",
            msg: "",
            copied: false,
            clickedImageId: "",
            clickedItem: {},
        };
    }

    componentDidMount = () => {
        this.setState({
            dataImages: this.props.dataImage,
        });
    };

    toggle = (item) => {
        this.setState({
            clickedImageId: item.id,
            clickedItem: item,
            modal: !this.state.modal,
            fade: !this.state.fade,
            msg: "",
        });
    };

    shareTog = (imagePath) => {
        this.setState(() => {
            this.props.shareBanner(imagePath);
        });
    };

    shareBannerFb = (item) => {
        const payload = {
            BannerId: this.state.clickedImageId,
            Platform: "Facebook",
            Points: item.points,
        };
        this.shareThisBanner(payload)
            .then((resp) => {
                console.log(resp.data.message, "result");
                this.setState({
                    msg: resp.data.message,
                });
            })
            .catch((err) => {
                console.log(err);
            });
    };

    shareBannerPin = (item) => {
        const payload = {
            BannerId: item.id,
            Platform: "Pinterest",
            Points: item.points,
        };
        this.shareThisBanner(payload)
            .then((resp) => {
                console.log(resp.data.message, "result");
                this.setState({
                    msg: resp.data.message,
                });
            })
            .catch((err) => {
                console.log(err);
            });
    };

    shareBannerTw = (item) => {
        const payload = {
            BannerId: item.id,
            Platform: "Twitter",
            Points: item.points,
        };
        this.shareThisBanner(payload)
            .then((resp) => {
                console.log(resp.data.message, "result");
                this.setState({
                    msg: resp.data.message,
                });
            })
            .catch((err) => {
                console.log(err);
            });
    };

    resetMsg = () => {
        this.setState({
            msg: "",
        });
    };

    shareFunctionFB = (item) => {
        const url = item.image;
        const FBurl = "https://www.facebook.com/sharer.php?u=" + url;
        window.open(FBurl);
    };

    shareFunctionTW = (item) => {
        const url = item.image;
        const TWurl = "https://twitter.com/intent/tweet?url=" + url;
        window.open(TWurl);
    };

    shareFunctionPin = (item) => {
        const url = item.image;
        const PINurl = "http://pinterest.com/pin/create/link/?url=" + url;
        window.open(PINurl);
    };

    shareThisBanner = async (payload) => {
        console.log("PARENT URL ", payload);
        const response = await ApiService.post(ServiceApiUrl.SHAREBANNER, payload);
        console.log(response);
        return response;
    };

    toggleDropdown = () => {
        this.setState({
            dropdown: !this.state.dropdown,
        });
    };

    addDefaultSrc = (e) => {
        e.target.src = require("assets/images/imageError.svg");
    };

    onExiting = () => {
        this.animating = true;
    };

    onExited = () => {
        this.animating = false;
    };

    next = () => {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === this.props.dataImage.length - 1 ? 0 : this.state.activeIndex + 1;
        this.setState({ activeIndex: nextIndex });
    };

    previous = () => {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === 0 ? this.props.dataImage.length - 1 : this.state.activeIndex - 1;
        this.setState({ activeIndex: nextIndex });
    };

    goToIndex = (newIndex) => {
        if (this.animating) return;
        this.setState({ activeIndex: newIndex });
    };

    onCopy = () => {
        this.setState({ copied: true });
        this.setState({
            msg: "Link Copied To Clipboard",
        });
    };

    render() {
        const { activeIndex, modal, clickedItem } = this.state;
        const { dataImage } = this.props;
        const slides = dataImage.map((item) => {
            return (
                <CarouselItem onExiting={this.onExiting} onExited={this.onExited} key={item.id}>
                    <img src={item.image} alt={item.altText} onError={this.addDefaultSrc} onClick={() => this.toggle(item)} />
                    <PopUp visible={modal} toggle={this.toggle} className="carousel-pop-up">
                        <div className="modal-bg-img" />
                        <div className="modal-share-container">
                            <div className="modal-miyobee-img" />
                            <div className="share-popup-container">
                                <div className="share-popup-flexleft" />
                                <div className="share-popup-flexright">
                                    <p className="title">SHARE & GET</p>
                                    <p className="modal-share-points bold">
                                        {clickedItem.points}
                                        <small className="bold">pts</small>
                                    </p>
                                    <div className="modal_ShareOn">
                                        <text className="title_text">SHARE ON</text>
                                        <div style={{ display: "inline-flex" }}>
                                            <img
                                                className="s_media"
                                                src={fb}
                                                alt="Facebook"
                                                onClick={() => {
                                                    this.shareBannerFb(item, item.id);
                                                    this.shareFunctionFB(item);
                                                }}
                                            />
                                            <img
                                                className="s_media"
                                                src={twitter}
                                                alt="Twitter"
                                                onClick={() => {
                                                    this.shareBannerTw(item);
                                                    this.shareFunctionTW(item);
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </PopUp>
                </CarouselItem>
            );
        });

        return (
            <Carousel activeIndex={activeIndex} next={this.next} previous={this.previous} onClickHandler={this.goToIndex}>
                <CarouselIndicators items={this.props.dataImage} activeIndex={activeIndex} onClickHandler={this.goToIndex} />
                {slides}
                <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
                <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
            </Carousel>
        );
    }
}

export default CarouselComponent;
