import React, { Component } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";

import Button from "components/button";
import Footer from "components/footer";
import eye from "assets/images/eye-icon.svg";
import miyosmartLogo from "assets/images/register-account-bee.svg";
import { ApiService, ServiceApiUrl } from "../../_helpers/index";
import queryString from "query-string";

import miyobee from "assets/images/miyo-bee.svg";

export default class ForgetPasswordPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isPasswordShown: false,
            icon: eye,
        };
    }

    togglePassword = () => {
        const { isPasswordShown } = this.state;
        this.setState({
            isPasswordShown: !isPasswordShown,
        });
    };

    componentDidMount = () => {
        const qs = queryString.parse(this.props.location.search);

        if (Object.keys(qs).length) {
            this.setState({
                query: qs.session,
            });
            const payload = qs.session;
            this.getCreds(payload)
                .then((resp) => {
                    console.log(resp);
                    this.setState({
                        valid: true,
                        returnData: resp.data,
                    });
                })
                .catch((err) => {
                    this.setState({
                        warning: true,
                        valid: false,
                    });
                });
        }
    };

    getCreds = (payload) => {
        const response = ApiService.get(ServiceApiUrl.CHECKSESSION + payload);
        return response;
    };

    resetPass = (values) => {
        const { history } = this.props;
        const { returnData } = this.state;
        const payload = {
            Email: returnData.email,
            Session: returnData.session,
            Password: values.password,
        };
        this.sendPass(payload)
            .then((resp) => {
                console.log(resp, "working here");
                history.push("/forgetpasswordresetted");
            })
            .catch((err) => {});
    };

    sendPass = (payload) => {
        const response = ApiService.put_(ServiceApiUrl.RESETPASS, payload);
        return response;
    };

    render() {
        return (
            <div className="forget-password">
                <Link to="/login">
                    <div className="hoya-logo" />
                </Link>
                <div className="signUpCard">
                    <div className="forget-password__wrapper">
                        <div className="signUpCardHeader">
                            <div className="hoya-mart">
                                <img src={miyosmartLogo} className="hoya-mart-logo" alt="" />
                            </div>
                        </div>
                        <div className="title">RESET PASSWORD</div>
                        <p className="description">Enter a new password below for your account.</p>
                        <div className="cardInputContainer">
                            <Formik
                                initialValues={{
                                    password: "",
                                    confirmPassword: "",
                                }}
                                onSubmit={(values) => {
                                    this.resetPass(values);
                                }}
                                validationSchema={Yup.object().shape({
                                    password: Yup.string().required("Required").min(6, "min 6 character password").max(15, "try a shorter password."),
                                    confirmPassword: Yup.string()
                                        .required("Required")
                                        .test("passwords-match", "Passwords must match", function (value) {
                                            return this.parent.password === value;
                                        }),
                                })}>
                                {(props) => {
                                    const { values, touched, errors, isSubmitting, handleChange, handleBlur, handleSubmit } = props;
                                    return (
                                        <form onSubmit={handleSubmit} className="reset-pass-form" autoComplete="off">
                                            <div className="inputContainer">
                                                <div className="icon_Container">
                                                    <img src={eye} alt="" onClick={this.togglePassword} />
                                                </div>
                                                <input
                                                    id="password"
                                                    type={this.state.isPasswordShown ? "text" : "password"}
                                                    placeholder="New Password"
                                                    value={values.password}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={errors.password && touched.password ? "text-input error" : "text-input"}
                                                />
                                                {errors.password && touched.password && <div className="input-feedback">{errors.password}</div>}
                                            </div>
                                            <div className="inputContainer">
                                                <input
                                                    id="confirmPassword"
                                                    type="password"
                                                    placeholder="Confirm Password"
                                                    value={values.confirmPassword}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={errors.confirmPassword && touched.confirmPassword ? "text-input error" : "text-input"}
                                                />
                                                {errors.confirmPassword && touched.confirmPassword && <div className="input-feedback">{errors.confirmPassword}</div>}
                                            </div>
                                            <Button title="RESET PASSWORD" type="submit" disabled={isSubmitting} />
                                        </form>
                                    );
                                }}
                            </Formik>
                        </div>
                        <div className="registor-container">
                            <img className="miyobeeLogo" src={miyobee} alt="" />
                        </div>
                    </div>
                </div>

                <Footer />
            </div>
        );
    }
}
