import React, { Fragment, Component } from "react";
import Layout from "components/layout";
import { ApiService, ServiceApiUrl } from "../../_helpers/index";
import Pagination from "react-js-pagination";
import { chevronDown } from "react-icons-kit/ionicons/chevronDown";
import Icon from "react-icons-kit";
import * as moment from "moment";
import { chevronLeft } from "react-icons-kit/ionicons";
import { history } from "../../_helpers/history";

export default class EyeTestFullHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            eyeTestHistory: [],
            appointmentData: [],
            voteCount: 0,
            page: 4,
            respEyeTestFullHistoryInfoData1: [],
            respEyeTestFullHistoryResultLeftData1: [],
            respEyeTestFullHistoryInfoData2: [],
            respEyeTestFullHistoryResultRightData1: [],
            respEyeTestFullHistoryResultLeftData2: [],
            respEyeTestFullHistoryResultRightData2: [],

            appointmentIndex: 1,

            dropdown: false,
            respNameListData: [],
            respLinkedProductData: [],
            currentPageIndex: 1,
            respTotalIndex: 1,
            profileId: 1,
            currentIndex: 1,
            dropDownActiveName: "",
            dropDownActiveProfileid: "",
            activePagination: 1,
        };
    }

    componentDidMount() {
        this.getNameList();
        // this.renderFirstLinkedProductData();
    }

    setCurrentSetsOfIndex = (selector) => {
        if (selector === "next") {
            this.setState((prevState) => ({
                currentPageIndex: prevState.currentPageIndex + 1,
            }));
        }
        if (selector === "prev") {
            this.setState((prevState) => ({
                currentPageIndex: prevState.currentPageIndex - 1,
            }));
        }
    };
    backToProfile = () => {
        history.push("/profile");
    };

    dropDownSelected = (profileId, profileName) => {
        this.setState(
            {
                dropDownActiveProfileid: profileId,
                dropDownActiveName: profileName,
                currentIndex: 1,
            },
            () => {
                const { dropDownActiveProfileid } = this.state;
                if (dropDownActiveProfileid) {
                    this.getEyeTestFullHistoryData(profileId, 1);
                } else {
                    this.getAllEyeTest();
                }
            }
        );
    };

    renderFirstLinkedProductData = () => {
        const { dropDownActiveProfileid } = this.state;
        if (dropDownActiveProfileid) {
            this.getEyeTestFullHistoryData(dropDownActiveProfileid, 1);
        } else {
            this.getAllEyeTest();
        }
    };

    getEyeTestFullHistoryData = async (profileId, currentIndex) => {
        const response = await ApiService.get(process.env.REACT_APP_MIYOSMART_URL + "Appointment/ResultHistory/" + profileId + "/" + currentIndex);
        console.log(response);
        this.setState({
            eyeTestHistory: response.data.appointmentHistory,
            activePagination: currentIndex,
            totalIndex: response.data.totalIndex,
        });
    };

    getNameList = async (payload) => {
        const response = await ApiService.get(ServiceApiUrl.SHOWLINKPROFILE, payload);

        const all = {
            profileId: null,
            profileName: "ALL",
        };
        const dropdownList = [all, ...response.data];

        console.log(response.data);

        this.setState(
            {
                respNameListData: dropdownList,
                dropDownActiveName: dropdownList[0].profileName,
                dropDownActiveProfileid: dropdownList[0].profileId,
            },
            () => {
                this.renderFirstLinkedProductData();
            }
        );
    };

    getAllEyeTest = async (i = 1) => {
        try {
            const response = await ApiService.get(process.env.REACT_APP_MIYOSMART_URL + `Appointment/AllResultHistory/${i}`);

            this.setState({
                eyeTestHistory: response.data.appointmentHistory,
                activePagination: i,
                totalIndex: response.data.totalIndex,
            });
        } catch (err) {
            console.log(err);
        }
    };

    togglePatientNameDropdown = (close) => {
        close === "close"
            ? this.setState({
                  dropdown: false,
              })
            : this.setState({
                  dropdown: !this.state.dropdown,
              });
    };

    handlePageChange = (i) => {
        const { dropDownActiveProfileid } = this.state;
        this.setState({
            activePagination: i,
        });
        if (dropDownActiveProfileid) {
            this.getEyeTestFullHistoryData(dropDownActiveProfileid, 1);
        } else {
            this.getAllEyeTest(i);
        }
    };

    render() {
        return (
            <Layout>
                <div className="eyetestfullhistorypage_container">
                    <div className="eye-test-history__header">
                        <p className="title">EYE TEST FULL HISTORY</p>
                        <div className="eye-test-history__back-btn" onClick={() => this.backToProfile()}>
                            <Icon icon={chevronLeft} size={10} />
                            <p className="bold">Back</p>
                        </div>
                    </div>

                    <div className="eyetestfullhistorypage_dropDownContainer">
                        <div className="eyetestfullhistorypage_patientName">Patient Name:</div>
                        <div onClick={this.togglePatientNameDropdown}>
                            <div className="eyetestfullhistorypage_dropDownActiveName semibold">
                                {this.state.dropDownActiveName}
                                {this.state.dropDownActiveName && <Icon icon={chevronDown} size={12} />}
                            </div>
                            {this.state.dropdown && (
                                <div className="eyetestfullhistorypage_dropDownBox">
                                    {this.state.respNameListData.map((p, index) => {
                                        if (p.profileName)
                                            return (
                                                <div className="eyetestfullhistorypage_dropDownElements semibold" key={`${p.profileName}_${index}`} onClick={() => this.dropDownSelected(p.profileId, p.profileName)}>
                                                    {p.profileName}
                                                </div>
                                            );
                                        return null;
                                    })}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="eye-test-history">
                        {this.state.eyeTestHistory.length
                            ? this.state.eyeTestHistory.map((data, index) => {
                                  return (
                                      <Fragment key={index}>
                                          <p className="title eye-test-history__title">APPOINTMENT {index + 1}</p>
                                          <div className="eye-test-history__item">
                                              <div className="eye-test-history__content-wrapper">
                                                  <div className="eye-test-history__content">
                                                      <p className="eye-test-history__label">NAME OF PATIENT</p>
                                                      <p className="eye-test-history__value semibold">{data.profileName}</p>
                                                  </div>
                                                  <div className="eye-test-history__content">
                                                      <p className="eye-test-history__label">DATE OF APPOINTMENT</p>
                                                      <p className="eye-test-history__value semibold">{moment(data.appointmentDate).format("DD/MM/YYYY")}</p>
                                                  </div>
                                                  <div className="eye-test-history__content">
                                                      <p className="eye-test-history__label">BRANCH</p>
                                                      <p className="eye-test-history__value semibold">{data.storeDescription}</p>
                                                  </div>
                                              </div>
                                              <div className="eye-test-history__results-wrapper">
                                                  <div className="eye-test-history__results-content">
                                                      <p className="eye-test-history__label">RESULTS</p>
                                                  </div>
                                                  <div className="eye-test-history__results-content">
                                                      <p className="eye-test-history__value"></p>
                                                      <p className="eye-test-history__value semibold">RIGHT</p>
                                                      <p className="eye-test-history__value semibold">LEFT</p>
                                                  </div>
                                                  <div className="eye-test-history__results-content">
                                                      <p className="eye-test-history__label">SPH</p>
                                                      <p className="eye-test-history__value semibold">{data.rightResult.sph}</p>
                                                      <p className="eye-test-history__value semibold">{data.leftResult.sph}</p>
                                                  </div>
                                                  <div className="eye-test-history__results-content">
                                                      <p className="eye-test-history__label">CYL</p>
                                                      <p className="eye-test-history__value semibold">{data.rightResult.cyl}</p>
                                                      <p className="eye-test-history__value semibold">{data.leftResult.cyl}</p>
                                                  </div>
                                                  <div className="eye-test-history__results-content">
                                                      <p className="eye-test-history__label">AXIS</p>
                                                      <p className="eye-test-history__value semibold">{data.rightResult.axis}</p>
                                                      <p className="eye-test-history__value semibold">{data.leftResult.axis}</p>
                                                  </div>
                                              </div>
                                          </div>
                                      </Fragment>
                                  );
                              })
                            : null}
                        <div className="pagination-container">
                            <Pagination
                                activePage={this.state.activePagination}
                                itemsCountPerPage={2}
                                pageRangeDisplayed={7}
                                totalItemsCount={this.state.totalIndex}
                                firstPageText={"First Page"}
                                lastPageText={"Last Page"}
                                itemClassFirst="pagination-first-child"
                                itemClassLast="pagination-last-child"
                                onChange={this.handlePageChange}
                            />
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}
