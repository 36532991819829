import { shippingDetailsConstants } from "../_constants/shippingDetails.constants";

const initialState = {
  shippingDetailsData: []
};

export const shippingDetails = (state = initialState, action) => {
  switch (action.type) {
    case shippingDetailsConstants.INIT_REDEMPTION:
      return initialState;
    case shippingDetailsConstants.ADD_SHIPPING_ADDRESS:
      return {
        ...state,
        shippingDetailsData: action.data
      };
    default:
      return state;
  }
};
