export const productData = {
  redemption: [
    {
      title: "Hoya's Travel Bag",
      point: "500 points",
      img:
        "https://thumbs-prod.si-cdn.com/0asJ-57mD0031U03UBTl8f8Czs0=/420x240/https://public-media.si-cdn.com/filer/bf/4e/bf4ec3eb-669f-4a1d-87ad-dd83f81c384d/4694899147_fcd4b69350_o.jpg"
    },
    {
      title: "Petronas Petrol Card RM500",
      point: "300 points",
      img:
        "https://thumbs-prod.si-cdn.com/0asJ-57mD0031U03UBTl8f8Czs0=/420x240/https://public-media.si-cdn.com/filer/bf/4e/bf4ec3eb-669f-4a1d-87ad-dd83f81c384d/4694899147_fcd4b69350_o.jpg"
    },
    {
      title: "Aeon Cash Voucher RM20",
      point: "500 points",
      img:
        "https://thumbs-prod.si-cdn.com/0asJ-57mD0031U03UBTl8f8Czs0=/420x240/https://public-media.si-cdn.com/filer/bf/4e/bf4ec3eb-669f-4a1d-87ad-dd83f81c384d/4694899147_fcd4b69350_o.jpg"
    },
    {
      title: "Aeon Cash Voucher RM20",
      point: "500 points",
      img:
        "https://thumbs-prod.si-cdn.com/0asJ-57mD0031U03UBTl8f8Czs0=/420x240/https://public-media.si-cdn.com/filer/bf/4e/bf4ec3eb-669f-4a1d-87ad-dd83f81c384d/4694899147_fcd4b69350_o.jpg"
    },
    {
      title: "Aeon Cash Voucher RM20",
      point: "500 points",
      img:
        "https://thumbs-prod.si-cdn.com/0asJ-57mD0031U03UBTl8f8Czs0=/420x240/https://public-media.si-cdn.com/filer/bf/4e/bf4ec3eb-669f-4a1d-87ad-dd83f81c384d/4694899147_fcd4b69350_o.jpg"
    },
    {
      title: "Aeon Cash Voucher RM20",
      point: "500 points",
      img:
        "https://thumbs-prod.si-cdn.com/0asJ-57mD0031U03UBTl8f8Czs0=/420x240/https://public-media.si-cdn.com/filer/bf/4e/bf4ec3eb-669f-4a1d-87ad-dd83f81c384d/4694899147_fcd4b69350_o.jpg"
    }
  ]
};

export const tableHeader = {
  miyoPointTransaction: [
    {
      label: "DATE",
      key: "dateUTC",
      format: "date"
    },
    {
      label: "TRANSACTIONS",
      key: "total",
      format: "points"
    }
  ],
  confirmRedemption: [
    {
      label: "ITEM",
      key: "name"
    },
    {
      label: "QTY",
      key: "addedQTY"
    },
    {
      label: "MIYO POINT",
      key: "points",
      format: "points"
    }
  ]
};

export const miyoPointTransactionData = [
  {
    date: "10/07/2019",
    transactions: "+1200 points"
  },
  {
    date: "10/07/2019",
    transactions: "+1200 points"
  },
  {
    date: "10/07/2019",
    transactions: "+1200 points"
  },
  {
    date: "10/07/2019",
    transactions: "+1200 points"
  },
  {
    date: "10/07/2019",
    transactions: "+1200 points"
  },
  {
    date: "10/07/2019",
    transactions: "+1200 points"
  },
  {
    date: "10/07/2019",
    transactions: "+1200 points"
  },
  {
    date: "10/07/2019",
    transactions: "+1200 points"
  },
  {
    date: "10/07/2019",
    transactions: "+1200 points"
  },
  {
    date: "10/07/2019",
    transactions: "+1200 points"
  },
  {
    date: "10/07/2019",
    transactions: "+1200 points"
  },
  {
    date: "10/07/2019",
    transactions: "+1200 points"
  },
  {
    date: "10/07/2019",
    transactions: "+1200 points"
  },
  {
    date: "10/07/2019",
    transactions: "+1200 points"
  },
  {
    date: "10/07/2019",
    transactions: "+1200 points"
  },
  {
    date: "10/07/2019",
    transactions: "+1200 points"
  }
];

export const confirmRedemptionResults = [
  {
    item: "Red Bull",
    quantity: "1",
    points: "100"
  },
  {
    item: "Red Bull",
    quantity: "1",
    points: "100"
  },
  {
    item: "Red Bull",
    quantity: "1",
    points: "100"
  },
  {
    item: "Red Bull",
    quantity: "1",
    points: "100"
  },
  {
    item: "Red Bull",
    quantity: "1",
    points: "100"
  }
];
