import React, { Component, Fragment } from "react";
import { Modal } from "reactstrap";
import { connect } from "react-redux";
import * as Yup from "yup";
import * as moment from "moment";
import { Formik } from "formik";
import jwt from "jsonwebtoken";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title as ChartTitle, Tooltip, Legend } from "chart.js";
import { Line } from "react-chartjs-2";

import { Title } from "components/Title/Title";
import { Card } from "components/card";
import Layout from "components/layout";
import Button from "components/button";
import { ApiService, ServiceApiUrl } from "../../_helpers/index";
import { alertActions } from "../../_actions";
import { userConstants } from "../../_constants";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, ChartTitle, Tooltip, Legend);

export const options = {
    responsive: true,
    plugins: {
        legend: {
            display: false,
        },
    },
};

const labels = ["0ᵗʰ","1ˢᵗ", "2ⁿᵈ", "3ʳᵈ", "4ᵗʰ", "5ᵗʰ"];
const colors = ["#005ca6", "#f1d4a5", "#b2dfdb", "#e91e63", "#9c27b0", "#673ab7", "#4caf50", "#4caf50", "#ff5722"];

class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            greetingDisplay: false,
            profileData: [],
            testData: [],
            popUp: false,
            editProfilePopUp: 0,
            modale: true,
            linkedProductData: [],
            showTooltipState: false,
            restrictionCounter: 0,
            activefieldTochange: [],
            edit: false,
            profileComplete: false,
            genderDropDown: false,
            chartData: {
                labels,
                datasets: null,
            },
        };
    }

    async componentDidMount() {
        this.profileCompleteChecker();
        this.getLinkedProducts();
        this.getProfile();
        this.getEYeTest();
    }

    closePopUp() {
        this.setState({ editProfilePopUp: 0 });
    }

    editForm = (field, type) => {
        this.setState(
            {
                activefieldTochange: field,
            },
            () => {
                this.setState({
                    editProfilePopUp: 1,
                });
            }
        );
    };
    addingRestrictionCounter = () => {
        this.setState((prevState) => ({
            restrictionCounter: prevState.restrictionCounter + 1,
        }));
    };

    changeToEdit = () => {
        this.setState((prevstate) => ({ edit: !prevstate.edit }));
    };

    getProfile = async (payload) => {
        try {
            const chartDatasets = [];
            const response = await ApiService.get(ServiceApiUrl.VIEWPROFILE, payload);
            const averageProgression = response.data.averageProgression;
            const childrenProgression = response.data.childrenProgression;
            const chartData = {
                ...this.state.chartData,
            };
            chartDatasets.push({
                label: "Average all Miyoclub users",
                data: averageProgression,
                borderColor: "#005ca6",
                backgroundColor: "#005ca6",
            });
            childrenProgression.forEach((o, i) => {
                chartDatasets.push({
                    label: o.profileName,
                    data: o.results,
                    borderColor: colors[i + 1],
                    backgroundColor: colors[i + 1],
                });
            });

            chartData.datasets = chartDatasets;
            this.props.updateProfile(response.data);
            this.setState(
                {
                    profileData: response.data.userView,
                    gender: response.data.userView.gender,
                    dateOfBirth: response.data.userView.dateOfBirth,
                    fullName: response.data.userView.name,
                    country: response.data.userView.country,
                    contactNumber: response.data.userView.mobileNumber,
                    shippingAddress: response.data.userView.shippingAddress,
                    chartData,
                },
                () => {
                    this.closePopUp();
                }
            );
        } catch (error) {
            console.log(error);
        }
    };

    changeProfileDetails = (values) => {
        const { profileData } = this.state;
        const payload = {
            Name: values.fullName || profileData.name,
            Gender: values.gender || profileData.gender,
            DateOfBirth: values.dateOfBirth || moment(profileData.dateOfBirth).format("DD/MM/YYYY"),
            MobileNumber: values.contactNumber || profileData.mobileNumber,
            Country: values.country || profileData.country,
            ShippingAddress: values.shippingAddress || profileData.shippingAddress,
        };

        if (values.password) payload.Password = values.password;

        this.requestChangeProfile(payload)
            .then((resp) => {
                this.getProfile();
                if (resp.data.token) {
                    this.props.updateToken(resp.data);
                    this.props.history.push("profile");
                }
                this.props.alertSuccess(resp.data.message);
            })
            .catch((err) => {
                this.props.alertError("Failed to updated!");
                this.getProfile();
            });
    };

    requestChangeProfile = async (payload) => {
        const response = await ApiService.put_(ServiceApiUrl.CHANGEDETAILS, payload);
        return response;
    };

    getLinkedProducts = async (payload) => {
        const response = await ApiService.get(ServiceApiUrl.VIEWPROFILE, payload);
        this.setState(
            {
                respLinkedProductsData: response.data.linkedProducts,
            },
            () => {
                this.displaylinkedProduct();
            }
        );
    };

    getEYeTest = async (payload) => {
        const response = await ApiService.get(ServiceApiUrl.VIEWPROFILE, payload);
        this.setState(
            {
                respEyeTestData: response.data.appointments,
            },
            () => {
                this.displayEyeTest();
            }
        );
    };

    displayEyeTest = () => {
        const EyeTestData = [];
        const { respEyeTestData } = this.state;
        const response = respEyeTestData;
        response.forEach((i) => {
            EyeTestData.push({
                profileid: i.profileId,
                elementTitle: "APPOINTMENT " + moment(i.appointmentTime).format("DD/MM/YYYY"),
                elementTitleDesc1: i.storeName,
                elementTitleDesc2: i.patientName,
                appointmentid: i.appointmentId,
            });
        });

        this.setState({ EyeTestData });
    };

    displaylinkedProduct = () => {
        const linkedProductData = [];
        const { respLinkedProductsData } = this.state;
        const response = respLinkedProductsData;
        response.forEach((i) => {
            linkedProductData.push({
                profileid: i.profileId,
                elementTitle: "PRODUCT " + i.linkedProductId,
                elementTitleDesc1: i.orderNumber,
                elementTitleDesc2: i.owner,
            });
        });

        this.setState({ linkedProductData }, () => {
            this.state.linkedProductData.length === 0 && this.showTooltip();
        });
    };

    showTooltip = () => {
        this.setState({
            showTooltipState: true,
        });
    };

    showGreeting = () => {
        this.setState({
            greetingDisplay: true,
        });
        setTimeout(() => this.showContent, 3000);
    };

    toggleGenderDropDown = () => {
        this.setState((prevState) => ({
            genderDropDown: !prevState.genderDropDown,
        }));
    };

    initialValuesEditForm = () => {
        const { activefieldTochange } = this.state;
        const payload = {};
        activefieldTochange.forEach((data, i) => {
            if (data.name === "dateOfBirth") {
                payload[data.name] = this.state[data.name] ? moment(this.state[data.name]).format("DD/MM/YYYY") : null;
            } else {
                payload[data.name] = this.state[data.name];
            }
        });
        return payload;
    };

    changeTest = () => {
        this.setState({ profileComplete: false });
    };

    profileCompleteChecker = () => {
        const validToken = jwt.decode(this.props.authentication.user.token);
        validToken.profileComplete === "True" ? this.setState({ profileComplete: true }) : this.setState({ profileComplete: false });
    };

    convertDateOfBirth = (value) => {
        const v = value.replace(/\//g, "");
        const v_1 = v.substring(0, 2);
        const v_2 = v.substring(2, 4);
        const v_3 = v.substring(4, 8);

        console.log(v_1, v_2, v_3);
        if (v.length <= 2) {
            return v;
        } else if (v.length > 2 && v.length < 5) {
            return `${v_1}/${v_2}`;
        } else if (v.length >= 5) {
            return `${v_1}/${v_2}/${v_3}`;
        }

        return v;
    };

    render() {
        const { profileData, linkedProductData, EyeTestData, activefieldTochange, editProfilePopUp } = this.state;
        const updateError = "Please Update";
        const accountCardData = [
            {
                elementTitle: "EMAIL",
                icon2: "false",
                elementTitleDesc1: profileData.email,
            },
            {
                inputField: [
                    { name: "password", label: "PASSWORD" },
                    { name: "confirmPassword", label: "CONFIRM PASSWORD" },
                ],
                elementTitle: "PASSWORD",
                elementTitleDesc1: (
                    <div
                        style={{
                            color: this.state.profileComplete ? "red" : "black",
                        }}>
                        Reset password
                    </div>
                ),
            },
        ];

        const profileCardData = [
            {
                inputField: [
                    { name: "fullName", label: "FULL NAME", placeholder: "FULL NAME" },
                    {
                        name: "dateOfBirth",
                        label: "DATE OF BIRTH",
                        placeholder: "DD/MM/YYYY",
                    },
                    { name: "gender", label: "GENDER", placeholder: "GENDER" },
                ],
                elementTitle: "FULL NAME",
                elementTitleDesc1: profileData.name === null ? updateError : profileData.name,
                elementTitle2: "DATE OF BIRTH",
                elementTitle2Desc: moment(this.state.dateOfBirth).year() > 2 ? moment(this.state.dateOfBirth).format("DD/MM/YYYY") : updateError,
                elementTitle3: "GENDER",
                elementTitle3Desc: this.state.gender === null ? this.updateError : profileData.gender,
            },
            {
                inputField: [{ name: "contactNumber", label: "MOBILE NUMBER", placeholder: "MOBILE NUMBER" }],
                elementTitle: "MOBILE NUMBER",
                elementTitleDesc1: profileData === null ? updateError : profileData.mobileNumber,
            },
            {
                inputField: [{ name: "shippingAddress", label: "SHIPPING ADDRESS", placeholder: "SHIPPING ADDRESS" }],
                elementTitle: "SHIPPING ADDRESS",
                elementTitleDesc1: profileData === null ? updateError : profileData.shippingAddress,
            },
        ];

        return (
            <Layout>
                <div className="profile">
                    {editProfilePopUp !== 0 && (
                        <Modal isOpen={this.state.modale} className="profilepage_modalContainer" contentClassName={"profilepage_modalContent"} style={{ width: "100%" }}>
                            <p className="title">UPDATES PROFILE DETAILS</p>
                            <Formik
                                initialValues={this.initialValuesEditForm()}
                                onSubmit={(values) => {
                                    this.changeProfileDetails(values);
                                }}
                                validationSchema={
                                    activefieldTochange[0].name === "password"
                                        ? Yup.object().shape({
                                              confirmPassword: Yup.string()
                                                  .required("Required")
                                                  .test("passwords-match", "Passwords must match", function (value) {
                                                      return this.parent.password === value;
                                                  }),
                                          })
                                        : null
                                }>
                                {(props) => {
                                    const { values, touched, errors, isSubmitting, setFieldValue, handleChange, handleBlur, handleSubmit } = props;
                                    return (
                                        <form onSubmit={handleSubmit} className="profilepage_formStyle">
                                            {activefieldTochange.map((i) => {
                                                const type = i.name === "password" ? "password" : i.name === "confirmPassword" ? "password" : "text";
                                                const hasError = errors[i.name] && touched[i.name];
                                                return (
                                                    <Fragment key={`${i.name}_input_field`}>
                                                        <input
                                                            placeholder={i.placeholder}
                                                            id={i.name}
                                                            type={type}
                                                            value={values[i.name] || ""}
                                                            onChange={(e) => {
                                                                if (i.name === "dateOfBirth") {
                                                                    setFieldValue("dateOfBirth", this.convertDateOfBirth(e.target.value));
                                                                } else {
                                                                    handleChange(e);
                                                                }
                                                            }}
                                                            onBlur={handleBlur}
                                                            onFocus={() => {
                                                                if (i.name === "gender") this.toggleGenderDropDown();
                                                            }}
                                                            className={hasError ? "text-input error" : "text-input"}
                                                        />
                                                        {this.state.genderDropDown && i.name === "gender" && (
                                                            <div className="gender-dropdown-box">
                                                                <div
                                                                    onClick={() => {
                                                                        values.gender = "Male";
                                                                        this.toggleGenderDropDown();
                                                                    }}>
                                                                    Male
                                                                </div>
                                                                <div
                                                                    onClick={() => {
                                                                        values.gender = "Female";
                                                                        this.toggleGenderDropDown();
                                                                    }}>
                                                                    Female
                                                                </div>
                                                            </div>
                                                        )}
                                                        {errors[i.name] && touched[i.name] && <div className="input-feedback">{errors[i.name]}</div>}
                                                    </Fragment>
                                                );
                                            })}
                                            <div className="profilepage_ButtonContainer">
                                                <Button title="Cancel" onClick={() => this.closePopUp()} outline />
                                                <Button title="Confirm" disabled={isSubmitting} type="submit" />
                                            </div>
                                        </form>
                                    );
                                }}
                            </Formik>
                        </Modal>
                    )}
                    <div className="profilepage_row1">
                        <div className="profilepage_accountCard">
                            <Card title={"ACCOUNT"} edit={this.state.edit} cardContent={accountCardData} editForm={this.editForm} onClick={() => this.changeToEdit()} />
                        </div>
                        <div className="profilepage_profileCard">
                            <Card title={"PARENTS'S PROFILE"} cardContent={profileCardData} edit={this.state.edit} onClick={() => this.changeToEdit()} editForm={this.editForm} updateProfile={!this.state.profileComplete} />
                            <Card title={"CHILDREN'S PROFILE"} cardContent={!this.state.showTooltipState && linkedProductData} nav={"VIEW ALL"} passedFunction={this.passedFunction}></Card>
                        </div>
                    </div>
                    <div className="profilepage_row2">
                        {/* <div className="user-chart-card">
                            <p className="user-chart-card__y-label bold">Visits</p>
                            {this.state.chartData.datasets && <Line options={options} data={this.state.chartData} />}
                            <div className="user-chart-card__footer">
                                <p className="user-chart-card__x-label bold">
                                    CHANGES IN SPH POWER
                                    <br />
                                    (average of both eyes)
                                </p>
                                <div className="user-chart-card__legend">
                                    {this.state?.chartData?.datasets?.map((o, i) => {
                                        return (
                                            <p className="user-chart-card__user">
                                                <span style={{ backgroundColor: colors[i] }} />
                                                {o.label}
                                            </p>
                                        );
                                    })}
                                </div>
                            </div>
                        </div> */}
                        <div className="profilepage_linkedProductsCard" style={{ position: "relative" }}>
                            {this.state.showTooltipState && (
                                <div className="profilepage_tooltip">
                                    <Title>PLEASE ADD LINKED PRODUCT</Title>
                                </div>
                            )}
                        </div>
                        <div className="profilepage_eyeTestResultsCard">{this.state.respEyeTestData !== undefined && this.state.respEyeTestData.length > 0 && <Card title={"EYE TEST RESULTS"} cardContent={EyeTestData} nav={"VIEW ALL"} />}</div>
                    </div>
                </div>
            </Layout>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        authentication: state.authentication,
        userProfile: state.userProfile,
    };
};

const mapDispatchToProps = (dispatch) => ({
    alertError: (error) => {
        dispatch(alertActions.error(error));
    },
    alertSuccess: (msg) => {
        dispatch(alertActions.success(msg));
    },
    updateToken: (data) => {
        dispatch({ type: userConstants.LOGIN_SUCCESS, user: data });
    },
    updateProfile: (data) => {
        dispatch({ type: userConstants.UPDATE_PROFILE, profile: data });
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
