import React, { Component } from "react";
import { Button } from "reactstrap";
import Icon from "react-icons-kit";
import { closeRound } from "react-icons-kit/ionicons/";

import beeicon from "assets/images/Miyobeehi.png";
import { ApiService, ServiceApiUrl } from "../_helpers/index";

class BeeChat extends Component {
    constructor(props) {
        super(props);
        this.state = {
            initChat: [],
            resmsg: [],
            initmsg: [],
            swap: true,
            openSections: [],
        };
    }

    componentDidMount = () => {
        this.loadInit();
    };

    nextFunction = (e) => {
        this.setState({
            swap: false,
        });
        this.handleToggle(e);
    };

    handleToggle = (e) => {
        this.setState({
            // openSections: [...this.state.openSections, e] <----- BOOKMARK, GOT ARRAY OF CLICKED ITEMS, NEED TO CHECK CONIDITON AGAINS ANY IN ARRAY
            openSections: e,
        });
    };

    handleclick = () => {
        console.log("CLICKED ");
    };

    nextQuestion = (params) => {
        const payload = {
            nextQuestionId: params,
        };
        this.getNextChat(payload).then((resp) => {
            this.setState({
                initChat: resp.data,
                resmsg: resp.data.answers,
            });
        });
    };

    getNextChat = (payload) => {
        const response = ApiService.post(ServiceApiUrl.NEXTCHAT, payload);
        console.log(response, "CHATMSG");

        return response;
    };

    loadInit = () => {
        this.getInitChat()
            .then((resp) => {
                console.log(resp, "working here");
                this.setState({
                    initChat: resp.data,
                    resmsg: resp.data.answers,
                    initmsg: resp.data.answers,
                });
            })
            .catch((err) => {
                console.log(err);
            });
    };

    getInitChat = (payload) => {
        const response = ApiService.get(ServiceApiUrl.GETINITCHAT, payload);
        console.log(response, "CHATMSG");
        return response;
    };

    render() {
        const { initChat, resmsg } = this.state;
        return (
            <div className="testo">
                <div className="testo-header">
                    <Icon icon={closeRound} onClick={() => this.props.toggle()} size={23} />
                </div>
                {this.state.swap ? (
                    <div className="linecomponent">
                        <div style={{ width: 50 }}>
                            <img className="chatlogo" src={beeicon} alt="" />
                        </div>

                        <Button onClick={() => this.handleclick()} className="chatline">
                            {initChat.questionDescription}
                        </Button>
                    </div>
                ) : null}

                <div className="renderer">
                    {Array.isArray(resmsg) &&
                        resmsg.map((resmsg) => (
                            <div
                                className="linecomponent"
                                key={resmsg.answerId}
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "flex-start",
                                }}>
                                <Button
                                    className="answerline buttonz"
                                    id="button"
                                    onClick={() => {
                                        this.nextQuestion(resmsg.nextQuestionId);
                                        this.nextFunction(resmsg.answerId);
                                    }}
                                    key={resmsg.answerId}>
                                    {resmsg.answerDescription}
                                </Button>

                                {this.state.openSections === resmsg.answerId ? (
                                    <div className="linecomponent">
                                        <div style={{ width: 50 }}>
                                            <img className="chatlogo" src={beeicon} alt="" />
                                        </div>

                                        <Button onClick={() => this.handleclick()} className="chatline">
                                            {initChat.questionDescription}
                                        </Button>
                                    </div>
                                ) : null}
                            </div>
                        ))}
                </div>
            </div>
        );
    }
}

export default BeeChat;
