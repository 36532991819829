import React from "react";
import { history } from "../_helpers/history";

import Icon from "react-icons-kit";
import { mail, lockClosedOutline, flagOutline, phoneOutline, locationOutline, pencil, plus, chevronRight } from "react-icons-kit/typicons";
import { ic_person_outline } from "react-icons-kit/md/ic_person_outline";

const addOrderNum = () => {
    history.push("/addnewordernumber");
};
const navLinkedProductDetails = (propProfileId, propElementTitleDesc1) => {
    console.log(propProfileId, propElementTitleDesc1, " CLICKED");
    history.push("/linkedproductdetails", {
        linkedProductOrderNum: propElementTitleDesc1,
        linkedProfileId: propProfileId,
    });
};
const nav = (navName) => {
    return navName === "EYE TEST RESULTS" ? history.push("/eyetestfullhistory") : history.push("/profilelinkedproducts");
};
const showIcon = ["EMAIL", "PASSWORD", "FULL NAME", "COUNTRY / REGION", "MOBILE NUMBER", "SHIPPING ADDRESS"];
const returnIcon = (elementTitle) => {
    return elementTitle === "EMAIL"
        ? mail
        : elementTitle === "PASSWORD"
        ? lockClosedOutline
        : elementTitle === "FULL NAME"
        ? ic_person_outline
        : elementTitle === "COUNTRY / REGION"
        ? flagOutline
        : elementTitle === "MOBILE NUMBER"
        ? phoneOutline
        : elementTitle === "SHIPPING ADDRESS"
        ? locationOutline
        : null;
};

export const Card = (props) => {
    const iconFeature = (props, prop) => {
        switch (props) {
            case "CHILDREN'S PROFILE":
                history.push("/linkedproductdetails", {
                    linkedProductOrderNum: prop.elementTitleDesc1,
                    linkedProfileId: prop.profileid,
                });
                break;
            case "EYE TEST RESULTS":
                history.push("/eyetesthistory", {
                    navPropAppointmentId: prop.appointmentid,
                    navPropProfileId: prop.profileid,
                });
                break;
            case "ACCOUNT":
                onHandleEdit(prop);
                break;
            case "PARENTS'S PROFILE":
                onHandleEdit(prop);
                break;
            default:
                break;
        }
    };

    const onHandleEdit = (data) => {
        props.editForm(data.inputField, data);
    };

    return (
        <div className="cardcomponent_container">
            <div className="card__header">
                <p className="title">{props.title}</p>
                {props.title === "CHILDREN'S PROFILE" ? <Icon onClick={() => addOrderNum()} className="card__details-button" size={13} icon={plus} /> : undefined}
            </div>
            <div className="cardcomponent_ContentContainer">
                {props.cardContent &&
                    props.cardContent.map((prop, i) => {
                        return (
                            <div
                                className={props.clickLinkedProductDetails === 1 ? "card__wrapper cardcomponent_hFlexbox2" : "card__wrapper cardcomponent_hFlexbox"}
                                key={i}
                                onClick={() => (props.hiddenProfileId ? navLinkedProductDetails(props.hiddenProfileId, prop.elementTitleDesc1) : prop.data && navLinkedProductDetails(prop.data[i].profileId, prop.data[i].orderNumber))}>
                                {showIcon.includes(prop.elementTitle) && props.icon1 !== "false" && <Icon size={18} icon={returnIcon(prop.elementTitle)} />}

                                <div className="cardcomponent_vflexboxElement">
                                    <div className="card__item">
                                        <p className="card__label">{prop.elementTitle}</p>
                                        <p className="card__content">
                                            <span
                                                className="card__value"
                                                style={{
                                                    color: prop.elementTitle === "PASSWORD" || prop.elementTitleDesc1 === "Please Update" ? "red" : null,
                                                }}>
                                                {prop.elementTitleDesc1}
                                            </span>
                                            {prop.elementTitleDesc2 ? <span className="card__value">{prop.elementTitleDesc2}</span> : <span />}
                                        </p>
                                    </div>
                                    {(prop.elementTitle2 || prop.elementTitle2Desc) && (
                                        <div className="card__item">
                                            <p className="card__label">{prop.elementTitle2}</p>
                                            <p className="card__content">
                                                <span
                                                    className="card__value"
                                                    style={{
                                                        color: prop.elementTitle2Desc === "Please Update" ? "red" : null,
                                                    }}>
                                                    {prop.elementTitle2Desc}
                                                </span>
                                            </p>
                                        </div>
                                    )}

                                    {(prop.elementTitle3 || prop.elementTitle3Desc) && (
                                        <div className={props.clickLinkedProductDetails === 1 ? "card__item2" : "card__item"}>
                                            <p className="card__label">{prop.elementTitle3}</p>
                                            <p>
                                                <span className="card__value">{prop.elementTitle3Desc}</span>
                                                <span />
                                            </p>
                                        </div>
                                    )}

                                    {(prop.elementTitle4 || prop.elementTitle4Desc) && (
                                        <div className={"card__item"}>
                                            <p className="card__label">{prop.elementTitle4}</p>
                                            <p>
                                                <span className="card__label">{prop.elementTitle4Desc}</span>
                                                <span />
                                            </p>
                                        </div>
                                    )}

                                    {(prop.elementTitle5 || prop.elementTitle5Desc) && (
                                        <div className={"card__item"}>
                                            <p className="card__label">{prop.elementTitle5}</p>
                                            <p>
                                                <span className="card__label">{prop.elementTitle5Desc}</span>
                                                <span />
                                            </p>
                                        </div>
                                    )}
                                </div>

                                {props.edit === true && props.icon2 !== "false" && (
                                    <div style={{ width: "60px", marginTop: "2%", color: "red" }} onClick={props.onClick}>
                                        Edit
                                    </div>
                                )}
                                {props.icon1 !== "false" && prop.icon2 !== "false" && (
                                    <Icon
                                        className="card__edit-icon"
                                        size={18}
                                        icon={
                                            props.title === "ACCOUNT"
                                                ? pencil
                                                : props.title === "PARENTS'S PROFILE"
                                                ? pencil
                                                : props.icon2 === "pencil"
                                                ? pencil
                                                : props.title === "CHILDREN'S PROFILE"
                                                ? chevronRight
                                                : props.title === "EYE TEST RESULTS"
                                                ? chevronRight
                                                : chevronRight
                                        }
                                        onClick={() => iconFeature(props.title, prop)}
                                    />
                                )}
                            </div>
                        );
                    })}

                {props.updateProfile && <div className="input-feedback">Please Update profile Fields to Proceed</div>}

                {props.nav && (
                    <div className="cardcomponent_navi bold" onClick={() => nav(props.title)}>
                        {props.nav}
                    </div>
                )}
            </div>
        </div>
    );
};
