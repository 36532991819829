import React, { Component } from "react";

import Layout from "components/layout";
import CardTable from "components/card-table";
import { tableHeader } from "../utilities/tableData";
import { ApiService, ServiceApiUrl } from "../../../_helpers";

export default class ApptDashboardPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentApptList: [],
            completedApptList: [],
        };
    }

    componentDidMount() {
        console.log("APPT Dashboard Did Mount :)");
        this.getApptList();
    }

    getApptList = () => {
        this.requestApptList()
            .then((resp) => {
                this.filterResp(resp.data);
            })
            .catch((err) => {
                alert(err);
            });
    };

    filterResp = (data) => {
        const completedAppt = data.filter((i) => {
            return i.status === "COMPLETED";
        });
        const currentAppt = data.filter((i) => {
            return i.status !== "COMPLETED";
        });

        this.setState({
            currentApptList: currentAppt,
            completedApptList: completedAppt,
        });
    };

    filterStatus = (data, status) => {
        return data.filter((i) => {
            return i.status === status;
        });
    };

    requestApptList = async () => {
        const response = await ApiService.get(ServiceApiUrl.APPT_List);
        return response;
    };

    editAppointment = (data) => {
        const { history } = this.props;
        history.push("appt-edit", data);
    };

    render() {
        const { completedApptList, currentApptList } = this.state;
        return (
            <Layout>
                <div className="apptDashboard-container">
                    <CardTable title="CURRENT APPOINTMENT" tableTHeadData={tableHeader.currentApptHeader} tableTBodyData={currentApptList} editAppt={this.editAppointment} />
                    <br />
                    <br />
                    <br />
                    <CardTable disabledEdit title="Completed APPOINTMENT" tableTHeadData={tableHeader.completedApptHeader} tableTBodyData={completedApptList} />
                </div>
            </Layout>
        );
    }
}
