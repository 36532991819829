import { redempConstants } from "../_constants/redemp.constants";

const initialState = {
  totalRedem: [],
  totalPoints: 0,
  currentPoints: 0
};

export const redempProduct = (state = initialState, action) => {
  switch (action.type) {
    case redempConstants.INIT_REDEMPTION:
      return initialState;
    case redempConstants.REDEMPTION_TOTAL_POINT:
      return {
        ...state,
        currentPoints: action.data
      };
    case redempConstants.UPDATE_TOTAL_REDEMP:
      const items = state.totalRedem.findIndex(i => {
        return i.id === action.data.id;
      });
      if (items < 0) {
        action.data.addedQTY++;
        return {
          ...state,
          totalRedem: [...state.totalRedem, action.data],
          totalPoints: state.totalPoints + action.data.points
        };
      } else {
        return state;
      }

    case redempConstants.REMOVE_REDEMP_ITEM:
      const totalRedemItems = state.totalRedem.filter(i => {
        return i.id !== action.data.id;
      });
      return {
        ...state,
        totalRedem: totalRedemItems,
        totalPoints:
          state.totalPoints - action.data.addedQTY * action.data.points
      };
    case redempConstants.ADD_REDEMP_ITEM_QTY:
      const addItem = state.totalRedem.map(i => {
        return i.id === action.data.id ? { ...i, addedQTY: i.addedQTY + 1 } : i;
      });
      return {
        ...state,
        totalRedem: addItem
      };
    case redempConstants.MINUS_REDEMP_ITEM_QTY:
      const minusItem = state.totalRedem.map(i => {
        return i.id === action.data.id ? { ...i, addedQTY: i.addedQTY - 1 } : i;
      });
      return {
        ...state,
        totalRedem: minusItem
      };
    case redempConstants.UPDATE_REDEMP_TOTAL_POINTS:
      return {
        ...state,
        totalPoints: action.data
      };
    default:
      return state;
  }
};
