import React, { Component } from "react";
import { Card, CardBody, Table } from "reactstrap";
import Icon from "react-icons-kit";
import { chevronLeft } from "react-icons-kit/ionicons/";
import { pencil } from "react-icons-kit/typicons";
import Pagination from "react-js-pagination";
import moment from "moment";

export default class CardTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activePagination: 1,
        };
    }

    handlePageChange = (i) => {
        this.setState({
            activePagination: i,
        });
        this.props.nextPage(i);
        //ON CHANGE DEN CALL PROPS FUNCTION API
    };

    renderPagination = () => {
        const { paginationTotal } = this.props;
        const { activePagination } = this.state;
        if (paginationTotal) {
            return (
                <Pagination
                    activePage={activePagination}
                    itemsCountPerPage={10}
                    pageRangeDisplayed={7}
                    totalItemsCount={paginationTotal}
                    firstPageText={"First Page"}
                    lastPageText={"Last Page"}
                    itemClassFirst="pagination-first-child"
                    itemClassLast="pagination-last-child"
                    onChange={this.handlePageChange}
                />
            );
        }
    };

    renderTableBody = () => {
        const { tableTBodyData, disabledEdit, editAppt, tableTHeadData } = this.props;
        return (
            tableTBodyData &&
            tableTBodyData.map((data, i) => {
                const keys = Object.keys(data);
                return (
                    <tr key={`${i}_tr_${keys[i]}`}>
                        {tableTHeadData.map((thead, j) => {
                            const index = keys.indexOf(thead.key);
                            if (thead.key === keys[index]) {
                                switch (thead.format) {
                                    case "date":
                                        return <td key={data[keys[index]]}>{moment(data[keys[index]]).format("DD/MM/YYYY")}</td>;
                                    case "dateTime":
                                        return <td key={data[keys[index]]}>{moment(data[keys[index]]).format("DD/MM/YYYY") + " " + data["time"]}</td>;
                                    default:
                                        return <td key={`${data[keys[index]]}`}>{data[keys[index]]}</td>;
                                }
                            } else if (!disabledEdit && thead.key === "edit" && tableTHeadData.length === j + 1) {
                                return (
                                    <td className="edit" key={`${j}_edit`}>
                                        <Icon icon={pencil} onClick={() => editAppt(data)} />
                                    </td>
                                );
                            } else {
                                return null;
                            }
                        })}
                    </tr>
                );
            })
        );
    };

    render() {
        const { title, tableTHeadData, type, goBack, tfoot } = this.props;
        const customStyle = type ? type : null;
        return (
            <div className={["card-container", customStyle].join(" ")}>
                <div className="card-header-container">
                    <h1 className="title">{title}</h1>
                    {goBack && (
                        <div className="back-btn-container" onClick={goBack}>
                            <Icon icon={chevronLeft} size={10} />
                            <p>Back</p>
                        </div>
                    )}
                </div>
                <Card>
                    <CardBody>
                        <Table>
                            <thead>
                                <tr>
                                    {tableTHeadData &&
                                        tableTHeadData.map((tHead, i) => {
                                            return <th key={`${i}_${tHead.label}`}>{tHead.label}</th>;
                                        })}
                                </tr>
                            </thead>
                            <tbody>{this.renderTableBody()}</tbody>
                            {tfoot && <tfoot>{tfoot.props.children}</tfoot>}
                        </Table>
                        {this.renderPagination()}
                    </CardBody>
                </Card>
            </div>
        );
    }
}
