import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Modal } from "reactstrap";
import ReactLoading from "react-loading";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import GoogleLogin from "react-google-login";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";

import { userActions } from "../../_actions";
import { ApiService, ServiceApiUrl } from "../../_helpers/index";
import Button from "components/button";
import Footer from "components/footer";
import miyosmartLogo from "assets/images/headLogo.svg";
import facebook from "assets/images/facebook.svg";
import google from "assets/images/google.svg";
import miyoBee from "assets/images/miyo-bee.svg";

class RegisterPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            user: {
                loading: false,
                email: "",
                mobilenumber: "",
                password: "",
            },
            modal: false,
            submitted: false,
        };
    }

    componentDidMount() {
        const { location } = this.props;
        if (location.state) {
            this.setState({
                referenceNumber: location.state.payload,
            });
        }
    }

    toggle = () => {
        this.setState((prevState) => ({
            modal: !prevState.modal,
        }));
    };

    handleChange = (event) => {
        const { name, value } = event.target;
        const { user } = this.state;
        this.setState({
            user: {
                ...user,
                [name]: value,
            },
        });
    };

    handleSubmit = (values) => {
        const { referenceNumber } = this.state;
        if (values.email && values.mobilenumber && values.password) {
            const payload = {
                Email: values.email,
                Password: values.password,
                MobileNumber: values.mobilenumber,
                OrderNumber: referenceNumber,
            };
            this.setState({ loading: true });
            this.props.register(payload, (response) => {
                if (response === "error") {
                    this.setState({
                        loading: false,
                    });
                } else if (response === "success") {
                    this.setState({ loading: false }, () => {
                        this.toggle();
                    });
                } else {
                    this.setState({ loading: false });
                }
            });
        }
    };

    navigateTo = () => {
        const { history } = this.props;
        history.push("login");
    };

    responseFacebook = (resp) => {
        const { referenceNumber } = this.state;
        const payload = {
            Email: resp.email,
            OrderNumber: referenceNumber,
        };
        this.props.registerSSO(payload, () => {
            this.toggle();
        });
    };

    responseGoogle = (resp) => {
        console.log(resp, " GOOGLE RESP");
        const { referenceNumber } = this.state;
        const payload = {
            Email: resp.profileObj.email,
            OrderNumber: referenceNumber,
        };
        this.props.registerSSO(payload, () => {
            this.toggle();
        });
    };

    requestRegisterWithSSO = (payload) => {
        const response = ApiService.post(`${ServiceApiUrl.REGISTER_SSO}`, payload);
        return response;
    };

    render() {
        const phoneRegExp = /^(01)[0-46-9]-*[0-9]{7,8}$/;

        return (
            <div className="register">
                {this.state.loading && (
                    <div className="loadingcontainer">
                        <div className="loading-spinner">
                            <p className="title">Loading</p>

                            <ReactLoading type={"spinningBubbles"} color={"#2f368f"} height={50} width={50} />
                        </div>
                    </div>
                )}

                <div className="register__wrapper">
                    <div className="register__content">
                        <div className="hoyalogoContainer">
                            <Link to="/login">
                                <img className="hoyalogoStyle" src={miyosmartLogo} alt="" />
                            </Link>
                        </div>
                    </div>
                    <div className="register__content">
                        <div className="register-inner-wrapper">
                            <div className="form-container">
                                <div className="title">CREATE AN ACCOUNT</div>
                                <Formik
                                    initialValues={{
                                        email: "",
                                        password: "",
                                        confirmPassword: "",
                                        mobilenumber: "",
                                        tnc: false,
                                        ca: false,
                                    }}
                                    onSubmit={(values, { setSubmitting }) => {
                                        //needs send to backend then acccept respond and condition it to popup
                                        setSubmitting(true);
                                        this.handleSubmit(values);
                                    }}
                                    validationSchema={Yup.object().shape({
                                        email: Yup.string().email().required("Required"),

                                        password: Yup.string().required("Required").min(6, "min 6 character password").max(15, "try a shorter password."),
                                        confirmPassword: Yup.string()
                                            .required("Required")
                                            .test("passwords-match", "Passwords must match", function (value) {
                                                return this.parent.password === value;
                                            }),

                                        mobilenumber: Yup.string().required("Required").matches(phoneRegExp, "Phone number is not valid"),
                                        tnc: Yup.bool().oneOf([true], "Please tick Terms of use"),
                                        ca: Yup.bool().oneOf([true], "Please tick Consent Agreement"),
                                    })}>
                                    {(props) => {
                                        const { values, touched, errors, isSubmitting, handleChange, handleBlur, handleSubmit } = props;
                                        return (
                                            <form onSubmit={handleSubmit}>
                                                <div className="inputContainer">
                                                    <label htmlFor="email" className="input-label">
                                                        EMAIL*
                                                    </label>

                                                    <input id="email" type="text" autoComplete="off" value={values.email} onChange={handleChange} onBlur={handleBlur} className={errors.email && touched.email ? "text-input error" : "text-input"} />
                                                    {errors.email && touched.email && <div className="input-feedback">{errors.email}</div>}
                                                </div>
                                                <div className="inputContainer">
                                                    <label htmlFor="password" className="input-label">
                                                        PASSWORD*
                                                    </label>
                                                    <input
                                                        id="password"
                                                        type="password"
                                                        autoComplete="off"
                                                        value={values.password}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={errors.password && touched.password ? "text-input error" : "text-input"}
                                                    />
                                                    {errors.password && touched.password && <div className="input-feedback">{errors.password}</div>}
                                                </div>
                                                <div className="inputContainer">
                                                    <label htmlFor="confirmPassword" className="input-label">
                                                        CONFIRM PASSWORD*
                                                    </label>
                                                    <input
                                                        id="confirmPassword"
                                                        type="password"
                                                        autoComplete="off"
                                                        value={values.confirmPassword}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={errors.confirmPassword && touched.confirmPassword ? "text-input error" : "text-input"}
                                                    />
                                                    {errors.confirmPassword && touched.confirmPassword && <div className="input-feedback">{errors.confirmPassword}</div>}
                                                </div>
                                                <div className="inputContainer">
                                                    <label htmlFor="mobilenumber" className="input-label">
                                                        MOBILE NUMBER*{" "}
                                                    </label>
                                                    <input
                                                        id="mobilenumber"
                                                        placeholder="Example 0123456789"
                                                        type="tel"
                                                        autoComplete="off"
                                                        value={values.mobilenumber.length < 2 ? (values.mobilenumber = "01") : values.mobilenumber}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={errors.mobilenumber && touched.mobilenumber ? "text-input error" : "text-input"}
                                                    />
                                                    {errors.mobilenumber && touched.mobilenumber && <div className="input-feedback">{errors.mobilenumber}</div>}
                                                </div>
                                                <div className="checkbox-container">
                                                    <Field
                                                        name="tnc"
                                                        id="tnc-input"
                                                        render={({ field, form }) => {
                                                            return <input type="checkbox" id="checkboxx" checked={field.value} {...field} />;
                                                        }}
                                                    />
                                                    <label htmlFor="tnc" className="checkbox-label">
                                                        I agree to the
                                                        <a href="https://miyosmart.hoya.com.my/terms_of_use.html" target="_blank" rel="noreferrer">
                                                            {" "}
                                                            Terms of use
                                                        </a>
                                                    </label>
                                                </div>
                                                <div className="checkbox-container">
                                                    <Field
                                                        name="ca"
                                                        id="ca-input"
                                                        render={({ field, form }) => {
                                                            return <input type="checkbox" id="checkboxxx" checked={field.value} {...field} />;
                                                        }}
                                                    />
                                                    <label htmlFor="ca" className="checkbox-label">
                                                        I agree to the
                                                        <a href="https://miyosmart.hoya.com.my/consent_agreement.html" target="_blank" rel="noreferrer">
                                                            {" "}
                                                            Consent Agreement
                                                        </a>
                                                    </label>
                                                </div>

                                                <div className="register__footer">
                                                    <div className="button-container">
                                                        <Button title="SIGN UP" type="submit" disabled={isSubmitting} />
                                                    </div>

                                                    <div className="register__bee">
                                                        <img src={miyoBee} alt=""/>
                                                    </div>

                                                    <div className="sso-signUp-container">
                                                        <p>Sign Up with</p>

                                                        <div className="sso-signUp-wrapper">
                                                            <FacebookLogin
                                                                appId="525844644637607"
                                                                fields="name,email,picture"
                                                                callback={this.responseFacebook}
                                                                render={(renderProps) => <img className="socialIcons" onClick={renderProps.onClick} src={facebook} alt="" />}
                                                            />
                                                            <GoogleLogin
                                                                clientId="859934599216-pg8gsk52ci51dl3mrf781g2mqim20ck5.apps.googleusercontent.com"
                                                                render={(renderProps) => <img className="socialIcons" src={google} onClick={renderProps.onClick} alt="" />}
                                                                onSuccess={this.responseGoogle}
                                                                onFailure={this.responseGoogle}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        );
                                    }}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer hideSocialMedia />
                <Modal isOpen={this.state.modal} toggle={this.toggle} onClosed={this.navigateTo} className={"modalBodyContainer"}>
                    <div className="modalBodyContent">
                        <p className="title">Registration Email Sent</p>
                        <p className="description">
                            An account registration request has been sent to your email. Click the link in the message to validate your email address and create your account. Please check spam inbox if confirmation email not received. <br />
                            <br /> Questions? Contact <a href="mailto: support@hoya.com.my">support@hoya.com.my</a>
                        </p>
                    </div>
                </Modal>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        register: state.registration,
    };
}

const mapDispatchToProps = {
    register: userActions.register,
    registerSSO: userActions.registerSSO,
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterPage);
