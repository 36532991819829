import React, { Component } from 'react';
import { ApiService, ServiceApiUrl } from "../../_helpers/index";
import NavBar from 'components/nav-bar';
import Button from 'components/button';
import Bottom from 'components/bottom';

import awardsBg1 from 'assets/images/awardsBg1.png'
import thumbNail from 'assets/images/awards-thumbnail-doc.png';
import awardsSilmo from 'assets/images/awards-silmo.png';
import awardsGoldPrice from 'assets/images/awards-gold-prize.png';
import iconAddMore from 'assets/images/testimonials-add-more.png';

export default class Awards extends Component {
  state={
    articles: [],
    articlesPage: 1,
    totalPage: 1
  }

  componentDidMount = () => {
    this.getArticles(this.state.articlesPage);
  }

  getArticlesAPI = async (page) => {
    const response = await ApiService._get(ServiceApiUrl.GETARTICLERESEARCH+page+"/"+3);
    return response
  }

  getArticles = async (page) => {
    try{
      const response = await this.getArticlesAPI(page);    
      if(response.data.articles){
        if(response.data.articles.length !==0){
          this.setState({ 
            ...this.state,
            articles: [...this.state.articles, ...response.data.articles], 
            totalPage: response.data.totalPage
          })
        }
      }
    }catch (error) {}
  }

  
  addMoreArticles = () => {
    const tempPage = this.state.articlesPage+1;

    this.setState({...this.state, articlesPage: tempPage})
    this.getArticles(tempPage);
  }
  
  openNewWindow = (url) => {
    window.open(url)
  }

  render() {
    const { articles, totalPage, articlesPage } =this.state;

    return (
      <div className="awards-page">
        <div className="awards">
          <NavBar/>
          <div className="awards-1">
            <div className="awards-1__main">
              <div className="awards-1__left">
                <div className="awards-1__left-content">
                  <div>
                    <div className="bold">An award-winning,</div>
                    <div className="bold">breakthrough lens.</div>
                  </div>
                </div>
              </div>
              <img className="awards-1__right" src={awardsBg1} alt=""/>
            </div>
          </div>

          <div className="awards-2">
            <div className="awards-2__main">
              <div className="awards-2__left">
                <img className="awards-2__left-image" src={awardsGoldPrice} alt=""/>
              </div>
              <div className="awards-2__right">
                <div className="awards-2__right-container">
                  <div className="awards-2__right-title">
                    <div>Gold Prize, Grand Award &</div>
                    <div>Special Gold Award</div>
                  </div>
                  <div className="awards-2__right-body">
                    In 2018, MiYOSMART won the Gold Prize, Grand Award
                    & Special Gold Award at the 46th International Exhibition of
                    Inventions of Geneva, Switzerland.
                  </div>
                  <div className="awards-2__right-body">
                    The International Exhibition of Inventions of Geneva is
                    the biggest event in the world to be exclusively devoted to
                    inventions.
                  </div>
                  <div className="awards-2__right-body">
                    Industrial and commercial companies, universities, inventors
                    and researchers, as well as private and state organisations
                    present their inventions and the results of their research there.
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="awards-3">
            <div className="awards-3__main">
              <div className="awards-3__left">
                <div className="awards-3__left-container">
                  <div className="awards-3__left-title">
                    Silmo d'Or Award
                  </div>
                  <div className="awards-3__left-body">
                    In 2020, MiYOSMART won the highly regarded Silmo d'Or
                    Award in the Vision category at the SILMO Paris Optical Fair.
                  </div>
                  <div className="awards-3__left-body">
                    The annual SILMO Paris is a benchmark event for the optics
                    and eyewear industry. 
                  </div>
                  <div className="awards-3__left-body">
                    It brings together the latest technological innovations,
                    processes, and materials by experts and trendsetters in the
                    optical industry.                   
                  </div>
                  <div className="awards-3__left-body">
                    The SILMO d’Or prize rewards excellence and creativity that
                    help drive the industry.                 
                  </div>
                </div>
              </div>
              <div className="awards-3__right">
                <img className="awards-3__right-image" src={awardsSilmo} alt=""/>
              </div>
            </div>
          </div>

          <div className="awards-4">
            <div className="awards-4__main">
              <div className="awards-4__text-title">
                MiYOSMART Research Papers
              </div>
              <div className="awards-4__text-body">
                <div>Learn more from our published research papers about</div>  
                <div>the technology behind MiYOSMART.</div>     
              </div>
              {articles? articles.length>0?
              <div className="awards-4__item-list">
                {articles.map((article, index) =>
                  <div key={index} className="awards-4__item">
                    <img src={thumbNail} className="awards-4__item-image" alt=""/>
                    <div className="awards-4__item-text">
                      {article.title}
                    </div>
                    <div className="awards-4__item-button-wrap">
                      <Button
                        title="DOWNLOAD"
                        type="submit"
                        customClass="awards-4__item-button"
                        onClick={()=> this.openNewWindow(article.content)}
                      />  
                    </div>
                  </div>
                )}
                </div>
              : null: null}
              { articlesPage < totalPage?
                <div className="awards-4__bottom">
                  See More
                  <img 
                    className="awards-4__bottom-button" 
                    src={iconAddMore}
                    onClick={this.addMoreArticles}
                    alt=""
                  />
                </div>
              :null}
            </div>
          </div>

          <Bottom/>
        </div>
      </div>
    )
  }
}