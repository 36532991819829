import React, { Fragment, Component } from "react";
import moment from "moment";
import Layout from "components/layout";
import { ApiService } from "../../_helpers/index";
import { history } from "../../_helpers/history";
import { chevronLeft } from "react-icons-kit/ionicons";
import Icon from "react-icons-kit";

export default class EyeTestHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            eyeTestHistory: [],
        };
    }

    backToProfile = () => {
        history.push("/profile");
    };

    getEyeTestHistoryData = async (payload) => {
        const response = await ApiService.get(process.env.REACT_APP_MIYOSMART_URL + "Appointment/" + this.props.location.state.navPropProfileId + "/" + this.props.location.state.navPropAppointmentId, payload);
        this.setState({
            eyeTestHistory: [response.data],
        });
    };

    componentWillMount() {
        this.getEyeTestHistoryData();
    }

    render() {
        const { eyeTestHistory } = this.state;

        return (
            <Layout>
                <div className="eye-test-history">
                    <div className="eye-test-history__header">
                        <p className="title">EYE TEST HISTORY</p>
                        <div className="eye-test-history__back-btn" onClick={() => this.backToProfile()}>
                            <Icon icon={chevronLeft} size={10} />
                            <p className="bold">Back</p>
                        </div>
                    </div>

                    {!!eyeTestHistory.length &&
                        eyeTestHistory.map((data, index) => {
                            return (
                                <Fragment key={index}>
                                    <p className="title eye-test-history__title">APPOINTMENT {index + 1}</p>
                                    <div className="eye-test-history__item">
                                        <div className="eye-test-history__content-wrapper">
                                            <div className="eye-test-history__content">
                                                <p className="eye-test-history__label">NAME OF PATIENT</p>
                                                <p className="eye-test-history__value semibold">{data.profileName}</p>
                                            </div>
                                            <div className="eye-test-history__content">
                                                <p className="eye-test-history__label">DATE OF APPOINTMENT</p>
                                                <p className="eye-test-history__value semibold">{moment(data.appointmentDate).format("DD/MM/YYYY")}</p>
                                            </div>
                                            <div className="eye-test-history__content">
                                                <p className="eye-test-history__label">BRANCH</p>
                                                <p className="eye-test-history__value semibold">{data.storeDescription}</p>
                                            </div>
                                        </div>
                                        <div className="eye-test-history__results-wrapper">
                                            <div className="eye-test-history__results-content">
                                                <p className="eye-test-history__label">RESULTS</p>
                                            </div>
                                            <div className="eye-test-history__results-content">
                                                <p className="eye-test-history__value"></p>
                                                <p className="eye-test-history__value semibold">RIGHT</p>
                                                <p className="eye-test-history__value semibold">LEFT</p>
                                            </div>
                                            <div className="eye-test-history__results-content">
                                                <p className="eye-test-history__label">SPH</p>
                                                <p className="eye-test-history__value semibold">{data.rightResult.sph}</p>
                                                <p className="eye-test-history__value semibold">{data.leftResult.sph}</p>
                                            </div>
                                            <div className="eye-test-history__results-content">
                                                <p className="eye-test-history__label">CYL</p>
                                                <p className="eye-test-history__value semibold">{data.rightResult.cyl}</p>
                                                <p className="eye-test-history__value semibold">{data.leftResult.cyl}</p>
                                            </div>
                                            <div className="eye-test-history__results-content">
                                                <p className="eye-test-history__label">AXIS</p>
                                                <p className="eye-test-history__value semibold">{data.rightResult.axis}</p>
                                                <p className="eye-test-history__value semibold">{data.leftResult.axis}</p>
                                            </div>
                                        </div>
                                    </div>
                                </Fragment>
                            );
                        })}
                </div>
            </Layout>
        );
    }
}
