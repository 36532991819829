import React, { Component } from "react";
import { Formik } from "formik";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import Footer from "components/footer";
import Button from "components/button";
import miyobee from "assets/images/miyo-bee.svg";
import authenticityCard from "assets/images/authenticityCard.png";
import { history } from "../../_helpers/history";
import { ApiService, ServiceApiUrl } from "../../_helpers/index";
import { alertActions } from "../../_actions";

class SignUpOrderNoPage extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    auth = (values) => {
        const payload = {
            ReferenceNumber: values.ReferenceNumber,
        };
        this.sendOrderNo(payload)
            .then((resp) => {
                console.log(resp);
                history.push("/register", { payload: values.ReferenceNumber });
            })
            .catch((err) => {
                const errMsg = err.response.data ? err.response.data.message : err.message;
                this.props.alertError(errMsg);
            });
    };

    sendOrderNo = (payload) => {
        const response = ApiService.get(`${ServiceApiUrl.ORDERNO}/${payload.ReferenceNumber}`);
        return response;
    };

    render() {
        return (
            <div className="sign-up">
                <Link to="/login">
                    <div className="hoya-logo" />
                </Link>
                <div className="signUpCard">
                    <div className="sign-up__wrapper">
                        <div className="title">PLEASE KEY IN HOYA MIYOSMART Order No.</div>
                        <img className="authenticityCard" src={authenticityCard} alt="" />
                        <div className="cardInputContainer">
                            <Formik
                                initialValues={{ ReferenceNumber: "" }}
                                onSubmit={(values, { setSubmitting }) => {
                                    this.auth(values);
                                    setSubmitting(false);
                                }}>
                                {(props) => {
                                    const { values, touched, errors, isSubmitting, handleChange, handleBlur, handleSubmit } = props;
                                    return (
                                        <form onSubmit={handleSubmit} className="signUpForm">
                                            <div className="inputContainer">
                                                <input
                                                    id="ReferenceNumber"
                                                    type="text"
                                                    value={values.ReferenceNumber}
                                                    placeholder="Order No."
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={errors.orderNo && touched.orderNo ? "text-input error" : "text-input"}
                                                />
                                                {errors.orderNo && touched.orderNo && <div className="input-feedback">{errors.orderNo}</div>}
                                            </div>
                                            <Button title="VERIFY" type="submit" disabled={isSubmitting} />
                                        </form>
                                    );
                                }}
                            </Formik>
                        </div>
                        <div className="registor-container">
                            <img className="miyobeeLogo" src={miyobee} alt="" />
                        </div>
                    </div>
                </div>

                <Footer />
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    alertError: (error) => {
        dispatch(alertActions.error(error));
    },
});
export default connect(null, mapDispatchToProps)(SignUpOrderNoPage);
