const API_URL = process.env.REACT_APP_MIYOSMART_URL;

export const ServiceApi = {
    GETARTICLE: API_URL + "article/",
    GETARTICLEVIDEO: API_URL + "article/video",
    GETARTICLERESEARCH: API_URL + "article/research/",

    GETBANNER: API_URL + "Banner/Promo",
    SHAREBANNER: API_URL + "Banner/Share",
    VIEWPROFILE: API_URL + "User",
    CREATELINKPRODUCT: API_URL + "Link",
    SHOWLINKPROFILE: API_URL + "Profile",
    GETLINKPRODUCT: API_URL + "Profile/",
    INITIALCHAT: API_URL + "chat/1",
    NEXTCHAT: API_URL + "chat/",
    ORDERNO: API_URL + "Account/ReferenceNumber",
    GETNOTI: API_URL + "Notification",
    READNOTI: API_URL + "Notification/",
    GETALLAPPT: API_URL + "Appointment/AllAppointment",
    GETINITCHAT: API_URL + "chat/1",
    GETLINKEDPRODUCT: API_URL + "Link/Profile/",

    POSTTEST: API_URL + "Link",
    CHECKEMAIL: API_URL + "Account/Forget",
    CHECKSESSION: API_URL + "Account/ResetPassword/",
    RESETPASS: API_URL + "Account/ResetPassword",
    SENDREQ: API_URL + "Appointment/RequestChange/",
    CHANGEDETAILS: API_URL + "Account/",

    /* Summary Point History */
    REDEEM_ITEMS: API_URL + "Redeem/",
    POINT_HISTORY: API_URL + "Points/History/",
    /* Redemption items */
    REDEEM_CONFIRM_ITEMS: API_URL + "Redeem",
    /* VERIFY EMAIL */
    VERIFY_EMAIL: API_URL + "Account/VerifyEmail/",
    /* APPT REQUEST */
    APPT_List: API_URL + "Appointment/AllAppointment",
    APPT_CHANGE: API_URL + "Appointment/RequestChange/",
    APPT_TIME_SLOT: API_URL + "Appointment/TimeSlot",
    /*CONTACTS US*/
    CONTACT_US: API_URL + "Contact",
};
