import ForgetPasswordPage from "./ForgetPassword";
import ForgetPasswordStep2Page from "./ForgetPasswordStep2";
import ForgetPasswordChangePasswordPage from "./ForgetPasswordChangePassword";
import ForgetPasswordResettedPage from "./ForgetPasswordResetted";

const ForgetPassword = ForgetPasswordPage;
const ForgetPasswordStep2 = ForgetPasswordStep2Page;
const ForgetPasswordChangePassword = ForgetPasswordChangePasswordPage;
const ForgetPasswordResetted = ForgetPasswordResettedPage;

export {
  ForgetPassword,
  ForgetPasswordStep2,
  ForgetPasswordChangePassword,
  ForgetPasswordResetted
};
