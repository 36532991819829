const timeSlotData = [
    {
        label: "9AM - 11AM",
        index: 0,
    },
    {
        label: "11AM - 1PM",
        index: 1,
    },
    {
        label: "1PM - 3PM",
        index: 2,
    },
    {
        label: "3PM - 5PM",
        index: 3,
    },
    {
        label: "5PM - 7PM",
        index: 4,
    },
    {
        label: "7PM - 9PM",
        index: 5,
    },
];

export { timeSlotData };
