import React, { Component } from "react";

export default class Button extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { type, title, disabled, outline, onClick, customClass, link } = this.props;
        const outlineS = outline ? "outline" : null;
        const customClassS = customClass ? customClass : null;
        return (
            <button type={type} className={["miyosmart-button bold", outlineS, customClassS].join(" ")} disabled={disabled} onClick={onClick}>
                {link ? (
                    <a
                        rel="noreferrer"
                        href={link}
                        target="_blank"
                        style={{
                            color: "#fff",
                            textDecoration: "none",
                            cursor: "pointer",
                            display: "block",
                        }}>
                        {title}
                    </a>
                ) : (
                    title
                )}
            </button>
        );
    }
}
