import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import NavBar from "components/nav-bar";
import Button from "components/button";
import Bottom from "components/bottom";

import landingBg1 from "assets/images/landingBg1.jpg";
import landingBg2 from "assets/images/landingBg2.png";
import landingBg3 from "assets/images/landingBg3.png";
import landingBg4 from "assets/images/landingBg4.png";
import landingBee1 from "assets/images/landing-bee1.png";
import landingBee2 from "assets/images/landing-bee2.png";
import landingBee3 from "assets/images/landing-bee3.png";
import landingIcon11 from "assets/images/landingIcon11.png";
import landingIcon12 from "assets/images/landingIcon12.png";
import eyeDrops from "assets/images/landing-eye-drops.png";
import contactLens from "assets/images/landing-contact-lens.png";
import spectacleLenses from "assets/images/landing-spectacle-lenses.png";

class Landing extends Component {
    render() {
        return (
            <div className="landing-page">
                <div className="landing">
                    <NavBar />
                    <div className="landing-1">
                        <div className="landing-1__main">
                            <div className="landing-1__center-circle">
                                <div className="landing-1__center-circle-container">
                                    <div className="landing-1__center-circle-text">
                                        <div className="landing-1__center-circle-text--title">60%</div>
                                        <div className="landing-1__center-circle-text--subtitle">slow down</div>
                                        <div className="landing-1__center-circle-text--subtitle">of myopia</div>
                                        <div className="landing-1__center-circle-text--subtitle">progression</div>
                                    </div>
                                </div>
                            </div>
                            <div className="landing-1__left">
                                <div className="landing-1__left-content">
                                    <div className="landing-1__left-content-container">
                                        <div>
                                            <div>MiYOSMART: </div>
                                            <div>the smart way </div>
                                            <div>to treat myopia </div>
                                            <div>in children. </div>
                                        </div>
                                        <div className="landing-1__left-content-bottom">
                                            <div>
                                                <Button title="LEARN MORE" customClass="landing-1__left-content-bottom-button" link={"https://www.youtube.com/watch?v=HDt8lNIp0eQ"} />
                                            </div>
                                            <img className="landing-1__landing-bee" src={landingBee1} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <img className="landing-1__right" src={landingBg1} alt="" />
                        </div>
                    </div>

                    <div className="landing-2">
                        <div className="landing-2__main">
                            <div className="landing-2__left">
                                <img src={landingBg2} className="landing-2__left-image" alt="" />
                            </div>
                            <div className="landing-2__right">
                                <div className="landing-2__right-wrap">
                                    <div className="landing-2__right-content">
                                        <div className="landing-2__right-text landing-2__right-text--title">What is myopia?</div>
                                        <div className="landing-2__right-text landing-2__right-text--body">
                                            <div>You may have noticed your child</div>
                                            <div>struggling to see clearly at a distance.</div>
                                        </div>
                                        <div className="landing-2__right-text landing-2__right-text--body">
                                            <div>Do they have a hard time</div>
                                            <div>concentrating at school and at play?</div>
                                        </div>
                                        <div className="landing-2__right-text landing-2__right-text--body">Your child may have myopia, or short-sightedness.</div>
                                    </div>
                                    <div className="landing-2__right-content">
                                        <div className="landing-2__right-text landing-2__right-text--title">How does it happen?</div>
                                        <div className="landing-2__right-text landing-2__right-text--body">
                                            <div>This happens when the eyeball grows fast and becomes</div>
                                            <div>too long. Or when the cornea is too curved and thick.</div>
                                        </div>
                                        <div className="landing-2__right-text landing-2__right-text--body">
                                            <div>Instead of focusing on the retina,</div>
                                            <div>images focus in front of the retina instead.</div>
                                        </div>
                                        <div className="landing-2__right-text landing-2__right-text--body landing-2__right-text--last">
                                            <div>
                                                <div>If left untreated, myopia can</div>
                                                <div>lead to lasting vision problems.</div>
                                            </div>
                                            <img className="landing-2__landing-bee" src={landingBee2} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="landing-3">
                        <div className="landing-3__left" />
                        <img className="landing-3__left-image" src={landingBg3} alt="" />
                        <div className="landing-3__right">
                            <div className="landing-3__right-container">
                                <div className="landing-3__right-text landing-3__right-text--title">Myopia is a growing global concern.</div>
                                <div className="landing-3__right-body">
                                    <img className="landing-3__right-body-image" src={landingIcon11} alt="" />
                                    <div className="landing-3__right-body-text">
                                        An estimated 5 billion people, or half of the global population, could be affected by short-sightedness by 2050. <sup>(1)</sup>
                                    </div>
                                </div>
                                <div className="landing-3__right-body">
                                    <img className="landing-3__right-body-image" src={landingIcon12} alt="" />
                                    <div className="landing-3__right-body-text">
                                        Research shows that more time spent on near-work activities is associated with a greater likelihood of myopia. <sup>(2)</sup>
                                    </div>
                                </div>
                                <div className="landing-3__right-text landing-3__right-text--citation">
                                    (1) Holden B.A., Fricke T.R., Wilson D.A., Jong M., Naidoo K.S., Sankaridurg P., Wong T.Y., Naduvilath T.J., Resniko_S. Global Prevalence of Myopia and High Myopia and Temporal Trends from 2000 through 2050.
                                    American Academy of Ophthalmology. 05/2016, vol.123, no. 5, p.1036-1042. https://dol.org/10.1016/j.ophtha.2016.01.006
                                </div>
                                <div className="landing-3__right-text landing-3__right-text--citation">
                                    (2) Huang H-M, Chang DS-T, Wu P-C. The Association between Near Work Activities and Myopia in Children —A Systematic Review and Meta-Analysis. 2015. PLoS ONE 10(10): e0140419.
                                    https://doi.org/10.1371/journal.pone.0140419
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="landing-4">
                        <div className="landing-4__main">
                            <div className="landing-4__text-title">What can you do?</div>
                            <div className="landing-4__text-body">While there is no cure, a variety of treatments have shown to be effective</div>
                            <div className="landing-4__text-body">in slowing or halting myopia progression.</div>
                            <div className="landing-4__image-list">
                                <div className="landing-4__image">
                                    <img src={eyeDrops} className="landing-4__image-image" alt="" />
                                    <div className="landing-4__image-text">Eye Drops</div>
                                </div>
                                <div className="landing-4__image">
                                    <img src={spectacleLenses} className="landing-4__image-image" alt="" />
                                    <div className="landing-4__image-text">Spectacle Lenses</div>
                                </div>
                                <div className="landing-4__image">
                                    <img src={contactLens} className="landing-4__image-image" alt="" />
                                    <div className="landing-4__image-text">Contact Lenses</div>
                                </div>
                            </div>
                            <div className="landing-4__text-body">Spectacle lenses are widely recommended as they are safe and easy for your child to wear.</div>
                        </div>
                    </div>

                    <div className="landing-5">
                        <div className="landing-5__left">
                            <div className="landing-5__left-content">
                                <div className="landing-5__left-content-title">It all starts with an eye exam.</div>
                                <div className="landing-5__left-content-body">Regular eye exams help to detect early signs of visual problems.</div>
                                <div className="landing-5__left-content-body">If either one or both parents are short-sighted, eye exams are even more important.</div>
                                <div className="landing-5__left-content-body landing-5__left-content-body--last">When you identify myopia early, you can slow it down with early, personalised action plans.</div>
                                <div className="landing-5__left-content-title">Is it possible to slow down myopia progression in your child?</div>
                                <div className="landing-5__left-content-answer">Yes, it is possible.</div>
                                <div className="landing-5__left-content-bottom">
                                    <div>
                                        <Button
                                            title="FIND OUT MORE"
                                            type="submit"
                                            customClass="landing-5__left-content-bottom-button"
                                            onClick={() => {
                                                this.props.history.push("/about");
                                            }}
                                        />
                                    </div>
                                    <img className="landing-5__landing-bee" src={landingBee3} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="landing-5__right" />
                        <img className="landing-5__right-image" src={landingBg4} alt="" />
                    </div>

                    <Bottom />
                </div>
            </div>
        );
    }
}

export default withRouter(Landing);
