import React, { Component } from "react";
import { Formik } from "formik";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as Yup from "yup";

import { history } from "../../_helpers/history";
import Button from "components/button";
import Footer from "components/footer";
import { ApiService, ServiceApiUrl } from "../../_helpers/index";
import { userActions } from "../../_actions";
import miyobee from "assets/images/miyo-bee.svg";

class ForgetPasswordPage extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    handleSubmit = (values) => {
        console.log(values);
        const payload = {
            input: values.emailOrPhone,
        };
        this.requestForgetPassword(payload)
            .then((resp) => {
                history.push(
                    {
                        pathname: "/forgetpasswordstep2",
                    },
                    { payload }
                );
            })
            .catch((err) => {
                alert(err);
            });
    };

    requestForgetPassword = (payload) => {
        const response = ApiService.post(ServiceApiUrl.CHECKEMAIL, payload);
        return response;
    };

    render() {
        return (
            <div className="forget-password">
                <Link to="/login">
                    <div className="hoya-logo" />
                </Link>
                <div className="signUpCard">
                    <div className="forget-password__wrapper">
                        <div className="title forget-password__title">
                            FIND YOUR <br /> HOYA MIYOSMART account
                        </div>
                        <div className="cardInputContainer">
                            <Formik
                                initialValues={{
                                    emailOrPhone: "",
                                }}
                                onSubmit={(values, { setSubmitting }) => {
                                    this.handleSubmit(values);
                                    setSubmitting(false);
                                }}
                                validationSchema={Yup.object().shape({
                                    emailOrPhone: Yup.string().required("Required"),
                                })}>
                                {(props) => {
                                    const { values, touched, errors, isSubmitting, handleChange, handleBlur, handleSubmit } = props;
                                    return (
                                        <form onSubmit={handleSubmit} className="loginForm" autoComplete="off">
                                            <div className="inputContainer">
                                                <input
                                                    id="emailOrPhone"
                                                    type="text"
                                                    placeholder="Enter your email or phone number"
                                                    value={values.emailOrPhone || ""}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={errors.emailOrPhone && touched.emailOrPhone ? "text-input error" : "text-input"}
                                                />

                                                {errors.emailOrPhone && touched.emailOrPhone && <div className="input-feedback">{errors.emailOrPhone}</div>}
                                            </div>
                                            <Button title="RESET" type="submit" disabled={isSubmitting} />
                                        </form>
                                    );
                                }}
                            </Formik>
                        </div>
                        <div className="registor-container">
                            <img className="miyobeeLogo" src={miyobee} alt="" />
                        </div>
                    </div>
                </div>

                <Footer />
            </div>
        );
    }
}

const mapDispatchToProps = {
    logout: userActions.logout,
};

export default connect(null, mapDispatchToProps)(ForgetPasswordPage);
