import React, { Component } from "react";
import { Modal, Row, Col } from "reactstrap";
import { connect } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import Icon from "react-icons-kit";
import { chevronLeft } from "react-icons-kit/ionicons/";

import Layout from "components/layout";
import Button from "components/button";
import { ApiService, ServiceApiUrl } from "../../../_helpers";
import { redempConstants } from "../../../_constants/redemp.constants";
import { shippingDetailsConstants } from "../../../_constants/shippingDetails.constants";
import { ShippingDetailsCard, RedemptionSummaryCard, RedemptionChartCard } from "../LocalComponents";

const shippingDetails = [
    {
        label: "FULL NAME",
        desc: "TAN KIM MUI",
        icon: "",
        name: "name",
    },
    // {
    //   label: "COUNTRY / REGION",
    //   desc: "Malaysia",
    //   icon: "",
    //   name: "country"
    // },
    {
        label: "CONTACT NUMBER",
        desc: "+6012345678",
        icon: "",
        name: "phoneNumber",
    },
    {
        label: "EMAIL",
        desc: "",
        icon: "",
        name: "email",
    },
    {
        label: "SHIPPING ADDRESS",
        desc: "B-05-3A, Menara Bata (Tower B), PJ Trade Centre, No. 8, Jalan PJU 8/8A, Bandar Damansara Perdana, PJU 8, 47820 Petaling Jaya, Selangor Darul Ehsan.",
        icon: "",
        name: "address",
    },
];
class RewardsRedemptionPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            profile: [],
            activeName: "",
            activeCountry: "",
            activeMobile: "",
            activeEmail: "",
            activeAddress: "",
            redempChartData: [],
            FormPopup: false,
            activefieldTochange: "",
        };
    }

    componentDidMount() {
        console.log("Rewards Redemption Did Mount :)");
        const { redempProduct, history } = this.props;
        if (!redempProduct.totalRedem.length) {
            history.push("rewards");
        }
        if (!this.props.shippingDetails.shippingDetailsData.length) {
            this.getProfile();
        } else {
            this.setStoreDatatoActiveStates();
        }
    }

    setStoreDatatoActiveStates = () => {
        this.setState(
            {
                activeName: [this.props.shippingDetails.shippingDetailsData[0].desc],
                // activeCountry: [this.props.shippingDetails.shippingDetailsData[1].desc],
                activeMobile: [this.props.shippingDetails.shippingDetailsData[2].desc],
                activeEmail: [this.props.shippingDetails.shippingDetailsData[3].desc],
                activeAddress: [this.props.shippingDetails.shippingDetailsData[4].desc],
            },
            () => {
                this.displayProfileUsingActiveStates();
            }
        );
    };
    mapProfileFromStore = () => {
        this.setState({
            profile: this.props.shippingDetails.shippingDetailsData,
        });
    };

    closePopUp = () => {
        this.setState({ FormPopup: false });
    };

    getProfile = () => {
        this.requestProfile()
            .then((resp) => {
                const { userView } = resp.data;

                this.setState(
                    {
                        activeName: userView.name,
                        // activeCountry: userView.country,
                        activeMobile: userView.mobileNumber,
                        activeEmail: userView.email,
                        activeAddress: userView.shippingAddress,
                    },
                    () => {
                        this.displayProfileUsingActiveStates();
                    }
                );
            })
            .catch((err) => {
                alert(err);
            });
    };

    requestProfile = async (payload) => {
        const response = await ApiService.get(ServiceApiUrl.VIEWPROFILE, payload);
        return response;
    };

    goBack = () => {
        const { history } = this.props;
        history.push("/rewards");
    };

    confirmProceed = () => {
        const { history } = this.props;
        const shippingInfoState = {
            email: this.state.activeEmail,
            address: this.state.activeAddress,
            contacts: this.state.activeMobile,
            name: this.state.activeName,
            // country: this.state.activeCountry
        };
        history.push("rewards-redemption-confirmation", {
            shippingInfo: shippingInfoState,
        });
    };

    displayProfilefromStore = () => {
        this.setState({
            profile: this.props.shippingDetails.shippingDetailsData,
        });
    };
    displayProfileUsingActiveStates = () => {
        shippingDetails[0].desc = this.state.activeName;
        // shippingDetails[1].desc = this.state.activeCountry;
        shippingDetails[1].desc = this.state.activeMobile;
        shippingDetails[2].desc = this.state.activeEmail;
        shippingDetails[3].desc = this.state.activeAddress;
        this.setState({
            profile: shippingDetails,
        });
    };

    mapActivestoProfileStateAndSavetoStore = () => {
        shippingDetails[0].desc = this.state.activeName;
        // shippingDetails[1].desc = this.state.activeCountry;
        shippingDetails[1].desc = this.state.activeMobile;
        shippingDetails[2].desc = this.state.activeEmail;
        shippingDetails[3].desc = this.state.activeAddress;
        this.setState(
            {
                profile: shippingDetails,
            },
            () => {
                this.props.addShippingDetails(this.state.profile);
            }
        );
    };

    onEdit = (datalabel) => {
        console.log(datalabel);
        this.setState({ activefieldTochange: datalabel }, () => {
            this.setState({
                FormPopup: true,
            });
        });
    };

    orderQuantity = (data, type) => {
        const { redempProduct } = this.props;
        if (data.addedQTY === 0 && type === "minus") return;
        if (data.quantity === data.addedQTY && type === "plus") return;
        switch (type) {
            case "plus":
                this.props.updateTotalPoints(redempProduct.totalPoints + data.points);
                this.props.addRedempItemQTY(data);
                break;
            case "minus":
                this.props.updateTotalPoints(redempProduct.totalPoints - data.points);
                this.props.minusRedempItemQTY(data);
                break;
            case "remove":
                this.props.removeRedempItem(data);
                break;
            default:
                break;
        }
    };

    render() {
        const { profile } = this.state;
        const { redempProduct } = this.props;
        return (
            <Layout>
                <div className="rewardsRedemption-container">
                    {this.state.FormPopup === true && (
                        <Modal isOpen={this.state.FormPopup} className={"rredemption_modalContainer"} contentClassName={"rredemption_modalContent"}>
                            <p className="title">UPDATES SHIPPING DETAILS</p>
                            <Formik
                                initialValues={{
                                    field: "",
                                }}
                                onSubmit={(values, { setSubmitting }) => {
                                    console.log("sent");
                                    switch (this.state.activefieldTochange) {
                                        case "FULL NAME":
                                            return this.setState({ activeName: values.field }, () => {
                                                this.mapActivestoProfileStateAndSavetoStore();
                                                this.mapProfileFromStore();
                                                this.displayProfileUsingActiveStates();

                                                this.setState({ FormPopup: false });
                                            });
                                        case "CONTACT NUMBER":
                                            return this.setState({ activeMobile: values.field }, () => {
                                                this.mapActivestoProfileStateAndSavetoStore();
                                                this.mapProfileFromStore();
                                                this.displayProfileUsingActiveStates();
                                                this.setState({ FormPopup: false });
                                            });
                                        case "EMAIL":
                                            return this.setState({ activeEmail: values.field }, () => {
                                                this.mapActivestoProfileStateAndSavetoStore();
                                                this.mapProfileFromStore();
                                                this.displayProfileUsingActiveStates();
                                                this.setState({ FormPopup: false });
                                            });
                                        case "SHIPPING ADDRESS":
                                            return this.setState({ activeAddress: values.field }, () => {
                                                this.mapActivestoProfileStateAndSavetoStore();
                                                this.mapProfileFromStore();
                                                this.displayProfileUsingActiveStates();
                                                this.setState({ FormPopup: false });
                                            });
                                        default:
                                            break;
                                    }
                                }}
                                validationSchema={Yup.object().shape({
                                    field: Yup.string().required("Required"),
                                })}>
                                {(props) => {
                                    const { values, touched, errors, isSubmitting, handleChange, handleBlur, handleSubmit } = props;
                                    return (
                                        <form onSubmit={handleSubmit} className="form">
                                            <label className="input-label">{this.state.activefieldTochange}</label>
                                            <input
                                                id="field"
                                                placeholder={
                                                    this.state.activefieldTochange === "FULL NAME"
                                                        ? this.state.activeName
                                                        : this.state.activefieldTochange === "CONTACT NUMBER"
                                                        ? this.state.activeMobile
                                                        : this.state.activefieldTochange === "EMAIL"
                                                        ? this.state.activeEmail
                                                        : this.state.activeAddress
                                                }
                                                value={values.field}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={errors.field && touched.field ? "text-input error" : "text-input"}
                                            />
                                            {errors.field && touched.field && <div className="input-feedback">{errors.field}</div>}

                                            <div className="button-container">
                                                <Button onClick={() => this.closePopUp()} outline type="button" title="Cancel" />
                                                <Button title="Confirm" disabled={isSubmitting} type="submit" />
                                            </div>
                                        </form>
                                    );
                                }}
                            </Formik>
                        </Modal>
                    )}
                    <Row className="row-container">
                        <Col className="redemption-col">
                            <Row>
                                <Col>
                                    <Row className="redemption-header">
                                        <h1 className="title">REDEMPTION CHART</h1>
                                        <div className="back-btn-container" onClick={this.goBack}>
                                            <Icon icon={chevronLeft} size={10} />
                                            <p>Back</p>
                                        </div>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <RedemptionChartCard redemptionChartData={redempProduct.totalRedem} onChange={this.orderQuantity} />
                                </Col>
                                <Col>
                                    <RedemptionSummaryCard
                                        subTotal={redempProduct.totalPoints}
                                        totalPoints={redempProduct.totalPoints}
                                        currentPoints={redempProduct.currentPoints || 0}
                                        title="REDEMPTION SUMMARY"
                                        onClick={this.confirmProceed}
                                        onCancel={this.goBack}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <ShippingDetailsCard title="SHIPPING DETAILS" shippingDetails={profile} onEdit={this.onEdit} />
                            </Row>
                        </Col>
                    </Row>
                </div>
            </Layout>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        redempProduct: state.redempProduct,
        shippingDetails: state.shippingDetails,
    };
};

const mapDispatchToProps = (dispatch) => ({
    updateTotalRedemp: (data) => {
        dispatch({ type: redempConstants.UPDATE_TOTAL_REDEMP, data });
    },
    removeRedempItem: (data) => {
        dispatch({ type: redempConstants.REMOVE_REDEMP_ITEM, data });
    },
    addRedempItemQTY: (data) => {
        dispatch({ type: redempConstants.ADD_REDEMP_ITEM_QTY, data });
    },
    minusRedempItemQTY: (data) => {
        dispatch({ type: redempConstants.MINUS_REDEMP_ITEM_QTY, data });
    },
    updateTotalPoints: (data) => {
        dispatch({ type: redempConstants.UPDATE_REDEMP_TOTAL_POINTS, data });
    },
    initialiseShippingDetails: (data) => {
        dispatch({ type: shippingDetailsConstants.INIT_REDEMPTION, data });
    },
    addShippingDetails: (data) => {
        dispatch({ type: shippingDetailsConstants.ADD_SHIPPING_ADDRESS, data });
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(RewardsRedemptionPage);
