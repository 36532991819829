import React, { Component } from 'react'
import NavBar from 'components/nav-bar'
import Button from 'components/button';

import clubBg1 from 'assets/images/clubBg1.png';
import clubBg2 from 'assets/images/clubBg2.png';
import trackChild from 'assets/images/club-track-child-eyesight.png';
import reedemPoint from 'assets/images/club-redeem-points.png';
import manageAppt from 'assets/images/club-manage-appt.png';
import Bottom from 'components/bottom';


export default class Club extends Component {
  render() {
    return (
      <div className="club-page">
        <div className="club">
          <NavBar/>
          <div className="club-1">
            <div className="club-1__main">
              <div className="club-1__left">
                <div className="club-1__left-content">
                  <div>
                    <div className="bold">A smart online</div>
                    <div className="bold">platform to keep</div>
                    <div className="bold">track of your</div>
                    <div className="bold">child’s myopia.</div>
                  </div>
                </div>
              </div>
              <div className="club-1__right" > 
              <div className="club-1__right-wrap">            
                <img className="club-1__right-image" src={clubBg1} alt=""/>
                </div> 
              </div>
            </div>
          </div>

          <div className="club-2">
            <div className="club-2__main">
              <div className="club-2__text-title">
                Welcome to MiYOSMART Club.
              </div>
              <div className="club-2__item-list">
                <div className="club-2__item">
                  <img src={manageAppt} className="club-2__item-image" alt=""/>
                  <div className="club-2__item-title">
                    Manage your 
                    appointments
                  </div>
                  <div className="club-2__item-body">
                    Get notified of your
                    child’s next appointment or
                    reschedule a visit online.
                  </div>
                </div>   
                <div className="club-2__item">
                  <img src={trackChild} className="club-2__item-image" alt=""/>
                  <div className="club-2__item-title">
                    Track your 
                    child’s eyesight
                  </div>
                  <div className="club-2__item-body">
                    View your child’s eye health
                    after check-ups and monitor
                    their progression online.
                  </div>
                </div>   
                <div className="club-2__item">
                  <img src={reedemPoint} className="club-2__item-image" alt=""/>
                  <div className="club-2__item-title">
                    Redeem your points
                  </div>
                  <div className="club-2__item-body">
                    Earn points through
                    purchases and redeem exclusive
                    gifts to be delivered to you.
                  </div>
                </div>               
              </div>
            </div>
          </div>

          <div className="club-3">
            <div className="club-3__left"/>
            <img className="club-3__left-image" src={clubBg2} alt=""/>
            <div className="club-3__right">
              <div className="club-3__right-content">
                <div className="club-3__right-content-title">
                  How do you join MiYOSMART Club?
                </div>
                <div className="club-3__right-content-body">
                  <ol>
                    <li>
                      <div className="club-3__right-content-body-title">
                        Be a MiYOSMART wearer
                      </div>
                      <div className="club-3__right-content-body-body">
                        Purchase MiYOSMART lenses to
                        gain access to the club website.
                      </div>
                    </li>
                    <li>
                      <div className="club-3__right-content-body-title">
                        Log on to the website
                      </div>
                      <div className="club-3__right-content-body-body">
                        Click on ‘first-time register’ once
                        you have entered the website.
                      </div>
                    </li>
                    <li>
                      <div className="club-3__right-content-body-title">
                        Enter your unique ID
                      </div>
                      <div className="club-3__right-content-body-body">
                        Fill in the 8-9 ID numbers on the MiYOSMART
                        card you received with your lenses.
                      </div>
                    </li>                    
                    <li></li>
                  </ol>
                  <ul>
                    <li>
                      <div className="club-3__right-content-body-title">
                        Fill in your details
                      </div>
                      <div className="club-3__right-content-body-body">
                        Add your details to complete your
                        registration to MiYOSMART Club.
                      </div>
                    </li>
                  </ul>
                </div>
                <Button
                    title="FIND YOUR MiYOSMART CENTRE NOW"
                    customClass="club-3__right-content-button"
                    link={'https://www.hoyavision.com/my/find-eye-care-practitioner/'}
                  />                  
              </div>
            </div>
          </div>
          <Bottom/>
        </div>
      </div>
    )
  }
}