import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";

import hoyaLogo from "assets/images/hoyaLogo.png";
import iconNavUser from "assets/images/navbar-user.png";
import iconNavUserWhite from "assets/images/navbar-userWhite.png";
import iconSignUp from "assets/images/navbar-signUp.png";
import iconLogIn from "assets/images/navbar-logIn.png";

const NavBar = () => {
    const history = useHistory();
    const location = useLocation();
    const [isOpen, setIsOpen] = useState(false);

    const navigateList = [
        {
            label: "HOME",
            path: "/",
        },
        {
            label: "TESTIMONIALS",
            path: "/testimonials",
        },
        {
            label: "ABOUT",
            path: "/about",
        },
        {
            label: "TECHNOLOGY",
            path: "/technology",
        },
        {
            label: "AWARDS",
            path: "/awards",
        },
        {
            label: "CLUB",
            path: "/club",
        },
    ];

    const navigation = (path) => {
        history.push(path);
    };

    const toggle = () => setIsOpen(!isOpen);

    return (
        <div className="navbar-wrap">
            <div className="navbar-container">
                <Navbar color="transparent" expand="md">
                    <NavbarBrand href="/">
                        <img className="navbar__left-logo" src={hoyaLogo} alt="" />
                    </NavbarBrand>
                    <NavbarToggler onClick={toggle} />
                    <Collapse isOpen={isOpen} navbar>
                        <Nav className="ml-auto" navbar>
                            {navigateList.map((item, index) => (
                                <NavItem key={index}>
                                    <NavLink onClick={() => navigation(item.path)}>
                                        <div className={location.pathname === item.path && item.path !== "/" ? "navbar__right-text navbar__right-text--selected" : "navbar__right-text"}>{item.label}</div>
                                    </NavLink>
                                </NavItem>
                            ))}
                            <UncontrolledDropdown nav inNavbar>
                                <DropdownToggle nav>
                                    <picture className="navbar__right-icon">
                                        <source media="(max-width: 767px)" srcSet={iconNavUserWhite} />
                                        <img src={iconNavUser} alt="iconUser" />
                                    </picture>
                                    <div className="navbar__right-icon-selected">
                                        <img src={iconNavUserWhite} alt="iconUser" />
                                    </div>
                                </DropdownToggle>
                                <DropdownMenu right>
                                    <DropdownItem>
                                        <div onClick={() => navigation("/login")} className="semibold">
                                            <img className="navbar__dropdown-icon" src={iconLogIn} alt="" />
                                            Login
                                        </div>
                                    </DropdownItem>
                                    <DropdownItem>
                                        <div onClick={() => navigation("/signuporderno")} className="semibold">
                                            <img className="navbar__dropdown-icon" src={iconSignUp} alt="" />
                                            Sign up
                                        </div>
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </Nav>
                    </Collapse>
                </Navbar>
            </div>
        </div>
    );
};

export default NavBar;
